<template>
<Form ref="form" :model="form" :label-width="140" style="margin-top: 30px; width: 400px">
  <FormItem prop="energyPrice" label="能耗电价">
    <Input v-model="form.energyPrice" number type="number">
      <span slot="append">元</span>
    </Input>
  </FormItem>
  <FormItem prop="energyOffset" label="统计偏移时间">
    <Input v-model="form.energyOffset" number type="number">
      <span slot="append">小时</span>
    </Input>
  </FormItem>
  <FormItem prop="expired" :label-width="0">
    <AuthButton opCode='sse' size="default" long type="primary" style="margin-right: 5px" @click="saveSetting">保存</AuthButton>
  </FormItem>
  <FormItem prop="stationAreaSort1" :label-width="0">
    <div style="text-align: center;">
      <span class="area-title">能耗区域显示顺序设置</span>(按设置值从小到大)
    </div>
  </FormItem>
  <FormItem prop="stationAreaSort" :label-width="0">
    <div class="area-list">
      <div class="area-item" v-for="(item, idx) in stationAreaSort" :key="idx">
        <div class="sort-title">{{item.area}}：</div>
        <Input v-model="item.sort" number type="number"></Input>
      </div>
    </div>
  </FormItem>
  <FormItem prop="expired" :label-width="0">
    <AuthButton opCode='sse' size="default" long type="primary" style="margin-right: 5px" @click="saveStationAreaSort">保存显示顺序</AuthButton>
  </FormItem>
  <!-- <FormItem prop="expired" :label-width="0">
    <Button v-if="funCodes('ssexxxxxxxx')" size="default" long type="success" style="margin-right: 5px" @click="switchHistoryData">切换历史记录数据存储格式</Button>
  </FormItem> -->
  <!-- <FormItem prop="expired" :label-width="0">
    <Button v-if="funCodes('ssexxxxxxxx')" size="default" long type="success" style="margin-right: 5px" @click="repairParamEnergyLogReadTime">修复能耗元数据读取时间</Button>
  </FormItem> -->
  <!-- <FormItem prop="expired" :label-width="0">
    <AuthButton opCode='sse' size="default" long type="success" style="margin-right: 5px" @click="repairParamEnergyLogNotDivide10">修复单灯4月25日9点以前未除10能耗数据</AuthButton>
  </FormItem> -->
  <!-- <FormItem prop="expired" :label-width="0">
    <Button v-if="funCodes('ssexxxxxxxx')" size="default" long type="success" style="margin-right: 5px" @click="repairParamEnergyDayAnalyse">修复属性能耗日统计数据</Button>
  </FormItem> -->
  <!-- <FormItem prop="expired" :label-width="0">
    <Button v-if="funCodes('ssexxxxxxxx')" size="default" long type="success" style="margin-right: 5px" @click="repairMeterEnergyDayAnalyse">修复表记能耗日统计数据</Button>
  </FormItem> -->
  <!-- <FormItem prop="expired" :label-width="0">
    <Button v-if="funCodes('ssexxxxxxxx')" size="default" long type="success" style="margin-right: 5px" @click="repairStationEnergyDayAnalyse">修复站点能耗日统计数据</Button>
  </FormItem> -->
</Form>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'AlarmSettingIndex',
  components:{
  },
  props: {
  },
  data () {
    return {
      tabHeight: 600,
      loading: false,
      form: {
        energyPrice: 1, //能耗电价
        energyOffset: 12, //统计偏移值
      },
      stationAreaSort: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['alarmLevels', 'productCodes', 'alarmTypes', 'alarmGroupList']),
  },
  watch: {
    windowOnResize(){
    },
  },
  mounted: function(){
    this.getList();
    this.getStationAreaSort();
  },
  destroyed: function(){
  },
  methods: {
    switchHistoryData: function(){
      this.$Modal.confirm({
        title: "提示",
        content: `切换历史记录数据存储格式吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/analyse/switchHistoryData`, {}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.$Message.info(`启动历史记录数据数量：${res.data}`, { timeout: 4000 });
            }
          });
        }
      });
    },
    // repairParamEnergyLogNotDivide10: function(){
    //   this.$axios.post(`//${this.domains.trans}/station/analyse/RepairParamEnergyLogNotDivide10`, {}).then(res => {
    //     if(res.code != 0){
    //       // this.$Message.error(res.data);
    //     }else{
    //       this.$Modal.info({
    //         title: "处理结果",
    //         content: res.data,
    //       });
    //     }
    //   });
    // },
    repairStationEnergyDayAnalyse: function(){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要修复站点能耗日统计数据吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/analyse/RepairStationEnergyDayAnalyse`, {}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.$Message.info(`已启动数据修复任务`, { timeout: 4000 });
            }
          });
        }
      });
    },
    repairMeterEnergyDayAnalyse: function(){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要修复表记能耗日统计数据吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/analyse/RepairMeterEnergyDayAnalyse`, {}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.$Message.info(`已启动数据修复任务`, { timeout: 4000 });
            }
          });
        }
      });
    },
    repairParamEnergyDayAnalyse: function(){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要修复属性能耗日统计数据吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/analyse/RepairParamEnergyDayAnalyse`, {}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.$Message.info(`已启动数据修复任务`, { timeout: 4000 });
            }
          });
        }
      });
    },
    repairParamEnergyLogReadTime: function(){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要修复能耗元数据读取时间吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/analyse/RepairParamEnergyLogReadTime`, {}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.$Message.info(`已启动数据修复任务`, { timeout: 4000 });
            }
          });
        }
      });
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/sys/setting/QueryEnergy`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'form', res.data);
        }
      });
    },
    getStationAreaSort: function(){
      this.$axios.post(`//${this.domains.trans}/station/config/QueryStationSort`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'stationAreaSort', res.data);
        }
      });
    },
    saveStationAreaSort: function(){
      this.$axios.post(`//${this.domains.trans}/station/config/SaveStationSort`, {list: this.stationAreaSort}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.getStationAreaSort();
        }
      });
    },
    saveSetting: function(){
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/sys/setting/SaveEnergy`, this.form).then(res => {
      this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功')
        }
      });
    },
  }
}
</script>
<style scoped>
.area-title{
  font-weight: bolder;
  font-size: 20px;
}
.area-list{
  display: flex;
  flex-direction: column;
}
.area-item{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.sort-title{
  width: 140px;
  text-align: right;
  padding-right: 10px;
  flex: none;
}
.chk-item{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin-bottom: 5px;
}
.alarm-type{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin: 5px;
  padding: 5px;
}
.alarm-type-name{
  padding: 5px 0 1px 0;
  border-bottom: solid 1px rgba(219, 175, 175, 0.568);
  margin-bottom: 10px;
}
.alarm-level-container{
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* align-content: stretch; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-level-item{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 3px;
  width: 300px;
  flex: auto;
  margin: 5px;
  display: flex;
  flex-direction: column;
}
.level-item-header{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 10px;
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.level-item-content{
  border: solid 1px rgba(219, 175, 175, 0.568);
  height: 400px;
  flex: auto;
}
.header-title{
  /* border: solid 1px red; */
  width: 100px;
  flex: auto;
  font-weight: bolder;
  font-size: 20px;
}
.header-btn{
  /* margin: 10px; */
  flex: none;
  /* border: solid 1px red; */
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>