<template>
  <div class="control-butList">
    <template v-for="item,index in list">
      <a href="javascript:void(0);" class="control-item" :key="index" @click="doLightCmd(item)">
        <img :src="item.img" />
        {{item.name}}
      </a>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: 'butList12',
  components: {
  },
  props: {
    dataShow: {
      type: Object,
      default() { return {}; }
    },
    store: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      list: [
        { needArgs: false, needPswd: false, code: 'forceRead', opcode: 'dcs', name: '召测数据', img: this.img.light.carat.gis.butData, },
        { needArgs: true, needPswd: true, code: 'handSingle', opcode: 'dco', name: '调光控制', img: this.img.light.carat.gis.butDimming, },

        { needArgs: true, needPswd: true, code: 'openSingle', opcode: 'dco', name: '开灯', img: this.img.light.carat.gis.butOpen, },
        { needArgs: true, needPswd: true, code: 'closeSingle', opcode: 'dco', name: '关灯', img: this.img.light.carat.gis.butClose, },

        { needArgs: false, needPswd: false, code: 'HistoryData', opcode: '', name: '历史数据', img: this.img.light.carat.gis.butCheck, },
        { needArgs: false, needPswd: false, code: 'AlarmRecord', opcode: '', name: '报警记录', img: this.img.light.carat.gis.butSet, },
      ],
      cmdItem: {},
      showArgModal: false,
      cmdIds: [],
    }
  },
  watch: {

  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes', 'deviceTypes', 'powerTableActions', 'monitorActionTypes']),
    ...mapState('timeTable', ['powerTableActions']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    doLightCmd(cmd) {
      this.$emit('savedShow', {cmd: cmd });
    },
  }
}
</script>
<style scoped>
.control-butList {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
}
.control-item {
  height: 32px;
  background: #42cee8;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #011c31;
  line-height: 32px;
  margin: 15px 0 10px 0;
  padding: 0 7px;
}
.control-item img {
  display: inline-block;
  vertical-align: middle;
}
</style>