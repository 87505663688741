
<template>
<Modal v-model="showModal" :mask-closable="false" width="1025px">
  <div slot="header">{{showLang('system.image.manage')}}</div>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
  </div>
  <div class="user-container">
    <ul>
       <li v-for="(el,index) in imgArr" :key="index" @click="copywxtap(el.path)">
         <img :src="el.url" alt=""  :title="el.path">
      </li>
    </ul>
  </div>
</Modal>
</template>
<script>
import { mapState,mapGetters } from "vuex";
export default {
  name: 'ModalImageList',
  components: {},
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showModal: this.value,
      imgArr: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["showLang"]),
    ...mapState("auth", ["organs", "customers", "organTypes", "customerId"]),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
    this.imgHandle();
  },
  methods: {
    // handleClick(index) {
    //   console.log(index);
    // },
    imgHandle() {
      this.initObjs(this.imgArr, this.img, "img");
    },
    copywxtap(con) {
      var input = document.createElement("input"); 
      input.value = con; 
      document.body.appendChild(input); 
      input.select(); 
      document.execCommand("Copy"); 
      document.body.removeChild(input); 
         this.$Message.success(this.showLang('com.copy.success'));
    },
    initObjs: function (list, obj, parent) {
      for (let key in obj) {
        let o = obj[key];
        if (typeof o == "object") {
          this.initObjs(list, o, `${parent}.${key}`);
        } else {
          list.push({ path: `${parent}.${key}`, url: obj[key] });
        }
      }
    },
    cancel () {
      this.showModal = false;
    },
  },
};
</script>
<style scoped>
.user-container {
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  height: 100%;
}
ul{
  background: #000;
}
ul li{
  border: 1px solid #fff;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 5px;
}
ul li img{
  max-width: 98px;
  max-height: 98px;
}
</style>
