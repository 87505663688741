<template>
<Modal v-model="showModal" :mask-closable="false" width="600px" :styles="{top: '150px'}">
  <div slot="header">{{title}}</div>
  <Form ref="form" :model="form" class="form" :rules="rules" :label-width="80">
    <FormItem prop="name" label="应用名称">
      <Input ref="name" type="text" v-model="form.name" placeholder="请输入名称">
      </Input>
    </FormItem>
    <FormItem prop="type" label="应用类型">
      <Select ref="type" v-model="form.type" transfer>
        <template v-for="(v, k) in appTypes">
          <Option v-if="k != 99" :value="parseInt(k)" :key="k">{{v}}</Option>
        </template>
      </Select>
    </FormItem>
    <FormItem prop="version" label="应用版本">
      <Input ref="version" type="text" v-model="form.version" placeholder="请输入版本">
      </Input>
    </FormItem>
  </Form>
  <div class="demo-drawer-footer">
    <Button style="margin-right: 8px" @click="cancel">取消</Button>
    <Button type="primary" :loading="loading" @click="ok">保存</Button>
  </div>
</Modal>
</template>
<script>
  export default {
    name: 'ModalEditApp',
    model: {
      prop: 'value',
      event: 'showChanged'
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      item: {
        type: Object,
        default () { return {}; }
      },
      // types: {
      //   type: Array,
      //   default () { return []; }
      // },
      // areas: {
      //   type: Array,
      //   default () { return []; }
      // },
      appTypes: {
        type: Object,
        default () { return {}; }
      },
    },
    data () {
      return {
        title: '编辑应用信息',
        loading: false,
        showModal: this.value,
        isAdd: false,
        index: -1,
        showDeviceSelection: true,
        selectedNode: {},
        main: {
          count: 0,
          loading: false,
          col: [
            {
              title: '设备编码',
              key: 'code',
              tree: true,
            },
            {
              title: '设备名称',
              key: 'name'
            },
            {
              title: '设备类型',
              width: 100,
              key: 'typeId',
              render: (h, params) => {
                return h('div', [
                  h('Icon', {
                      props: {
                          type: 'person'
                      }
                  }),
                  h('strong', this.deviceType(params))
                ]);
              }
            },
            {
              title: '操作',
              key: 'latitude',
              width: 190,
              render: (h, params) => {
                let btns = [];
                // if(params.row.type == "dev"){
                  btns.push(h('Checkbox', {
                    props: {
                      value: params.row.canRead,
                    },
                    style: {
                      marginLeft: '5px'
                    },
                    on: {
                      'on-change': (item) => {
                        params.row.canRead = item;
                        if(!item){
                          params.row.canWrite = false;
                        }
                        this.handleCheck(params);
                      }
                    }
                  }, '可查看'));
                  btns.push(h('Checkbox', {
                    props: {
                      value: params.row.canWrite,
                    },
                    on: {
                      'on-change': (item) => {
                        params.row.canWrite = item;
                        if(item){
                          params.row.canRead = true;
                        }
                        this.handleCheck(params);
                      }
                    }
                  }, '可写入'));
                // }
                return h('div', btns);
              },
            }
          ],
          list: [],
          filter: {
            appId: '',
            areaId: '',
            code: '',
            index: 1,
            size: 10
          }
        },
        form: {
          id: '',
          name: '',
          type: 0,
          version: '',
          // appId: '',
          appSecret: '',
          // appDevices: [],
        },
        rules: {
          name: [
            { required: true, message: '名称不能为空', trigger: 'blur' },
            { type: 'string', min: 2, max: 30, message: '名称长度必须2 - 30个字符', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '请选择应用类型', trigger: 'change',type: 'number' },
            // { type: 'string', min: 2, max: 20, message: '版本长度必须2 - 20个字符', trigger: 'blur' }
          ],
          version: [
            { required: true, message: '版本不能为空', trigger: 'blur' },
            { type: 'string', min: 2, max: 20, message: '版本长度必须2 - 20个字符', trigger: 'blur' }
          ]
        }
      }
    },
    watch: {
      item(newVal) {
        this.form.id = newVal.id;
        this.isAdd = !this.form.id;
        this.form.name = newVal.name;
        this.form.type = newVal.type;
        this.form.version = newVal.version;
        // this.form.appId = newVal.appId;
        this.form.appSecret = newVal.appSecret;
        // this.form.appDevices = {};
        if(newVal.index != -1){
          this.index = newVal.index;
        }else{
          this.form.type = 0;
        }
        // this.typeChanged();
        // this.main.filter.appId = this.form.id;
        // this.getDeviceList();
      },
      value(newVal) {
        this.showModal = newVal;
      },
      showModal(val){
        this.$emit('showChanged', val);
      }
    },
    mounted: function(){
      
    },
    methods: {
      ok: async function () {
        let result = await this.$refs.form.validate();
        if(!result){
          return;
        }
        this.loading = true;
        let res;
        if(this.isAdd){
          res = await this.$axios.post(`//${this.domains.trans}/app/list`, this.form);
        }else{
          res = await this.$axios.put(`//${this.domains.trans}/app/list/${this.form.id}`, this.form);
        }
        this.loading = false;
        if(res.code !== 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$Message.info('保存成功');
        setTimeout(() => {
          this.showModal = false;
          if(!this.form.id){
            this.form.id = res.data.id;
          }
          this.form.version = res.data.version;
          this.form.appSecret = res.data.appSecret;
          this.form.name = res.data.name;
          // this.main.list = []
          this.$emit('saved', {add: this.isAdd, index: this.index, data: {id: this.form.id, type: this.form.type, name: this.form.name, version: this.form.version, appSecret: this.form.appSecret}});
        }, 800);
      },
      cancel () {
        this.showModal = false;
        this.$refs.form.resetFields();
      },
    }
  }
</script>
<style scoped>
.drawer-content{
  z-index: 2000 !important;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .demo-tree-render .ivu-tree-title{
    width: 100%;
  }
  /* .ivu-tree-title{
    width: 100%;
  } */
</style>