<template>
  <div>
    <Row class="expand-row">
      <Col span="24">
        <Input type="text" v-model="row.developContent" placeholder="开发配置内容">
          <span slot="prepend">开发配置：</span>
        </Input>
      </Col>
    </Row>
    <Row class="expand-row">
      <Col span="24">
        <Input type="text" v-model="row.testContent" placeholder="测试配置内容">
          <span slot="prepend">测试配置：</span>
        </Input>
      </Col>
    </Row>
    <Row class="expand-row">
      <Col span="24">
        <Input type="text" v-model="row.productContent" placeholder="生产配置内容">
          <span slot="prepend">生产配置：</span>
        </Input>
      </Col>
    </Row>
  </div>
</template>
<script>
  export default {
    name: 'ConfigTableExpand',
    props: {
      row: {
        type: Object,
        default () { return {}; }
      },
    },
    data () {
      return {
      }
    },
    watch: {
    },
    methods: {
    }
  }
</script>
<style scoped>
.expand-row{
  margin-bottom: 10px;
}
</style>