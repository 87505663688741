<template>
  <div class="softwaretree">
    <div class="tree-box">
      <div v-for="el,index in list" :class="[index==treeIndex?'after':'','tree-list']" :key="index" @click="treeBut(el,index)">{{el.name}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'softwaretree',
  components: {
  },
  data() {
    return {
      treeIndex: 0,
      list: [],
    }
  },
  created: function () {
  },
  computed: {
  },
  watch: {
  },
  mounted: function () {
    this.treeAjax();
  },
  destroyed: function () {
  },
  methods: {
    treeAjax() {
      this.$axios.post(`site/debug/GetBigTypes`, {}).then(res => {
        this.$set(this, 'list', res.data);
        this.treeBut(res.data[0], 0)
      })
    },
    treeBut(el, index) {
      this.treeIndex = index;
      this.$emit('saved', el.id);
    }
  },
}
</script>
<style scoped>
.softwaretree {
  height: 100%;
}
.tree-box {
  width: 300px;
  height: 100%;
  flex: none;
  background: #ffffff;
  border: solid 1px rgba(197, 197, 197, 0.37);
  padding-top: 20px;
  margin-right: 10px;
}
.tree-list {
  width: 100%;
  text-align: left;
  text-indent: 40px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  cursor: pointer;
  margin: 0 auto;
}
.tree-list.after {
  background: #ddeffe;
}
.tree-list.after::after {
  content: "";
  width: 100%;
  height: 48px;
  display: block;
  margin: -48px auto 0;
  border-left: 4px solid #3880fc;
  border-radius: 0 6px 6px 0;
}
</style>