<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">参管项目信息</div>
    <div class="user-edit-content">
      <div class="query-data-area">
        项目名称：
        <Input v-model="filter.appName" placeholder="请输入项目名称" clearable style="width: 200px; margin-right: 10px;"></Input>
        组织名称：
        <Input v-model="filter.name" placeholder="请输入组织名称" clearable style="width: 200px; margin-right: 10px;"></Input>
        <Button type="primary" @click="search">查询</Button>
      </div>
      <div class="alarm-data-area" ref="table1">
        <vxe-table resizable border show-overflow row-key :row-config="{isHover: true}" :data="list" :scroll-y="{enabled: false}" height="500">
          <vxe-column field="isOwner" title="拥有者" width="70" header-align="center">
            <template #default="{ row }">
              <div :style="{color: row.isOwner ? 'blue' : 'green'}">{{row.isOwner ? '是' : '否'}}</div>
            </template>
          </vxe-column>
          <vxe-column field="enable" title="状态" width="60" header-align="center">
            <template #default="{ row }">
              <div :style="{color: row.enable ? 'green' : 'red'}">{{row.enable ? '启用' : '未启用'}}</div>
            </template>
          </vxe-column>
          <vxe-column field="appName" title="项目名称" header-align="center"></vxe-column>
          <vxe-column field="name" title="所属组织" width="200" header-align="center"></vxe-column>
          <vxe-column field="area" title="所在地区" width="100" header-align="center"></vxe-column>
          <!-- <vxe-column field="linkman" title="联系人" width="150" header-align="center"></vxe-column>
          <vxe-column field="mobile" title="联系方式" width="150" header-align="center"></vxe-column> -->
          <vxe-column>
            <template #default="{ row }">
              <Button v-if='funCodes("sue")' size="small" type="primary" style="margin-right: 5px" @click="handleAdd(row)">加入项目</Button>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
      <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
      <ModalRoleAndGroup v-model="showRoleAndGroupModal" :item="editItem" @saved="custAdded" />
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
  </Modal>
</template>
<script>
import {mapState,mapGetters} from 'vuex'
import ModalRoleAndGroup from './ModalRoleAndGroup'
export default {
  name: 'ModalUserEdit',
  components:{
    ModalRoleAndGroup
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      showRoleAndGroupModal: false,
      loading: false,
      editItem: {},
      filter: {
        name: '',
        appName: '',
        appType: '',
        isNewMode: false,
        index: 1,
        size: 20,
      },
      list: [],
      total: 0,
      custChanged: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('auth', ['appType', 'isNewMode', 'customerId']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(this.showModal){
        this.search();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    search: function(){
      this.filter.index = 1;
      this.getList();
    },
    getList: function(){
      this.filter.userId = this.item.user.id;
      this.filter.isNewMode = this.isNewMode;
      this.filter.appType = this.appType;
      this.$axios.post(`site/customer/QueryUserWaitCust`, this.filter).then(res => {
        if(res.code == 0){
          this.$set(this, 'list', res.data.list);
          this.total = res.data.count;
        }
      });
    },
    handleAdd: function(params){
      this.editItem = {user: this.item.user, cust: params};
      this.showRoleAndGroupModal = true;
    },
    custAdded: function(){
      // console.log('user add cust ok')
      this.custChanged = true;
      this.getList();
      // this.showRoleAndGroupModal = false;
    },
    cancel () {
      this.showModal = false;
      if(this.custChanged){
        this.$emit('saved');
      }
    },
  }
}
</script>
<style scoped>
.user-edit-content{
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
}
.query-data-area {
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  /* border: solid 1px rgba(143, 138, 138, 0.452); */
  margin-bottom: 5px;
}
</style>