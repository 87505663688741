<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">{{title}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="80" :key="form.parentId">
      <FormItem prop="name" label="网关名称">
        <Input ref="name" type="text" v-model="form.name"></Input>
      </FormItem>
      <FormItem prop="smallType" label="设备小类">
        <Select ref="smallType" v-model="form.content.stid" number @on-change="smallTypeChanged">
          <template v-for="(item, idx) in smallTypes">
            <Option v-if="item.type == 2" :value="item.code" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
        {{devtype}}
      </FormItem>
      <FormItem v-if="devtype == 'zxklm20'" prop="code" label="关联账号">
        <Select ref="channel" v-model="form.channel">
          <Option v-for="(item, key) in accounts" :value="`ZXKL:${item.id}`" :key="key">{{ item.name }}:{{item.appKey}}</Option>
        </Select>
      </FormItem>
      <FormItem prop="code" :label="devtype == 'zxklm20' ? 'GWEUI' : '通信ID'">
        <Input ref="code" type="text" maxlength="20" v-model="form.code" placeholder="请输入设备通信ID"></Input>
      </FormItem>
      <FormItem v-if="devtype != 'zxklm20'" prop="enlux" label="启用光控">
        <div style="display: flex;align-items:center; height: 34px; border: solid 0 red;">
          <i-switch v-model="form.content.enlux" style="width: 44px; flex:none; margin-right:10px" />
          <Input v-if="form.content.enlux" ref="tlux" type="number" number v-model="form.content.tlux"><span slot="prepend">光控采集间隔时间</span><span slot="append">秒</span></Input>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalEditDevice',
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    accounts: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      title: '编辑单灯网关信息',
      loading: false,
      showModal: this.value,
      isAdd: false,
      devtype: '',
      form: {
        id: '',
        name: '',
        code: '',
        deviceId: 0,
        channel: '',
        content: {
          stid: 0,
          enlux: false,
          tlux: 60,
        },
      },
      rules: {
        name: { required: true, type: 'string', max: 30, message: '设备名称不能为空且长度不能超过30个字符', trigger: 'blur' },
        // type: { required: true, type: 'string', message: '必须选择一个设备类型', trigger: 'on-change' },
      }
    }
  },
  computed: {
    ...mapState('auth', ['roles', 'customers', 'appScenes']),
    ...mapState('common', ['productCodes', 'deviceTypes', 'bigTypes', 'smallTypes', 'coms8088names']),
    ...mapGetters('common', ['getBigTypeName', 'getSmallTypeName', 'getSmallTypes']),
  },
  watch: {
    // form:{
    //    handler(val){
    //      if(val.parentId==0){
    //         this.rules.code={ required: true, type: 'string', message: '添加通信ID', trigger: 'on-change'}
    //      }else{
    //        delete this.rules.code;
    //      }
    //    },
    //   deep:true
    // },
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        // console.log('edit device', this.item)
        this.isAdd = this.item.isAdd;
        if (this.isAdd) {
          this.form.id = "";
          this.form.name = '';
          this.form.code = "";
          this.form.channel = "";
          this.form.deviceId = 0;
          this.form.content.stid = 0;
          this.form.content.enlux = false;
          this.form.content.tlux = 60;
        } else {
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.form.deviceId = this.item.data.deviceId;
          this.form.code = this.item.data.code;
          this.form.channel = this.item.data.channel;
          this.form.content.stid = this.item.data.content.stid;
          this.form.content.enlux = this.item.data.content.enlux;
          this.form.content.tlux = this.item.data.content.tlux;
          // if(this.devtype == 'zxklm20'){
          //   this.form.code = this.item.data.code;
          // }else{
          //   this.form.code = this.item.data.code;
          // }
        }
        this.smallTypeChanged();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {

  },
  methods: {
    smallTypeChanged: function () {
      if (this.form.content.stid <= 0) {
        this.devtype = '';
      } else {
        let ts = this.smallTypes.filter(p => p.code == this.form.content.stid);
        if (ts.length == 0) {
          this.devtype = '';
        } else {
          this.devtype = ts[0].protocol;
        }
      }
    },
    ok: async function () {
      let result = await this.$refs.form.validate();
      if (!result) {
        return;
      }
      if (this.form.code == '') {
        this.$Message.warning('顶级设备请输入通信ID')
        return;
      }
      if(this.form.content.tlux <= 10 || this.form.content.tlux > 1000){
        this.$Message.warning('光控采集间隔必须大于10秒且小于1000秒')
        return;
      }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/light/SaveGateWay`, this.form).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$Message.info('保存成功');
          setTimeout(() => {
            this.showModal = false;
            this.$emit('saved');
          }, 800);
        } else {
          // this.$Message.error(res.data);
        }
      });
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>