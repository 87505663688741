<template>
<div class="open-container">
  <Form ref="form" :model="form" label-position="top" class="open-form">
    <FormItem prop="enable" label="是否开放系统应用数据">
      <i-switch v-model="form.enable" size="large" true-color="green" false-color="red">
        <span slot="open">启用</span>
        <span slot="close">禁用</span>
      </i-switch>
    </FormItem>
    <FormItem prop="appKey" label="应用KEY">
      <Input v-model="form.appKey" maxlength="100" readonly type="text">
      </Input>
    </FormItem>
    <FormItem prop="appSecret" label="应用密钥">
      <div style="display: flex;">
        <Input v-model="form.appSecret" maxlength="100" readonly type="text">
        </Input>
        <Button v-if="funCodes('sosd')" type="primary" @click="updateSecret" style="margin-left: 10px">更换密钥</Button>
      </div>
    </FormItem>
    <FormItem prop="callback" label="设备数据回调接口">
      <Input v-model="form.callback" maxlength="100" type="textarea">
      </Input>
    </FormItem>
    <FormItem prop="expired" :label-width="0">
      <AuthButton opCode='sose' size="default" long type="primary" @click="saveSetting">保存</AuthButton>
    </FormItem>
    <FormItem prop="callback" label="">
      <a v-if="appType == 'road'" target="_blank" href="https://console-docs.apipost.cn/preview/07202ed439a09b97/98e1449064c45a8a">点击查看系统数据开放接口文档</a>
      <a v-else-if="appType == 'pole'" target="_blank" href="https://console-docs.apipost.cn/preview/7833926001946d37/eefd19fe2ec4a268?target_id=695bc4d3-9db8-4616-8a66-5254586cd7d4">点击查看系统数据开放接口文档</a>
    </FormItem>
  </Form>
  <div class="log-list" ref="table1" style="margin: 0 10px; width: 200px;">
    <div class="log-header">
      令牌获取日志：
      开始日期：
      <DatePicker type="date" v-model="tokenFilter.start" placeholder="请选择开始日期" style="width: 115px;margin-right:10px"></DatePicker>
      结束日期：
      <DatePicker type="date" v-model="tokenFilter.end" placeholder="请选择结束日期" style="width: 115px;margin-right:10px"></DatePicker>
      <Button v-if="funCodes('sosv')" type="primary" @click="getTokenLog" style="margin-right:10px">查询</Button>
    </div>
    <div class="log-detail">
      <vxe-table border show-overflow show-header-overflow ref="chkTable" :key="dataRefresh" :data="tokenLogs" :height="tabHeight" :row-config="{isHover: true}" :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']" :current-page="tokenFilter.index" :page-size="tokenFilter.size" :total="tokenCount">
        <vxe-column field="tokenTime" title="时间" width="150"></vxe-column>
        <vxe-column field="accessToken" title="令牌" width="280"></vxe-column>
        <vxe-column field="ip" title="IP" width="120"></vxe-column>
      </vxe-table>
      <vxe-pager
        perfect
        :current-page="tokenFilter.index"
        :page-size="tokenFilter.size"
        :total="tokenCount"
        :page-sizes="[15, 50, 100, 200, 500]"
        :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']"
        @page-change="handleTokenPageChange">
      </vxe-pager>
    </div>
  </div>
  <div class="log-list" ref="table2">
    <div class="log-header">
      接口访问日志：
      开始日期：
      <DatePicker type="date" v-model="accessFilter.start" placeholder="请选择开始日期" style="width: 115px;margin-right:10px"></DatePicker>
      结束日期：
      <DatePicker type="date" v-model="accessFilter.end" placeholder="请选择结束日期" style="width: 115px;margin-right:10px"></DatePicker>
      <Button v-if="funCodes('sosv')" type="primary" @click="getAccessLog" style="margin-right:10px">查询</Button>
    </div>
    <div class="log-detail">
      <vxe-table border show-overflow show-header-overflow ref="chkTable" :key="dataRefresh" :data="accessLogs" :height="tabHeight" :row-config="{isHover: true}" :current-page="accessFilter.index" :page-size="accessFilter.size" :total="accessCount">
        <vxe-column field="createTime" title="时间" width="150"></vxe-column>
        <vxe-column field="ip" title="IP" width="120"></vxe-column>
        <vxe-column field="accessToken" title="令牌" width="280"></vxe-column>
        <vxe-column field="name" title="名称" width="150"></vxe-column>
        <vxe-column field="url" title="URL" width="200"></vxe-column>
        <vxe-column width="120" title="操作" fixed="right">
          <template #default="{ row }" fiexed="right" width="100">
            <Button v-if="funCodes('sosv')" size="small" type="primary" @click="getAccessDetail(row)">访问明细</Button>
          </template>
        </vxe-column>
      </vxe-table>
      <vxe-pager
        perfect
        :current-page="accessFilter.index"
        :page-size="accessFilter.size"
        :total="accessCount"
        :page-sizes="[15, 50, 100, 200, 500]"
        :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']"
        @page-change="handleAccessPageChange">
      </vxe-pager>
    </div>
  </div>
  <ModalAccessDetail v-model="showEditModal" :item="editItem" />
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalAccessDetail from './ModalAccessDetail'
export default {
  name: 'AlarmSettingIndex',
  components:{
    ModalAccessDetail,
  },
  props: {
  },
  data () {
    return {
      tabHeight: 600,
      loading: false,
      showEditModal: false,
      editItem: {},
      form: {
        enable: 1, //
        appKey: '',
        appSecret: '',
        callback: '',
      },
      dataRefresh: 0,
      tokenFilter: {
        start: '',
        end: '',
        size: 15,
        index: 1,
      },
      tokenCount: 0,
      tokenLogs: [],
      accessFilter: {
        start: '',
        end: '',
        size: 15,
        index: 1,
      },
      accessCount: 0,
      accessLogs: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('auth', ['appType']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['alarmLevels', 'productCodes', 'alarmTypes', 'alarmGroupList']),
  },
  watch: {
    windowOnResize(){
      setTimeout(this.setTableHeight, 100);
    },
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 100);
    let now = new Date();
    now = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    now.setDate(now.getDate() + 1);
    this.tokenFilter.end = now.format('yyyy-MM-dd HH:mm:ss');
    this.accessFilter.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 3);
    this.accessFilter.start = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 4);
    this.tokenFilter.start = now.format('yyyy-MM-dd HH:mm:ss');
    this.getList();
    this.getTokenLog();
    this.getAccessLog();
  },
  destroyed: function(){
  },
  methods: {
    setTableHeight: function(){
      this.tabHeight = this.$refs.table1.clientHeight - 100;
    },
    handleTokenPageChange: function({ currentPage, pageSize }){
      this.tokenFilter.index = currentPage;
      this.tokenFilter.size = pageSize;
      this.getTokenLog();
    },
    handleAccessPageChange: function({ currentPage, pageSize }){
      this.accessFilter.index = currentPage;
      this.accessFilter.size = pageSize;
      this.getAccessLog();
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/sys/open/GetSetting`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'form', res.data);
        }
      });
    },
    saveSetting: function(){
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/sys/open/SetSetting`, this.form).then(res => {
      this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功')
        }
      });
    },
    updateSecret: function(){
      this.$Modal.confirm({
        title: "提示",
        content: `更换密钥会清除正在使用的令牌，<br />确定要更换密钥吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/sys/open/UpdateSecret`, {}).then(res => {
            if(res.code !== 0){
              // this.$Message.error(res.data);
              return;
            }else{
              this.$Message.info('更换密钥成功');
              this.getList();
            }
          })
        }
      });
    },
    getTokenLog: function(){
      this.tokenFilter.start = new Date(this.tokenFilter.start).format('yyyy-MM-dd HH:mm:ss');
      this.tokenFilter.end = new Date(this.tokenFilter.end).format('yyyy-MM-dd HH:mm:ss');
      this.$axios.post(`//${this.domains.trans}/sys/open/QueryTokenLog`, this.tokenFilter).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.tokenCount = res.data.count;
          this.$set(this, 'tokenLogs', res.data.list);
        }
      });
    },
    getAccessLog: function(){
      this.accessFilter.start = new Date(this.accessFilter.start).format('yyyy-MM-dd HH:mm:ss');
      this.accessFilter.end = new Date(this.accessFilter.end).format('yyyy-MM-dd HH:mm:ss');
      this.$axios.post(`//${this.domains.trans}/sys/open/QueryAccessLog`, this.accessFilter).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.accessCount = res.data.count;
          this.$set(this, 'accessLogs', res.data.list);
        }
      });
    },
    getAccessDetail: function(item){
      this.editItem = item;
      this.showEditModal = true;
    }
  }
}
</script>
<style scoped>
.open-container{
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
}
.open-form{
  border: solid 1px rgba(219, 175, 175, 0.568);
  border-radius: 6px;
  padding: 20px 30px;
  /* margin: 30px; */
  width: 430px;
  flex: none;
}
.log-list{
  border: solid 1px rgba(219, 175, 175, 0.568);
  border-radius: 6px;
  width: 430px;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.log-header{
  height: 50px;
  flex: none;
  display: flex;
  align-items: center;
  padding: 10px;
}
.log-detail{
  height: 500px;
  flex: auto;
}
.chk-item{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin-bottom: 5px;
}
.alarm-type{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin: 5px;
  padding: 5px;
}
.alarm-type-name{
  padding: 5px 0 1px 0;
  border-bottom: solid 1px rgba(219, 175, 175, 0.568);
  margin-bottom: 10px;
}
.alarm-level-container{
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* align-content: stretch; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-level-item{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 3px;
  width: 300px;
  flex: auto;
  margin: 5px;
  display: flex;
  flex-direction: column;
}
.level-item-header{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 10px;
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.level-item-content{
  border: solid 1px rgba(219, 175, 175, 0.568);
  height: 400px;
  flex: auto;
}
.header-title{
  /* border: solid 1px red; */
  width: 100px;
  flex: auto;
  font-weight: bolder;
  font-size: 20px;
}
.header-btn{
  /* margin: 10px; */
  flex: none;
  /* border: solid 1px red; */
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>