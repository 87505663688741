<template>
  <div class="tree-container">
    <MyTreeNode v-for="(node, idx) in data" :path="[]" :level="idx" :key="node.id" :node="node" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MyTreeNode from './MyTreeNode'
  export default {
    name: 'MyTree',
    components:{
      MyTreeNode,
    },
    model: {
      prop: 'value',
      event: 'showChanged'
    },
    props: {
      data: {
        type: Array,
        default(){ return [];}
      },
    },
    data () {
      return {
      }
    },
    computed: {
      ...mapState('powerbox', ['selectedNode'])
    },
    watch: {
      data(){
        // if(this.data.length > 0 && !this.selectedNode.name){
        //   this.$store.commit('powerbox/selectNode', this.data[0]);
        // }
      },
    },
    methods: {
      removeNode: function(node, parent){
        let list = this.data;
        if(parent != null && parent != undefined){
          list = parent.children;
        }
        if(list == null || list.length == 0)return;
        for(let i = 0; i < list.length; i++){
          let n = list[i];
          if(n.type == node.type && n.data.id == node.data.id){
            list.splice(i, 1);
            return;
          }else{
            this.removeNode(node, n);
          }
        }
      },
      selectNodeByData: function(nodeData){
        this.findNodeByData(this.data[0], nodeData);
      },
      findNodeByData: function(node, nodeData){
        if(!node.children)return;
        for(let n of node.children){
          if(n.data.id == nodeData.id){
            this.$store.commit('powerbox/selectNode', n);
            return;
          }else{
            this.findNodeByData(n, nodeData);
          }
        }
      },
    }
  }
</script>
<style scoped>
.tree-container{
  width: 100%;
  background-color: #041B32;
  /* background-color: #ffffff; */
  padding-bottom: 50px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
  .context-menu{
    display: none;
    position: absolute;
    width: 200px;
    height: 200px;
    background: oldlace;
  }
</style>