<template>
<div class="alarm-level-container">
  <div class="alarm-level-item">
    <div class="level-item-header">
      <Form ref="form" :model="form" :label-width="350">
        <FormItem prop="paramId" :label="showLang('work.tips.SMS.enable')">
          <i-switch v-model="form.message" size="large" true-color="green" false-color="red">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
        </FormItem>
        <FormItem prop="stationId" :label="showLang('work.tips.SMS.alarm')">
          <Input v-model="form.msgDelay" maxlength="100" type="number" style="width: 100px">
            <span slot="append">min</span>
          </Input>
        </FormItem>
        <FormItem prop="paramId" :label="showLang('work.tips.phone.enable')">
          <i-switch v-model="form.phone" size="large" true-color="green" false-color="red">
            <span slot="open">{{showLang('com.state.enable')}}</span>
            <span slot="close">{{showLang('com.state.disable')}}</span>
          </i-switch>
        </FormItem>
        <FormItem prop="stationId" :label="showLang('work.tips.phone.alarm')">
          <Input v-model="form.delay" maxlength="100" type="number" style="width: 100px">
            <span slot="append">min</span>
          </Input>
        </FormItem>
        <FormItem prop="stationId" :label="showLang('work.tips.phone.dev.alarm')">
          <Input v-model="form.repeatTime" maxlength="100" type="number" style="width: 100px">
            <span slot="append">min</span>
          </Input>
        </FormItem>
        <FormItem prop="expired" :label-width="0">
          <AuthButton opCode='ane' long size="default" type="primary" style="margin-right: 5px" @click="saveSetting">保存</AuthButton>
        </FormItem>
      </Form>
    </div>
  </div>
</div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'AlarmNotifyIndex',
  components:{
  },
  props: {
  },
  data () {
    return {
      curTab: 'hour24',
      list: [],
      tabHeight: 600,
      loading: false,
      chksLevel10: [],
      form: {
        message: false,
        msgDelay: 10,
        phone: false,
        delay: 20,
        repeatTime: 5,
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['alarmLevels', 'productCodes', 'alarmTypes', 'alarmGroupList']),
  },
  watch: {
    windowOnResize(){
    },
  },
  mounted: function(){
    this.getList();
  },
  destroyed: function(){
  },
  methods: {
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/station/alarm/QueryNotify`, {}).then(res => {
        if(res.code == 0){
          // this.chksLevel10 = res.data.types.map(p => this.alarmTypes[p]);
          this.form.message = res.data.message;
          this.form.msgDelay = res.data.msgDelay;
          this.form.delay = res.data.delay;
          this.form.phone = res.data.phone;
          this.form.repeatTime = res.data.repeatTime;
        }
      });
    },
    getKeyByName: function(names){
      let keys = [];
      for(let key in this.alarmTypes){
        if(names.indexOf(this.alarmTypes[key]) != -1){
          keys.push(key);
        }
      }
      return keys;
    },
    saveSetting: function(){
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/alarm/SetNotify`, {message: this.form.message, repeatTime: this.form.repeatTime, msgDelay: this.form.msgDelay,phone:this.form.phone,delay:this.form.delay}).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info(this.showLang('com.tips.save'))
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-level-container{
  width: 100%;
  height: 100%;
  display: flex;
}
.alarm-level-item{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 3px;
  width: 350px;
  flex: auto;
  margin: 5px;
  display: flex;
}
.level-item-header{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 10px;
  /* height: 60px; */
  width: 480px;
  flex: none;
  display: flex;
}
</style>