<template>
  <Dropdown placement="bottom-end" trigger="click" @on-click="selectMenu">
    <a href="javascript:void(0)" style="display: flex;align-items:center;">
      <!-- <img style="width: 20px; height: 20px; margin-right: 5px" :src="img.pole.asset.level2.env" /> -->
      <span  :title="user.name" class='user-name'>{{user.name}}</span>
      <span style="color:#083366; margin: 0 10px">|</span>
      <img style="width: 12px; height: 8px; margin-right: 20px" :src="img.headerUserDown" />
    </a>
    <DropdownMenu slot="list">
      <DropdownItem name="switch">{{showLang('system.switch.app')}}</DropdownItem>
      <!-- <DropdownItem v-if="appTypes.length > 1" name="return">返回主菜单</DropdownItem> -->
      <DropdownItem name="system" v-if="appType == 'pole'">{{showLang('com.head.system')}}</DropdownItem>
      <Dropdown placement="left-start" style="display: block" v-if="hasMenu">
        <DropdownItem>
          {{showLang('system.platform.features')}}
          <Icon type="ios-arrow-forward"></Icon>
        </DropdownItem>
        <DropdownMenu slot="list">
          <AuthDropdownItem v-for="m in menus" :key="m.code" :opCode="m.code" :name="m.code">{{showLang(m.lang)}}</AuthDropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Dropdown placement="left-start" style="display: block" v-if="hasMenu">
        <DropdownItem>
          中文/English
          <Icon type="ios-arrow-forward"></Icon>
        </DropdownItem>
        <DropdownMenu slot="list">
          <DropdownItem name="Chinese">中文</DropdownItem>
          <DropdownItem name="English">English</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <DropdownItem name="modify">{{showLang('com.pswd.modify')}}</DropdownItem>
      <DropdownItem name="novice">{{showLang('system.novice.guidance')}}</DropdownItem>
      <DropdownItem name="logout">{{showLang('system.log.out')}}</DropdownItem>
    </DropdownMenu>
    <template v-for="(item, idx) in menus">
      <component v-model="item.show" :key="idx" v-bind:is="item.com"></component>
    </template>
    <ModalSwitchCustomer v-model="showSwitchModal" />
    <ModalModifyPswd v-model="showModifyModal" />
    <ModalNovice v-model="modalNoviceShow" />
  </Dropdown>
</template>
<script>
import Vue from 'vue';
import { mapState, mapGetters } from "vuex";
import { localData } from '@/plugins/cookie'
Vue.prototype.$localData = { localData }
import ModalSwitchCustomer from '@/views/common/modals/ModalSwitchCustomer'
// import ModalSwitchCustomer from '@/views/news/header/user/ModalSwitchCustomer'
import ModalWhereDevice from '@/views/common/modals/ModalWhereDevice'
// import ModalWhereUser from '@/views/common/modals/ModalWhereUser'
import ModalImageList from '@/views/common/modals/ModalImageList'
// import ModalCustList from '@/views/super/cust/Index'
import ModalAppList from './app/Index'
import ModalTransLog from '@/views/common/modals/ModalTransLogs'//this
import ModalTransOnlines from '@/views/common/modals/ModalTransOnlines'
// import ModalModifyPswd from '@/views/common/modals/ModalModifyPswd'
import ModalModifyPswd from '@/views/news/header/user/ModalModifyPswd'
import ModalDataMonitor from '@/views/common/modals/ModalDataMonitor'
// import ModalProtocolLogs from '@/views/common/modals/ModalProtocolLogs'
import ModalTaskMonitor from '@/views/common/modals/ModalTaskMonitor'
import ModalQueryIccid from '@/views/common/modals/ModalQueryIccid'

import ModalNovice from '@/views/common/modals/ModalNovice'
export default {
  name: 'HeaderDropDown',
  components: {
    ModalModifyPswd,
    ModalSwitchCustomer,
    ModalNovice,
  },
  props: {
  },
  data() {
    return {
      langActive: 'zh_cn',
      langList: [
        { name: '中文', code: 'zh_cn' },
        { name: 'English', code: 'en_us' },
      ],
      menus: [
        { code: 'c6', name: '通信监听', lang: 'system.comm.mon', com: ModalDataMonitor, show: false },
        { code: 'c1', name: '通信记录', lang: 'system.comm.records', com: ModalTransLog, show: false },
        { code: 'c2', name: '在线连接', lang: 'system.online.connection', com: ModalTransOnlines, show: false },
        { code: 'c3', name: '查找设备ID', lang: 'com.site.deviceID.find', com: ModalWhereDevice, show: false },
        // { code: 'c9', name: '查找用户', lang: 'auth.user.find', com: ModalWhereUser, show: false },
        { code: 'c10', name: '查询ICCID', lang: 'system.iccid.query', com: ModalQueryIccid, show: false },
        { code: 'c8', name: '任务信息', lang: 'system.task.info', com: ModalTaskMonitor, show: false },
        { code: 'c4', name: '应用配置', lang: 'system.app.configuration', com: ModalAppList, show: false },
        { code: 'c5', name: '图片资源', lang: 'system.image.resources', com: ModalImageList, show: false },
        // { code: 'c7', name: '解析错误日志', com: ModalProtocolLogs, show: false },
      ],
      showTransLogModal: false,
      showSwitchModal: false,
      showModifyModal: false,
      modalNoviceShow: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('auth', ['user', 'customerId', 'appType', 'appTypes', 'ops']),
    // canSwitch: function () {
    //   let t = 0;
    //   for (let app of this.appTypes) {
    //     t += app.custs.length;
    //   }
    //   return t > 1;
    // },
    hasMenu: function () {
      if (!this.ops) return false;
      if (this.user.isSuper) return true;
      let t = 0;
      this.menus.map(p => {
        t += this.ops.indexOf(p.code) == -1 ? 0 : 1;
      })
      return t > 0;
    },
  },
  mounted: function () {
  },
  methods: {
    langAjax(code) {
      localStorage.setItem("lighting_system_lang", code)
      this.$store.commit('auth/setLang', code);
      location.reload();
    },
    selectMenu: async function (name) {
      if (name == 'logout') {
        this.$store.dispatch('auth/logout').then(res => {
          if (res) {
            this.$Message.info(this.showLang('system.login.out'));
            let url = '/login';
            // console.log('log out', this.domains)
            // if(this.domains.version == 'new'){
            //   url = '/loginNew';
            // }
            this.$router.replace(url);
            // this.$router.push('/login');
          }
        });
      } else if (name == 'modify') {
        this.showModifyModal = true;
      } else if (name == 'return') {
        this.$router.push('/index/home');
      } else if (name == 'switch') {
        this.showSwitchModal = true;
      } else if (name == 'system') {
        this.$router.push('/index/pole/system');
      } else if (name == 'novice') {
        this.modalNoviceShow = true;
      } else if (name == 'Chinese') {
        this.langAjax('zh');
      } else if (name == 'English') {
        this.langAjax('en');
      } else {
        let ms = this.menus.filter(p => p.code == name);
        if (ms.length > 0) {
          ms[0].show = true;
        }
      }
    },
  }
}
</script>
<style scoped>
.user-name {
  text-align: center;
  word-break: keep-all;
  max-width: 70px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>