<template>
  <div class="power-container">
    <div class="power-areas">
      <CommonTree />
    </div>
    <div class="power-content">
      <div class="power-title">{{selectedNode.name}}
        <div class="float-btns">
          <input ref="fileInput" type="file" accept="image/*" style="visibility: hidden;width: 0;" @change="fileSelected" />
          <Button type="primary" size="small" @click="selectFile" style="margin-right:5px">设置图片</Button>
          设置位置
          <i-switch v-model="enableMoveDevice" size="large" true-color="#1B5FA8" false-color="#6D8194" title="打开可拖动设备以改变设备位置">
            <span slot="open">开启</span>
            <span slot="close">禁止</span>
          </i-switch>
        </div>
      </div>
      <div class="config-content">
        <div v-if="!currentGroup"></div>
        <div v-else class="light-content-area" :style="{backgroundImage: currentGroup.bgImg ? `url(//${domains.trans}/group/background/${currentGroup.bgImg}?auth=${token})` : '', width: currentGroup.width ? `${currentGroup.width}px` : '100%', height: currentGroup.height ? `${currentGroup.height}px` : '100%'}">
          <!-- <img class="content-area-bg" :src="img.light.factory.light.bg" /> -->
          <div class="sub-group-total" v-for="(group,idx) in groups" :key="`group${idx}`" @click.stop="selectItem(1, group)" :style="{top: `${group.y}px`, left: `${group.x}px`, border: (type == 1 && item.id == group.id && enableMoveDevice) ? 'solid 1px red':'0'}">
            <div class="sub-group-title">
              <img :src="img.light.factory.spread.groupPos" />
              {{group.name}}
            </div>
            <div class="sub-group-content">
              <div class="sub-group-item"><div class="group-item-title">智能控制柜</div><div class="group-item-value">{{group.box}}</div></div>
              <div class="sub-group-item"><div class="group-item-title">控制区域</div><div class="group-item-value">{{group.area}}</div></div>
              <div class="sub-group-item"><div class="group-item-title">单灯</div><div class="group-item-value">{{group.light}}</div></div>
              <div class="sub-group-item"><div class="group-item-title">亮灯</div><div class="group-item-value">{{group.lightOn}}</div></div>
              <div class="sub-group-item"><div class="group-item-title">灭灯</div><div class="group-item-value">{{group.lightOff}}</div></div>
              <div class="sub-group-item"><div class="group-item-title">当前负载</div><div class="group-item-value">{{group.power}}</div></div>
              <!-- <div class="sub-group-item"><div class="group-item-title">当月用电量</div><div class="group-item-value">50000</div></div> -->
            </div>
          </div>
          <div class="sub-area-total" v-for="(area,idx) in areas" :key="`area${idx}`" @click.stop="selectItem(3, area)" :style="{top: `${area.y}px`, left: `${area.x}px`,width: `${area.width}px`,height: `${area.height}px`, border: (type == 3 && item.id == area.id && enableMoveDevice) ? 'solid 1px red':'0'}">
            {{enableMoveDevice ? area.name : ''}}
          </div>
          <div class="sub-monitor-total" v-for="(monitor,idx) in stations" :key="`monitor${idx}`" @click.stop="selectItem(2, monitor)" :style="{top: `${monitor.y}px`, left: `${monitor.x}px`, border: (type == 2 && item.id == monitor.id && enableMoveDevice) ? 'solid 1px red':'0'}">
            <div class="monitor-total-content">
              <div class="sub-group-title">
                <img :src="img.light.factory.spread.groupPos" />
                {{monitor.name}}
              </div>
              <div class="sub-group-content">
                <div class="sub-group-item"><div class="group-item-title">电能</div><div class="group-item-value">-</div></div>
                <div class="sub-group-item"><div class="group-item-title">单灯</div><div class="group-item-value">{{monitor.light}}</div></div>
                <div class="sub-group-item"><div class="group-item-title">温度</div><div class="group-item-value">-</div></div>
                <div class="sub-group-item"><div class="group-item-title">亮灯</div><div class="group-item-value">{{monitor.lightOn}}</div></div>
                <div class="sub-group-item"><div class="group-item-title">柜门</div><div class="group-item-value">-</div></div>
                <div class="sub-group-item"><div class="group-item-title">灭灯</div><div class="group-item-value">{{monitor.lightOff}}</div></div>
                <!-- <div class="sub-group-item"><div class="group-item-title">当月用电量</div><div class="group-item-value">50000</div></div> -->
              </div>
            </div>
            <img class="monitor-total-img" :src="img.light.factory.spread.box" />
          </div>
        </div>
      </div>
    </div>
    <div class="set-pos-modal" v-if="enableMoveDevice">
      <Form ref="form" :model="form" :label-width="80">
        <FormItem label="X坐标" style="margin-bottom: 5px">
          <Slider v-model="form.x" :min="1" :max="currentGroup.width" style="flex:auto;margin: 0 15px"></Slider>
        </FormItem>
        <FormItem label="Y坐标" style="margin-bottom: 5px">
          <Slider v-model="form.y" :min="1" :max="currentGroup.height" style="flex:auto;margin: 0 15px"></Slider>
        </FormItem>
        <FormItem label="宽度" style="margin-bottom: 5px">
          <Slider v-model="form.width" :min="1" :max="currentGroup.width" style="flex:auto;margin: 0 15px"></Slider>
        </FormItem>
        <FormItem label="高度" style="margin-bottom: 5px">
          <Slider v-model="form.height" :min="1" :max="currentGroup.height" style="flex:auto;margin: 0 15px"></Slider>
        </FormItem>
        <FormItem :label-width="80" style="margin-bottom: 5px">
          <!-- <Button style="margin-right: 8px" @click="cancel">取消</Button> -->
          <Button type="primary" @click="ok">保存</Button>
        </FormItem>
      </Form>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import CommonTree from '@/views/common/tree/Index'
export default {
  name: 'FactoryLightIndex',
  components: {
    CommonTree,
  },
  data(){
    return {
      lightOnCurrentValue: 0.02, //灯亮电流阈值，即电流大于此值时，表示灯亮，否则灯灭
      enableMoveDevice: false,
      isCollapsed: false,
      hoverButtonName: '',
      bgWidth: 0,
      bgHeight: 0,
      poses: [],
      moveDevicePos: {
        show: false,
        lat: 0,
        lng: 0,
      },
      form:{
        type: 1,
        itemId: '',
        groupId: 0,
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
      type: 0,
      item: null,
      groups: [],
      stations: [],
      areas: [],
      lights: [],
    }
  },
  computed: {
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedNode', 'selectedGroups', 'selectedDevices', 'backImages', 'devPoses']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('auth', ['token']),
    subAreas: function(){
      // let mids = this.subMonitors.map(p => {return p.station.id});
      // let ss = this.stations.filter(p => mids.indexOf(p.station.id))
      let ass = [];
      this.subMonitors.map(s => {
        s.params.filter(p => p.type == 501).map(a => {
          let gs = this.devPoses.filter(q => q.type == 3 && q.groupId == this.selectedNode.id && q.posId == a.id);
          if(gs.length == 0){
            a.x = 0;
            a.y = 0;
            a.width = 100;
            a.height = 100;
          }else{
            a.x = gs[0].x;
            a.y = gs[0].y;
            a.width = gs[0].width;
            a.height = gs[0].height;
          }
          ass.push(a);
        })
      })
      return ass;
    },
    currentGroup: function(){
      let group = {id: this.selectedNode.id, name: this.selectedNode.name}
      let bs = this.backImages.filter(p => p.groupId == this.selectedNode.id);
      group.bgImg = bs.length == 0 ? '' : bs[0].bgImg;
      group.width = bs.length == 0 ? 0 : bs[0].width;
      group.height = bs.length == 0 ? 0 : bs[0].height;
      return group;
    },
  },
  watch: {
    "form.x"(){
      // console.log('fomr x change', this.item, this.type, this.form)
      if(this.type == 0 || !this.item)return;
      this.item.x = this.form.x;
    },
    "form.y"(){
      if(this.type == 0 || !this.item)return;
      this.item.y = this.form.y;
    },
    "form.width"(){
      if(this.type == 0 || !this.item)return;
      this.item.width = this.form.width;
    },
    "form.height"(){
      if(this.type == 0 || !this.item)return;
      this.item.height = this.form.height;
    },
    selectedNode(){
      // console.log('spread mounted 1')
      this.getGroupInfo();
    },
    windowOnResize(){

    },
  },
  mounted: function(){
    this.$store.dispatch('group/getGroupAndStations', -1)
    this.$store.dispatch('group/getDevPoses');
    this.$store.dispatch('group/getGroupImages');
    this.$store.commit('group/selectNodeById', {nodeId: 0, type: 'root'});
      // console.log('spread mounted')
  },
  methods: {
    getGroupInfo: function(){
      let groupId = this.selectedNode.id;
      if(!groupId)groupId = 0;
      this.$axios.post(`//${this.domains.trans}/station/config/QueryGroupInfo`, {groupId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          for(let g of res.data.groups){
            let gs = this.devPoses.filter(q => q.type == 1 && q.groupId == groupId && q.posId == g.id);
            if(gs.length == 0){
              g.x = 0;
              g.y = 0;
            }else{
              g.x = gs[0].x;
              g.y = gs[0].y;
            }
            g.box = res.data.stations.filter(p => p.groupId == g.id).length;
            g.area = res.data.areas.filter(p => p.groupId == g.id).length;
            g.light = res.data.lights.filter(p => p.groupId == g.id).length;
            g.lightOn = res.data.lights.filter(p => p.groupId == g.id && p.running).length;
            g.lightOff = res.data.lights.filter(p => p.groupId == g.id && !p.running).length;
            g.power = res.data.lights.filter(p => p.groupId == g.id).reduce((total, item) => total + item.power, 0);
          }
          this.$set(this, 'groups', res.data.groups);
          for(let g of res.data.stations){
          // console.log('test dev pos', this.devPoses, groupId, g.id)
            let gs = this.devPoses.filter(q => q.type == 2 && q.groupId == groupId && q.posId == g.id);
            if(gs.length == 0){
              g.x = 0;
              g.y = 0;
            }else{
              g.x = gs[0].x;
              g.y = gs[0].y;
            }
            g.light = res.data.lights.filter(p => p.stationId == g.id).length;
            g.lightOn = res.data.lights.filter(p => p.stationId == g.id && p.running).length;
            g.lightOff = res.data.lights.filter(p => p.stationId == g.id && !p.running).length;
          }
          this.$set(this, 'stations', res.data.stations);
          for(let g of res.data.areas){
            let gs = this.devPoses.filter(q => q.type == 3 && q.groupId == groupId && q.posId == g.id);
            if(gs.length == 0){
              g.x = 0;
              g.y = 0;
              g.width = 100;
              g.height = 100;
            }else{
              g.x = gs[0].x;
              g.y = gs[0].y;
              g.width = gs[0].width;
              g.height = gs[0].height;
            }
            g.light = res.data.lights.filter(p => p.areaId == g.id).length;
            g.lightOn = res.data.lights.filter(p => p.areaId == g.id && p.running).length;
            g.lightOff = res.data.lights.filter(p => p.areaId == g.id && !p.running).length;
          }
          this.$set(this, 'areas', res.data.areas);
          this.$set(this, 'lights', res.data.lights);
          // console.log('get group info', this.groups, this.stations, this.areas, this.lights);
        }
      });
    },
    selectItem: function(type, item){
      if(!this.enableMoveDevice){
        if(type == 1){
          this.$store.commit('group/selectNodeById', {nodeId: item.id, type: 'group'});
        }else if(type == 2){
          this.$router.push(`/index/light/factory/adjust?id=${item.id}`);
        }else if(type == 3){
          this.$router.push(`/index/light/factory/adjust?id=${item.stationId}`);
        }
        return;
      }
      this.type = type;
      this.item = item;
      this.form.x = this.item.x;
      this.form.y = this.item.y;
      if(this.type == 3){
        this.form.width = this.item.width;
        this.form.height = this.item.height;
      }
    },
    cancel: function(){},
    ok: function(){
      // let itemId = this.item.id;
      // if(this.type == 2){
      //   itemId = this.item.id;
      // }
      this.$store.dispatch('group/setDevPos', {
        groupId: this.selectedNode.id,
        type: this.type,
        itemId: this.item.id,
        x: this.form.x,
        y: this.form.y,
        width: this.form.width,
        height: this.form.height,
      }).then(res => {
        if(res.code === 0){
          this.$Message.info(`对象位置保存成功`);
          // console.log('save pos ', this.item, this.form)
        }else{
          // this.$Message.error(`保存对象位置数据出错：${res.data}`);
        }
      });
    },
    fileSelected: function(e){
      if(e.target.files.length == 0){
        this.$Message.warning('请选择文件');
        return;
      }
      var reader = new FileReader(); //实例化文件读取对象
      let that = this;
      reader.onload = function(ev) { //文件读取成功完成时触发
        var img = new Image();
        img.onload = function(){
          var width = img.width;
          var height = img.height;
          var form = {};
          form.file = ev.target.result;
          form.groupId = that.selectedNode.id;
          form.width = width;
          form.height = height;
          that.$store.dispatch('group/setGroupImage', form).then(res => {
            if(res.code == 0){
              that.$Message.info('图片设置成功');
            }else{
              // that.$Message.error(res.data);
            }
          });
          that.$refs.fileInput.value = '';
        };
        img.src = ev.target.result;
      }
      reader.readAsDataURL(e.target.files[0]); //将文件读取为 DataURL,也就是base64编码
    },
    selectFile: function(){
      // console.log('sel file', this.currentGroup);
      if(this.selectedNode == null){
        this.$Message.warning('请选择一个分组');
        return;
      }
      this.$refs.fileInput.click();
    },
  }
}
</script>
<style scoped>
.sub-monitor-total{
  position: absolute;
  user-select:none;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: solid 1px red; */
}
.monitor-total-content{
  /* border: solid 1px red; */
  width: 328px;
  height: 130px;
  flex: none;
  padding: 10px;
  background: #012645;
  opacity: 0.7;
}
.monitor-total-img{
  width: 38px;
  height: 71px;
  flex: none;
  /* border: solid 1px red; */
}
.set-pos-modal{
  padding-top: 20px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 400px;
  height: 220px;
  background: #0cd3fc;
  opacity: 0.7;
}
.sub-group-total{
  width: 328px;
  height: 151px;
  background: #012645;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: absolute;
  user-select:none;
}
.sub-group-title{
  background: linear-gradient(90deg, #0cd3fc, #02223f);
  width: 100%;
  height: 22px;
  flex: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  display: flex;
  align-items: center;
}
.sub-group-title img{
  margin: 8px;
}
.sub-group-content{
  height: 100px;
  flex: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.sub-group-item{
  width: 140px;
  height: 16px;
  flex: none;
  /* border: solid 1px red; */
  display: flex;
  justify-content: space-around;
}
.group-item-title{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  width: 70px;
  flex: none;
}
.group-item-value{
  width: 60px;
  flex: none;
  font-size: 16px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #FAD041;
  text-align: right;
}
/* .sub-monitor-total img{
  width: 38px;
  height: 71px;
} */
.sub-area-total{
  user-select:none;
  position: absolute;
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0);
  box-shadow: inset 0px 0px 29px 0px rgba(255, 241, 82, 0.952);
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
}
.float-btns{
  
  float:right;
  color:white;
  font-size:16px;
  display:flex;
  align-items:center;
  justify-content: flex-end;
  margin-top: 15px;
}
.power-container{
  /* border: solid 1px red; */
  width: 100%;
  display: flex;
  background-color: #011220;
}
.power-areas{
  /* border: solid 1px red; */
  width: 300px;
  margin: 3px;
  flex: none;
  background-color: #001F37;
  display: flex;
  flex-direction: column;
}
.power-item{
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}
.power-item div{
  width: 4px;
  height: 60px;
  background: transparent;
}
.power-item img{
  margin: 0 10px;
}
.power-selected{
  background: linear-gradient(90deg, #00305D, #001F37);
  color: #51E7F3;
}
.power-selected div{
  background: #0CD3FC;
  border-radius: 0px 2px 2px 0px;
}
.power-content{
  /* border: solid 1px red; */
  margin: 3px;
  width: 0;
  flex: auto;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #001323, #0E244A);
  box-shadow: inset 0px 0px 32px 0px #013565;
  /* align-items: center; */
}
.power-title{
  padding: 10px;
  /* border: solid 1px red; */
  height: 60px;
  flex: none;
  text-align: center;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #51E7F3;
}
.power-time{
  /* padding: 30px; */
  /* border: solid 1px red; */
  height: 30px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #058CA1;
  text-align: center;
}
.config-content{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* display: flex;
  flex-direction: column; */
  /* padding: 30px 30px 0 30px; */
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.config-content::-webkit-scrollbar {
  display: none;
}
.light-content-area{
  /* border: solid 1px red; */
  /* width: 100%; */
  /* height: 100%; */
  /* height: 300px; */
  /* margin: 0 auto; */
  flex: none;
  display: flex;
  /* justify-content: center; */
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.content-area-bg{
  width: 1036px;
  height: 530px;
  /* border: solid 1px red; */
}
.content-area-item{
  position: absolute;
  width: 30px;
  height: 30px;
}
.light-power-area{
  height: 300px;
  flex: none;
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.light-power-item{
  /* border: solid 1px red; */
  width: 300px;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 30px;
}
.light-power-item img{
  /* width: 116px;
  height: 78px; */
  flex: none;
}
.content-box-data{
  margin-top: 20px;
  width: 300px;
  height: 150px;
  /* flex: none; */
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  /* border: 1px solid #6EFFFA; */
  /* opacity: 0.6; */
  background: linear-gradient(0deg, #0d5893, #00335b);
  position: relative;
}
.box-data-item{
  /* border: solid 1px red; */
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
}
.box-data-item img{
  margin: 0 5px;
  flex: none;
}
.box-data-title{
  width: 70px;
  flex: none;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  letter-spacing: 8px;
}
.box-data-value{
  flex: auto;
  width: 91px;
  height: 30px;
  padding-left: 10px;
  line-height: 30px;
  background: #05375E;
  box-shadow: inset 0px -1px 1px 0px rgba(255, 255, 255, 0.2), 0px 2px 3px 0px #001322;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #51E7F3;;
}
.content-main-border{
  position: absolute;
  width: 20px;
  height: 20px;
  border: solid 1px #6EFFFA;
}
</style>
