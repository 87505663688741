<template>
  <Modal v-model="showModal" :mask-closable="false" width="800px">
    <div slot="header">{{title}}</div>
    <div class="softUpdate">
      <vxe-table resizable border show-overflow row-key :row-config="{isHover: true}" :data="list" :scroll-y="{enabled: false}" height="500">
        <vxe-column field="custName" title="组织名称"></vxe-column>
        <vxe-column field="start" title="开始日期"></vxe-column>
        <vxe-column field="end" title="结束日期"></vxe-column>
        <vxe-column>
          <template #header>
            <Button size="small" type="primary" style="margin-right: 5px" @click="handleEdit(false)">新增</Button>
          </template>
          <template #default="{ row }">
            <Button size="small" type="success" style="margin-right: 5px" @click="handleEdit(true,row)">编辑</Button>
            <Button size="small" type="error" style="margin-right: 5px" @click="handleDelete(row)">删除</Button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <Modal v-model="showUpdateInfo" :mask-closable="false" width="600px">
      <div slot="header">更新范围信息</div>
      <div class="user-edit-content">
        <div class="query-data-area">
          选择组织： <Button style="margin-right: 8px" :disabled='custIdAdd' type="primary" @click="custBut">{{custButName}}</Button>
        </div>
        <div class="query-data-area mt20">
          开始日期：
          <DatePicker type="date" v-model="filter.start" placeholder="开始日期" style="width: 200px" />
        </div>
        <div class="query-data-area mt20">
          结束日期：
          <DatePicker type="date" v-model="filter.end" placeholder="结束日期" style="width: 200px" />
        </div>

      </div>
      <div slot="footer">
        <Button style="margin-right: 8px" @click="showUpdateInfocancel">关闭</Button>
        <Button style="margin-right: 8px" type="primary" @click="showUpdateInfoOk">保存</Button>
      </div>
    </Modal>
    <Modal v-model="showCusList" :mask-closable="false" width="600px">
      <div slot="header">组织列表</div>
      <span class="ml10">组织名称：</span>
      <Input type="text" v-model="cusAjax.name" clearable placeholder="搜索字段" style="width: 140px; margin-right: 10px;"></Input>
      <Button style="margin-right: 8px" type="primary" @click="cusListAjax(cusAjax.index=1)">搜索</Button>
      <div class="user-edit-content mt20">
        <vxe-table resizable border show-overflow row-key ref="xTable1" :row-config="{isHover: true}" :data="cusList" :scroll-y="{enabled: false}" height="400">
          <vxe-column type="radio" width="60"></vxe-column>
          <vxe-column field="name" title="组织名称"></vxe-column>
          <vxe-column field="linkman" title="联系人"></vxe-column>
          <vxe-column field="mobile" title="联系方式"></vxe-column>
        </vxe-table>
        <vxe-pager :current-page.sync="cusAjax.index" :page-size.sync="cusAjax.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
      </div>
      <div slot="footer">
        <Button style="margin-right: 8px" @click="showCusList=false">关闭</Button>
        <Button style="margin-right: 8px" type="primary" @click="showCusListOk()">确定</Button>
      </div>
    </Modal>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="showModal=false">关闭</Button>
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'softUpdate',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    softId: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      showModal: this.value,
      editItem: {},
      showUpdateInfo: false,
      showCusList: false,
      custIdAdd: false,
      filesName: '',
      cusTitle: '',
      cusList: [],
      pageSizes: [20, 100, 500, 1000, 5000],
      custButName: '选择组织',
      list: [],
      filter: {
        "softId": "",
        "custId": 12,
        "start": "2024-08-09",
        "end": "2024-08-10",
      },
      total: 20,
      cusAjax: {
        "name": "",
        "index": 1,
        "size": 20
      }
    }
  },
  computed: {
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (!this.showModal) return;
      this.filter.softId = this.softId;
      this.getList();
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  methods: {
    showUpdateInfocancel() {
      this.showUpdateInfo = false;
    },
    showCusListOk() {
      let ps = this.$refs.xTable1.getRadioRecord()
      if (ps != null) {
        this.filter.custId = ps.id;
        this.custButName = ps.name;
      }
      this.showCusList = false;
    },
    custBut() {
      this.cusListAjax();
      this.showCusList = true;
    },
    showUpdateInfoOk() {
      if (this.filter.custId == 0) {
        this.$Message.warning('请选择组织');
        return false
      }
      if (this.filter.start == '' || this.filter.end == '') {
        this.$Message.warning('请选择时间');
        return false
      }
      this.filter.start = new Date(this.filter.start).format('yyyy-MM-dd');
      this.filter.end = new Date(this.filter.end).format('yyyy-MM-dd');
      this.$axios.post(`site/debug/SaveSoftUpdateRange`, this.filter).then(res => {
        this.showUpdateInfo = false;
        if (res.code == 0) {
          this.getList();
        }
      });
    },
    getList() {
      this.$axios.post(`site/debug/QuerySoftUpdateRange`, { id: this.softId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
          this.list.forEach(el => {
            el.start = new Date(el.start).format('yyyy-MM-dd');
            el.end = new Date(el.end).format('yyyy-MM-dd');
          });
        }
      });
    },
    handlePageChange({ currentPage, pageSize }) {
      this.cusAjax.index = currentPage
      this.cusAjax.size = pageSize
      this.cusListAjax()
    },
    cusListAjax() {
      this.$axios.post(`site/debug/GetCustList`, this.cusAjax).then(res => {
        if (res.code == 0) {
          this.$set(this, 'cusList', res.data.list);
          this.total = res.data.count;

        }
      });
    },
    handleDelete(el) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除[${el.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`site/debug/DeleteSoftUpdateRange`, { softId: this.softId, custId: el.custId }).then(res => {
            if (res.code == 0) {
              this.$Message.info('删除成功');
              this.getList();
            }
          })
        }
      });
    },
    handleEdit(is, el) {
      this.custButName = is ? el.custName : '选择组织';
      if (is) {
        this.filter.custId = el.custId;
        this.filter.start = el.start;
        this.filter.end = el.end;
        this.custIdAdd = true;
      } else {
        this.filter.custId = 0;
        this.filter.start = '';
        this.filter.end = '';
        this.custIdAdd = false;
      }
      this.showUpdateInfo = true;
    },
  }
}
</script>
<style scoped>
.user-edit-content {
  display: flex;
  flex-direction: column;
}
.file-control {
  display: none;
}
</style>