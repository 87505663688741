<template>
  <div class="policePageBox">
    <Modal ref="modalAlarmSpecial" v-model="showModal" :mask-closable="false" draggable width="1100px" footer-hide style="">
      <div class="police-top" slot="header">
        <img :src="img.light.carat.gis.policePosition" />
        实时报警
      </div>
      <div class="analyse-table">
        <table border="0" cellspacing="0" cellpadding="0" v-if="alarmsData && alarmsData.length > 0">
          <tr v-for="(item, idx) in alarmsData" :key="idx">
            <td style="width: 10px;" @click="showAlarmHisData(item)">
              <div class="block"></div>
            </td>
            <td style="width: 170px; text-align: left" @click="showAlarmHisData(item)">
              <template v-if="getItemNameLength(item) <= 10">{{item.stationName}}</template>
              <Tooltip v-else placement="top-start" style="width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                {{item.stationName}}
                <div slot="content" style="white-space: normal">
                  {{item.stationName}}
                </div>
              </Tooltip>
            </td>
            <td style="width: 100px; text-align: left" @click="showAlarmHisData(item)">
              <template v-if="getItemNameLength(item) <= 10">{{item.pName}}</template>
              <Tooltip v-else placement="top-start" style="width: 100px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                {{item.pName}}
                <div slot="content" style="white-space: normal">
                  {{item.pName}}
                </div>
              </Tooltip>
            </td>
            <td style="width: 120px; text-align: left;color:#E84F4D;" @click="showAlarmHisData(item)">
              <template v-if="item.name && item.name.length <= 6">{{item.name}}</template>
              <Tooltip v-else placement="top-start" style="width: 100px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                {{item.name}}
                <div slot="content" style="white-space: normal">
                  {{item.name}}
                </div>
              </Tooltip>
            </td>
            <td style="width: 470px; text-align: left" @click="showAlarmHisData(item)">
              <template v-if="!item.extra || item.extra.length <= 30">{{item.extra}}</template>
              <Tooltip v-else placement="top-start" class="tl" style="width: 450px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                {{item.extra}}
                <div slot="content" style="white-space: normal">
                  {{item.extra}}
                </div>
              </Tooltip>
            </td>
            <td style="width: 170px;" @click="showAlarmHisData(item)">{{item.createTime}}</td>
          </tr>
        </table>
        <div v-else>
          <div class="lux-empty">
            <img :src="img.lux.empty" />
            <div class="empty-text">暂无报警</div>
          </div>
        </div>
      </div>
      <ModalAlarmData v-model="showAlarmDataModal" :item="editItem" />
    </Modal>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapState } from 'vuex'
import ModalAlarmData from '../../../road/monitor/station/alarm/ModalAlarmData'//light/road/monitor/station/alarm/ModalAlarmData
import { sessionData } from '@/plugins/cookie'
Vue.prototype.$sessionData = { sessionData }
export default {
  name: 'Home',
  components: {
    ModalAlarmData
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showModal: this.value,
      curtab: 'light',
      editItem: {},
      showAlarmDataModal: false,
      isShowPoliceBox: true,
      alarms: [],
      timeout: null,
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.initData();
        // this.initModalPosition();
      }
    },
    selectedNode() {
      if (this.showModal) {
        this.initData();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    stationAlarmChanged() {
      if (this.showModal) {
        this.debounceInitData();
      }
    }
  },
  destroyed: function () {
  },
  computed: {
    ...mapState('group', ['selectedNode', 'groupLoaded', 'selectedDevices', 'stationAlarmChanged']),
    ...mapState('common', ['alarmTypes', 'productCodes']),
    alarmsData: function () {
      if (this.curtab == 'light') {
        return this.alarms.filter(p => p.paramType == 199);
      } else if (this.curtab == 'power') {
        return this.alarms.filter(p => p.paramType < 100);
      } else {
        return [];
      }
    },
  },
  mounted: function () {
    this.initModalPosition();
    let isShow = this.$sessionData.sessionData('get', "policeBox", "");
    if (isShow == true || isShow == 'true' || isShow == null) {
      this.isShowPoliceBox = true;
    } else {
      this.isShowPoliceBox = false;
    }
  },
  methods: {
    debounceInitData: function () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.initData, 500);
    },
    initData: function () {
      let ajaxData = {
        "groupId": this.selectedNode.id,
        "paramId": 0,
        "level": 0,
        "type": 0,
        "done": false,
        "start": "2023-03-01 10:24:00",
        "end": "2023-03-14 10:24:00",
        "size": 9999,
        "index": 1
      }
      let now1 = new Date();
      ajaxData.end = now1.format('yyyy-MM-dd HH:mm:ss');
      now1.setDate(now1.getDate() - 30);
      ajaxData.start = now1.format('yyyy-MM-dd HH:mm:ss');
      this.$axios.post(`//${this.domains.trans}/station/light/QueryAlarm`, ajaxData).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'alarms', res.data.list);
        }
      });
    },
    getItemNameLength: function (item) {
      let len = 0;
      if (item.stationName) {
        len += item.stationName.length;
      }
      if (item.pName) {
        len += item.pName.length;
      }
      return len;
    },
    initModalPosition: function () {
      let modal = this.$refs.modalAlarmSpecial.$refs.content;//.querySelector('.ivu-modal-content-drag');
      modal.style.top = `${300}px`;
      modal.style.left = `${400}px`;
    },
    switchTab: function (item) {
      this.curtab = item;
    },
    getParamType: function (item) {
      if (item.paramType > 0 && item.paramType < 100) return `[柜]`;
      else if (item.paramType == 199) return '[灯]';
      return '';
    },
    showAlarmHisData: function (item) {
      this.editItem = item;
      this.showAlarmDataModal = true;
    },
    alarmsJump(index) {
      this.$store.commit('auth/setStationMenu', "alarm");
      this.isShowPoliceBox = false;
      this.alarmsData.filter(el => {
        el.isColor = false;
      });
      if (index != -1) {
        this.alarmsData[index].isColor = true
      }
    },
    cancel: function () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
>>> .ivu-modal .ivu-modal-content-drag {
  background: #122f51;
}
.lux-empty {
  width: 100%;
  height: 100%;
  position: relative;
}
.empty-text {
  /* border: solid 1px red; */
  position: absolute;
  top: 280px;
  left: 50%;
  width: 100px;
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  line-height: 26px;
  text-align: center;
  margin-left: -50px;
}
.lux-empty img {
  position: absolute;
  top: 30px;
  left: 50%;
  width: 340px;
  height: 290px;
  margin-left: -170px;
}
.modal-class {
  border: solid 1px red;
}
.tab-item {
  flex: none;
  cursor: pointer;
  background: #ebf0f9;
  border-radius: 19px;
  padding: 0 23px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737d;
  text-align: center;
  margin-right: 30px;
}
.tab-active {
  background-color: #e84f4d;
  color: #ffffff;
}
.police-box {
  width: 460px;
  height: 416px;
  background: #edf2f9;
  border-radius: 12px;
  position: fixed;
  right: 230px;
  bottom: 85px;
  z-index: 500;
}
.police-top {
  border-radius: 6px 6px 0px 0px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  margin: -15px;
  display: flex;
  padding: 15px 15px;
}
.police-top img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  vertical-align: middle;
}
.police-list {
  width: 440px;
  height: 335px;
  background: #ffffff;
  box-shadow: 0px 12px 30px 0px rgba(31, 106, 237, 0.2);
  border-radius: 12px;
  margin: 10px auto 0;
}
.police-but {
  width: 78px;
  height: 28px;
  border: 1px solid #3880fc;
  border-radius: 4px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3880fc;
  line-height: 22px;
  text-align: center;
  float: right;
  margin: 10px 15px 0 0;
  cursor: pointer;
}
.police-ul {
  height: 280px;
  list-style-type: square;
  color: #c30f0b;
  padding: 0 15px 0 18px;
  overflow-y: auto;
}
.police-li {
  display: flex;
  justify-content: space-between;
  height: 35px;
  line-height: 35px;
}
.block {
  /* display: inline-block; */
  width: 6px;
  height: 6px;
  background: #db0e30;
  /* vertical-align: middle; */
}
.analyse-table {
  width: 100%;
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.analyse-table table td {
  text-align: center;
  box-sizing: border-box;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
  height: 36px;
  cursor: pointer;
  background: #071a31;
  padding-left: 5px;
}

.analyse-table table tr:last-child td {
  border-bottom: none;
}
.analyse-table table tr:nth-of-type(odd) td {
  border-top: 6px solid #fff;
  border-bottom: 6px solid #fff;
  height: 46px;
}
.analyse-table table tr td:last-child {
  border-right: none;
}
.police-con {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
}
.police-con.police-title {
  max-width: 225px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
</style>