<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">编辑单灯信息</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="110">
      <FormItem prop="stationId" label="所在分区" style="margin-right: 15px">
        <div class="flex-row">
          <Select v-model="form.groupId" @on-change="groupChanged" style="width: 200px; margin-right: 10px;">
            <Option v-for="(s, idx) in groups" :key="idx" :value="s.id">{{s.name}}</Option>
          </Select>
          单灯分组
          <Select v-model="form.content.area" style="width: 200px; margin: 0 10px;">
            <template v-for="(s, idx) in areas">
              <Option :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
            </template>
          </Select>
          单灯类型
          <Select v-model="form.content.type" style="width: 200px; margin: 0 10px;">
            <template v-for="(v, k) in newLightTypes">
              <Option :value="parseInt(k)" :key="k">{{v}}</Option>
            </template>
          </Select>
        </div>
      </FormItem>
      <FormItem prop="name" label="所在灯杆" style="margin-right: 15px">
        <div class="flex-row">
          <Select v-model="form.content.pole" style="width: 200px; margin-right: 10px;" @on-change="poleChanged">
            <Option :value="0">不关联</Option>
            <template v-for="(s, idx) in poles">
              <Option :value="s.id" :key="idx">{{s.name}}</Option>
            </template>
          </Select>
          灯控名称
          <Input type="text" v-model="form.name" placeholder="灯控名称" style="width: 475px; margin: 0 10px;"></Input>
        </div>
      </FormItem>
      <FormItem prop="connectType" label="控制模式" style="margin-right: 15px">
        <div class="flex-row">
          <Select v-model="form.content.mode" style="width: 200px; margin-right: 10px;">
            <Option :value="0">计时日表</Option>
            <Option :value="1">准时日表</Option>
            <!-- <Option :value="2">光控模式</Option> -->
          </Select>
          计时日表
          <Select v-model="form.content.timeId1" style="width: 200px; margin: 0 10px;">
            <!-- <Option :value="0">无</Option> -->
            <template v-for="(s, idx) in timeTables">
              <Option v-if="s.type == '计时日表'" :value="s.id" :key="idx">[{{s.mode}}][{{s.type}}]{{s.name}}</Option>
            </template>
          </Select>
          准时日表
          <Select v-model="form.content.timeId2" style="width: 200px; margin: 0 10px;">
            <!-- <Option :value="0">无</Option> -->
            <template v-for="(s, idx) in timeTables">
              <Option v-if="s.type == '准时日表'" :value="s.id" :key="idx">[{{s.mode}}][{{s.type}}]{{s.name}}</Option>
            </template>
          </Select>
        </div>
      </FormItem>
      <FormItem prop="connectType" label="单灯类型" style="margin-right: 15px">
        <div class="flex-row">
          <Select ref="smallType" v-model="form.content.stid" number style="width: 200px; margin: 0 25px 0 0;">
            <template v-for="(item, idx) in smallTypes">
              <Option v-if="item.type == 3" :value="item.code" :key="idx">{{item.name}}</Option>
            </template>
          </Select>
          通信ID
          <Input type="text" v-model="form.code" style="width: 200px; margin: 0 10px;"></Input>
        </div>
      </FormItem>
      <FormItem prop="connectType" label="关联网关" style="margin-right: 15px">
        <div class="flex-row">
          <Select v-model="form.content.mid" style="width: 200px; margin-right: 10px;">
            <Option :value="0">不关联网关</Option>
            <Option v-for="(dev, didx) in gates" :key="didx" :value="dev.id">[{{dev.code}}]{{dev.name}}</Option>
          </Select>
          上行通道
          <Input type="text" v-model="form.channel" style="width: 200px; margin: 0 10px;"></Input>
        </div>
      </FormItem>
      <FormItem prop="enp" label="一路控制" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.en1" size="large" true-color="green" false-color="red" style="margin: 0 10px;width:56px">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
          <Select v-model="form.content.nm1" style="margin: 0 10px;width:100px">
            <Option value="主灯">主灯</Option>
            <Option value="辅灯">辅灯</Option>
          </Select>
          <Input type="number" v-model="form.content.pr1" style="width: 200px; margin: 0 5px;"><span slot="prepend">额定功率</span><span slot="append">W</span></Input>
          <Input type="number" v-model="form.content.lc1" style="width: 200px; margin: 0 5px;"><span slot="prepend">灯具数量</span><span slot="append">个</span></Input>
        </div>
      </FormItem>
      <FormItem prop="enp" label="二路控制" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.en2" size="large" true-color="green" false-color="red" style="margin: 0 10px;width:56px">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
          <Select v-model="form.content.nm2" style="margin: 0 10px;width:100px">
            <Option value="主灯">主灯</Option>
            <Option value="辅灯">辅灯</Option>
          </Select>
          <Input type="number" v-model="form.content.pr2" style="width: 200px; margin: 0 5px;"><span slot="prepend">额定功率</span><span slot="append">W</span></Input>
          <Input type="number" v-model="form.content.lc2" style="width: 200px; margin: 0 5px;"><span slot="prepend">灯具数量</span><span slot="append">个</span></Input>
        </div>
      </FormItem>
      <FormItem prop="enp" label="三路控制" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.en3" size="large" true-color="green" false-color="red" style="margin: 0 10px;width:56px">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
          <Select v-model="form.content.nm3" style="margin: 0 10px;width:100px">
            <Option value="主灯">主灯</Option>
            <Option value="辅灯">辅灯</Option>
          </Select>
          <Input type="number" v-model="form.content.pr3" style="width: 200px; margin: 0 5px;"><span slot="prepend">额定功率</span><span slot="append">W</span></Input>
          <Input type="number" v-model="form.content.lc3" style="width: 200px; margin: 0 5px;"><span slot="prepend">灯具数量</span><span slot="append">个</span></Input>
        </div>
      </FormItem>
      <FormItem prop="enp" label="四路控制" style="margin-right: 15px">
        <div class="flex-row">
          <i-switch v-model="form.content.en4" size="large" true-color="green" false-color="red" style="margin: 0 10px;width:56px">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
          <Select v-model="form.content.nm4" style="margin: 0 10px;width:100px">
            <Option value="主灯">主灯</Option>
            <Option value="辅灯">辅灯</Option>
          </Select>
          <Input type="number" v-model="form.content.pr4" style="width: 200px; margin: 0 5px;"><span slot="prepend">额定功率</span><span slot="append">W</span></Input>
          <Input type="number" v-model="form.content.lc4" style="width: 200px; margin: 0 5px;"><span slot="prepend">灯具数量</span><span slot="append">个</span></Input>
        </div>
      </FormItem>
      <FormItem prop="tr" label="温度监测" style="margin-right: 15px">
        <Input type="number" v-model="form.content.tr" style="width: 200px; margin-right: 5px;"><span slot="prepend">阈值</span><span slot="append">℃</span></Input>
      </FormItem>
      <FormItem prop="enp" label="电压监测" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="number" v-model="form.content.ur" style="width: 200px; margin-right: 5px;"><span slot="prepend">额定值</span><span slot="append">V</span></Input>
          <Input type="number" v-model="form.content.ul" style="width: 150px; margin: 0 5px;"><span slot="prepend">下限</span><span slot="append">%</span></Input>
          <Input type="number" v-model="form.content.uh" style="width: 150px; margin: 0 5px;"><span slot="prepend">上限</span><span slot="append">%</span></Input>
        </div>
      </FormItem>
      <FormItem prop="enp" label="漏电监测" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="number" v-model="form.content.ll" style="width: 200px; margin-right: 5px;"><span slot="prepend">一级</span><span slot="append">mA</span></Input>
          <Input type="number" v-model="form.content.lh" style="width: 150px; margin: 0 5px;"><span slot="prepend">二级</span><span slot="append">mA</span></Input>
          <Input type="number" v-model="form.content.lx" style="width: 150px; margin: 0 5px;"><span slot="prepend">三级</span><span slot="append">mA</span></Input>
        </div>
      </FormItem>
      <FormItem prop="enp" label="水浸监测" style="margin-right: 15px">
        <div class="flex-row">
          一级通道号
          <Select v-model="form.content.l1" style="margin: 0 10px;width:100px">
            <Option :value="0">无</Option>
            <Option v-for="n in 16" :value="n" :key="n">S{{n}}</Option>
          </Select>
          二级通道号
          <Select v-model="form.content.l2" style="margin: 0 10px;width:100px">
            <Option :value="0">无</Option>
            <Option v-for="n in 16" :value="n" :key="n">S{{n}}</Option>
          </Select>
          三级通道号
          <Select v-model="form.content.l3" style="margin: 0 10px;width:100px">
            <Option :value="0">无</Option>
            <Option v-for="n in 16" :value="n" :key="n">S{{n}}</Option>
          </Select>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
    <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
  </Modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalLampEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    groups: {
      type: Array,
      default(){ return [];}
    },
    areas: {
      type: Array,
      default(){ return [];}
    },
    gates: {
      type: Array,
      default(){ return [];}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      accounts: [],
      poles: [],
      devices: [],
      timeTables: [],
      form: {
        id: 0,
        groupId: 0,
        channel: 0,
        deviceId: 0,
        code: '',
        name: '',
        content: {
          //基本配置相关
          mid: 0, //网关属性id
          type: 101, //单灯类型
          area: 0, //单独所属控制区域
          pole: 0, //关联灯杆
          timeId1: 0, //计时日表ID
          timeId2: 0, //准时日表ID
          mode: 0, //控制模式
          stid: 0, //设备小类
          ur: 220, //额定电压
          uh: 120, //电压阈值上限
          ul: 80, //电压阈值下限
          tr: 70, //温度阈值
          ll: 100, //一级漏电电流阈值
          lh: 1000, //一级漏电电流阈值
          lx: 2000, //一级漏电电流阈值
          l1: 0, //一级水浸通道号
          l2: 0, //一级水浸通道号
          l3: 0, //一级水浸通道号
          //1路
          en1: true, //控制使能
          nm1: '主灯', //名称
          gp1: 0, //分组号
          pr1: 100, //额定功率
          lc1: 1, //灯具数量
          //2路
          en2: false, //控制使能
          nm2: '辅灯', //名称
          gp2: 0, //分组号
          pr2: 100, //额定功率
          lc2: 1, //灯具数量
          //3路
          en3: false, //控制使能
          nm3: '辅灯', //名称
          gp3: 0, //分组号
          pr3: 100, //额定功率
          lc3: 1, //灯具数量
          //4路
          en4: false, //控制使能
          nm4: '辅灯', //名称
          gp4: 0, //分组号
          pr4: 100, //额定功率
          lc4: 1, //灯具数量
        }
      },
      rules: {
        // name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
        // topCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
        // location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        // monitorCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        // console.log('modal level edit mounted', this.groups)
        this.isAdd = this.item.isAdd;
        this.getUseablePlan();
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.groupId = this.item.data.groupId;
          this.form.name = this.item.data.name;
          this.form.code = this.item.data.code;
          this.form.deviceId = this.item.data.deviceId;
          this.form.channel = this.item.data.channel;
          this.groupChanged(() => {
            for(let k in this.form.content){
              this.form.content[k] = this.item.data.content[k];
            }
          });
        }else{
          this.form.id = 0;
          this.form.groupId = this.groups.length > 0 ? this.groups[0].id : 0;
          this.form.deviceId = 0;
          this.form.name = '';
          this.form.code = '';
          this.form.channel = 6;
          this.groupChanged(() => {
          });

          // this.form.type = 101;
          this.form.content = {
            type: 101, //单灯类型
            area: 0, //单独所属控制区域
            pole: 0, //关联灯杆
            timeId1: 0, //计时日表ID
            timeId2: 0, //准时日表ID
            mode: 0, //控制模式
            stid: 0, //设备小类
            ur: 220, //额定电压
            uh: 120, //电压阈值上限
            ul: 80, //电压阈值下限
            tr: 70, //温度阈值
            ll: 100, //一级漏电电流阈值
            lh: 1000, //一级漏电电流阈值
            lx: 2000, //一级漏电电流阈值
            l1: 0, //一级水浸通道号
            l2: 0, //一级水浸通道号
            l3: 0, //一级水浸通道号
            //1路
            en1: true, //控制使能
            nm1: '主灯', //名称
            gp1: 0, //分组号
            pr1: 100, //额定功率
            lc1: 1, //灯具数量
            //2路
            en2: false, //控制使能
            nm2: '辅灯', //名称
            gp2: 0, //分组号
            pr2: 100, //额定功率
            lc2: 1, //灯具数量
            //3路
            en3: false, //控制使能
            nm3: '辅灯', //名称
            gp3: 0, //分组号
            pr3: 100, //额定功率
            lc3: 1, //灯具数量
            //4路
            en4: false, //控制使能
            nm4: '辅灯', //名称
            gp4: 0, //分组号
            pr4: 100, //额定功率
            lc4: 1, //灯具数量
          }
        }
        // console.log('modal level edit mounted', this.form.content, this.item.data.content)
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
    // 'form.stationId'(){
    //   this.form.paramId = 0;
    // },
  },
  computed: {
    ...mapState('common', ['newLightTypes','deviceTypes', 'productCodes', 'bigTypes', 'smallTypes']),
    boxType: function () {
      return function (deviceId) {
        // if (!this.selectedStation) return 'none';
        if (deviceId <= 0) return 'none';
        let devs = this.devices.filter(p => p.id == deviceId);
        if (devs.length == 0) return 'none';
        return devs[0].type;
      }
    },
  },
  mounted: function(){
    // this.getProjects();
    // console.log('modal level edit mounted', this.alarmTypes, this.alarmGroupList)
  },
  methods: {
    poleChanged: function(){
      if(this.form.content.pole == 0){
        this.form.name = '';
      }else{
        let ps = this.poles.filter(p => p.id == this.form.content.pole);
        if(ps.length == 0){
          this.form.name = '';
        }else{
          this.form.name = ps[0].name;
        }
      }
    },
    getUseablePlan: function(){
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLightPlan`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'timeTables', res.data);
          // if(callback && `${typeof callback}` == 'function')callback();
        }
      });
    },
    queryPoles: function(callback){
      if(this.form.groupId <= 0){
        this.$set(this, 'poles', []);
        if(callback && `${typeof callback}` == 'function')callback();
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/light/QueryGroupLampPole`, {groupId: this.form.groupId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'poles', res.data);
          if(callback && `${typeof callback}` == 'function')callback();
        }
      });
    },
    groupChanged: function(callback){
        // console.log('modal level edit mounted', this.form.groupId)
      this.queryPoles(callback);
      // if(this.form.groupId <= 0){
      //   this.$set(this, 'poles', []);
      //   this.form.content.pole = 0;
      // }else{
      // }
    },
    ok: async function () {
      if(this.form.groupId <= 0){
        this.$Message.warning('不能选择根分区');
        return;
      }
      if(this.form.content.area <= 0){
        this.$Message.warning('请选择所属分组');
        return;
      }
      if(this.form.name.trim().length == 0){
        this.$Message.warning('请输入灯控名称');
        return;
      }
      // if(this.form.deviceId <= 0){
      //   this.$Message.warning('请选择关联设备');
      //   return;
      // }
      if(this.form.content.stid <= 0){
        this.$Message.warning('请选择单灯控制器类型');
        return;
      }
      if(this.form.code.trim().length == 0){
        this.$Message.warning('请输入灯控通信ID');
        return;
      }
      if(this.form.content.timeId1 <= 0){
        this.$Message.warning('请选择计时日表');
        return;
      }
      if(this.form.content.timeId2 <= 0){
        this.$Message.warning('请选择准时日表');
        return;
      }
      if(this.form.content.tr < 30 || this.form.tr > 200){
        this.$Message.warning('温度阈值必须在 30 - 200 之间');
        return;
      }
      if(this.form.content.ur < 0 || this.form.ur > 500){
        this.$Message.warning('额定电压必须在 0 - 500 之间');
        return;
      }
      if(this.form.content.ll <= 0){
        this.$Message.warning('一级漏电阈值必须大于0');
        return;
      }
      if(this.form.content.lh < this.form.content.ll){
        this.$Message.warning('二级漏电阈值必须大于一级漏电阈值');
        return;
      }
      if(this.form.content.lx < this.form.content.lh){
        this.$Message.warning('三级漏电阈值必须大于二级漏电阈值');
        return;
      }
      
      this.$axios.post(`//${this.domains.trans}/station/light/SaveLight`, this.form).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved', {isAdd: this.isAdd, data: res.data});
        }
      })
    },
    cancel () {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>