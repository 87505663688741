<template>
  <div class="power-container">
    <div class="power-areas">
      <CommonTree />
    </div>
    <div class="power-content">
      <div class="switch-button-list">
        <template v-for="(el,index) in meuns">
          <a href="javascript:0" :class="['power-button-item',currentMenu == el.code ? 'active' : '']" :key="index" v-if="funCodes(...el.codes)" @click="selectMenu(el)"><img :src="[el.isHover?el.hover:el.img]" alt="">{{el.name}}</a>
        </template>
      </div>
      <div class="area-content">
        <template v-for="(item, idx) in meuns">
          <component v-if="item.code == currentMenu" :key="idx" v-bind:is="item.com" :args="item.args"></component>
        </template>
        <!-- 调光 -->
        <!-- <ModelMonitorItem v-if="currentMenu == 's2'" /> -->
        <!-- 单灯 -->
        <!-- <ModelLightItem v-if="currentMenu == 's3'" /> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import CommonTree from '@/views/common/tree/Index'
import CommonTree from '../tree/Index'
import ModelLightItem from './LightItem'
// import ModelLightList from './LightList'
// import patternPage from './pattern'
import ModelMonitorItem from './MonitorItem'
import Gateway from './Gateway'
// import ModelMonitorItemC228 from './MonitorItemC228'
export default {
  name: 'FactoryAdjustIndex',
  components: {
    CommonTree,
    // ModelLightItem,
    // Gateway,
    // ModelMonitorItemC228,
    // ModelPowerItem,
    // patternPage,
  },
  data() {
    return {
      currentMenu: 's2',
      currentLux: 0,
      areas: [],
      // adjusts: [],
      // lights: [],
      currentAdjustParam: null,
      currentAdjustType: null,
      dataLoaded: 0,
      dataIndex: 0,
      meuns: [
        // { code: 's1', name: '情景控制', com: "", isHover: true, args: {}, codes: ['lmv', 'lma', 'lme', 'lmd'], img: this.img.light.carat.lamp.scene, hover: this.img.light.carat.lamp.sceneActive },
        { code: 's2', name: '智能调光', com: ModelMonitorItem, isHover: true, args: {type: 'common'}, codes: ['aov1', 'aoc1'], img: this.img.light.carat.lamp.dimming, hover: this.img.light.carat.lamp.dimmingActive },
        { code: 's3', name: '单灯控制', com: ModelLightItem, isHover: false, args: {type: 'common'}, codes: ['lov', 'loc'], img: this.img.light.carat.lamp.real, hover: this.img.light.carat.lamp.realActive },
        { code: 's4', name: '网关控制', com: Gateway, isHover: false, args: {type: 'common'}, codes: ['gov', 'goc'], img: this.img.light.carat.lamp.linkage, hover: this.img.light.carat.lamp.linkageActive },
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('cmd', ['cmdResultRefresh', 'cmds', 'luxDataRefreshed', 'luxes']),
  },
  watch: {
    $route: {
      handler: function (route) {
        if (route.query.id) {
          setTimeout(() => {
            this.$store.commit('group/selectNodeById', { nodeId: route.query.id, type: this.productCodes.station })
          }, 200)
        }
      },
      immediate: true
    },
    luxDataRefreshed() {
      if (this.currentAdjustParam == null) {
        this.currentLux = 0;
        return;
      }
      let lux = this.luxes[this.currentAdjustParam.id];
      if (!lux) {
        this.currentLux = 0;
        return;
      }
      this.currentLux = lux.val;
      return;
    },
    selectedNode() {
      // this.initAdjustAndAreas();
    }
  },
  mounted: function () {
    this.$store.dispatch('group/getGroupAndStations', -1)
    // this.initAdjustAndAreas();
  },
  methods: {
    selectMenu: function (menu) {
      if (this.currentMenu == menu.code) { return false }
      this.currentMenu = menu.code;
      this.meuns.forEach(el => {
        el.isHover = false;
      });
      menu.isHover = true;
    },
  }
}
</script>
<style scoped>
.switch-button-list {
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: initial;
}
.power-button-item {
  cursor: pointer;
  height: 44px;
  text-align: center;
  display: flex;
  flex-direction: initial;
  align-items: center;
  cursor: pointer;
  text-align: center;
  margin-right: 60px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #9eb1c8;
}
.power-button-item.active {
  color: #fff;
  border-bottom: 2px solid #fff;
}
.power-button-item img {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 8px;
}
.area-content {
  height: 100px;
  flex: auto;
  overflow: auto;
}
.area-content::-webkit-scrollbar {
  display: none;
}
.power-container {
  /* border: solid 1px red; */
  width: 100%;
  display: flex;
  background-color: #011220;
}
.power-areas {
  /* border: solid 1px red; */
  width: 300px;
  margin: 3px;
  flex: none;
  background-color: #001f37;
  display: flex;
  flex-direction: column;
}
.power-content {
  margin: 3px;
  padding: 30px;
  width: 0;
  flex: auto;
  display: flex;
  flex-direction: column;
  background: #0f2137;
}
.power-title {
  padding: 0 10px 10px;
  height: 100px;
  flex: none;
  text-align: center;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #51e7f3;
  position: relative;
}
.power-title-center {
  padding-top: 8px;
  /* border: solid 1px red; */
  width: 400px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.power-title-center img {
  position: absolute;
  bottom: 20px;
  left: 0;
  /* left: ; */
}
.bright-env {
  /* border: solid 1px red; */
  position: absolute;
  top: 20px;
  right: 60px;
  /* width: 200px; */
  height: 30px;
  display: flex;
}
.bright-env-item {
  display: flex;
  align-items: center;
}
.bright-env-item img {
  margin: 0 5px;
}
.env-item-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.env-item-value {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #51e7f3;
}
</style>
