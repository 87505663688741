<template>
  <div class="power-container">
    <div class="power-areas">
      <CommonTree  />
    </div>
    <div class="power-content">
      <!-- <MenuCommon v-if="args.type == 'common'" class="light-control-menu" @cmd="cmdGot" /> -->
      <ListCommon ref="lightList1" class="light-control-list" :type="args.type" />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import CommonTree from '@/views/common/tree/Index'
import CommonTree from '../tree/Index'
// import MenuCommon from '@/components/control/light/menus/Common'
import ListCommon from '@/components/control/light/list/newCommon'
export default {
  name: 'FactoryAdjustIndex',
  components: {
    CommonTree,
    // MenuCommon,
    ListCommon,
  },
  props: {
    args: {
      type: Object,
      default() {
        return {
          type: 'common',
        }
      }
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('cmd', ['cmdResultRefresh', 'cmds', 'commands', 'luxDataRefreshed', 'luxes', 'windowOnResize', 'clearOldLogs']),
    currentStation: function () {
      if (this.selectedNode.type == this.productCodes.station) {
        return this.selectedNode;
      } else if (this.selectedDevices.length > 0) {
        return this.selectedDevices[0];
      }
      return null;
    },
  },
  watch: {
  },
  mounted: function () {
    this.$store.dispatch('group/getGroupAndStations', -1)
  },
  methods: {
    cmdGot: function (params) {
      this.$refs.lightList1.dealCmd(params);
    },
  }
}
</script>
<style scoped>
.switch-button-list {
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: initial;
}
.power-button-item {
  cursor: pointer;
  height: 44px;
  text-align: center;
  display: flex;
  flex-direction: initial;
  align-items: center;
  cursor: pointer;
  text-align: center;
  margin-right: 60px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #9eb1c8;
}
.power-button-item.active {
  color: #fff;
  border-bottom: 2px solid #fff;
}
.power-button-item img {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 8px;
}
.area-content {
  height: 100px;
  flex: auto;
  overflow: auto;
}
.area-content::-webkit-scrollbar {
  display: none;
}
.power-container {
  /* border: solid 1px red; */
  width: 100%;
  display: flex;
  background-color: #011220;
}
.power-areas {
  /* border: solid 1px red; */
  width: 300px;
  margin: 3px;
  flex: none;
  background-color: #001f37;
  display: flex;
  flex-direction: column;
}
.power-content {
  margin: 3px;
  padding: 30px;
  width: 0;
  flex: auto;
  display: flex;
  flex-direction: column;
  background: #0f2137;
}
.power-title {
  padding: 0 10px 10px;
  height: 100px;
  flex: none;
  text-align: center;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #51e7f3;
  position: relative;
}
.power-title-center {
  padding-top: 8px;
  /* border: solid 1px red; */
  width: 400px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.power-title-center img {
  position: absolute;
  bottom: 20px;
  left: 0;
  /* left: ; */
}
.bright-env {
  /* border: solid 1px red; */
  position: absolute;
  top: 20px;
  right: 60px;
  /* width: 200px; */
  height: 30px;
  display: flex;
}
.bright-env-item {
  display: flex;
  align-items: center;
}
.bright-env-item img {
  margin: 0 5px;
}
.env-item-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.env-item-value {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #51e7f3;
}
.light-control-menu {
  height: 50px;
  flex: none;
}
.light-control-list {
  height: calc(100% - 50px);
  flex: auto;
}
</style>
