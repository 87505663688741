<template>
  <div class="monitor-content">
    <div class="a-line">
      <div class="control-title">
        <span>管理区域</span>
      </div>
      <div class="control-list">
        <div :class="['control-area-box',selectedAreaIds.indexOf(area.id) != -1 ? 'selected' : '',]" v-for="(area, idx) in areas" :key="idx" @click="selectArea(area)">
          <div class="box-top">
            <div class="box-choice">
              <div class="box-checkbox " :class="[selectedAreaIds.indexOf(area.id) != -1 ? 'box-selected' : '',]"></div>
              <div class="box-choice-name">{{ area.name }}</div>
            </div>
            <div class="box-name">ID：{{ area.code }}</div>
            <img class="area-title-icon" :src="img.light.factory.monitor.adjust.areaImg" />
          </div>
          <div class="box-echart-title">
            <span class="box-echart-name">亮度</span>
            <span class="box-echart-value">{{ area.bright }}%</span>
          </div>
          <div class="box-echart-icon">
            <img :src="img.light.carat.lamp.brightness" alt="">
            <div class="echart-box">
              <div class="echart-cover" :style="{width:`${countWidth(area)}` }"></div>
              <div class="echart-spot" :style="{left:`${area.bright}%` }"></div>
            </div>
          </div>
          <div class="box-echart-title">
            <span class="box-echart-name">色温</span>
            <span class="box-echart-value">{{ area.color }}%</span>
          </div>
          <div class="box-echart-icon">
            <img :src="img.light.carat.lamp.colorTemp" alt="">
            <div class="echart-box echart-color">
              <div class="echart-spot" :style="{left:`${area.color}%` }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="a-line">
      <div class="control-title">
        <span>模式调光</span>
      </div>
      <div class="adjust-list" v-if="funCodes('dco')">
        <div v-for="(scene, idx) in scenes" :key="idx" :class="['adjust-area-item', scene.id == selectedLevel ? 'selected' : '',]" @click="selectLevel(scene)">
          <img :src="adjustBrightImg(scene.bright)" style="margin: 0 15px 0 0px" />
          <div style="display: flex; flex-direction: column">
            <div class="area-item-name">{{ scene.name }}</div>
            <div class="area-item-arg">
              亮度：<i class="blue">{{ scene.bright }}%</i>
            </div>
          </div>
        </div>
        <div class="adjust-area-dimming">
          <span class="adjust-dimming-title">自由调光</span>
          <div class="adjust-title-small">亮度:<span>{{bright}}%</span></div>
          <div class="adjust-dimming-box">
            <Slider v-model="bright" class="adjust-slider"></Slider>
            <div v-if="funCodes('dco')" class="adjust-button" @click="freeAdjust">发送</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "FactoryMonitorItem",
  components: {
  },
  props: {
    adjust: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      areas: [],
      lights: [],
      selectedAreaIds: [1],
      adjustAreaIds: [], //存储已更新过的区域ID
      selectedLevel: 1,
      showArgModal: false,
      cmdItem: {},
      aa: {},
      hoverButtonName: "",
      bright: 0, //自由调光亮度值
      showChannelControlModal: false,
      isShowMore: false,
      scenes: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('cmd', ['cmdResultRefresh', "commands"]),
    currentStation: function () {
      if (this.selectedDevices.length > 0) return this.selectedDevices[0];
      return null;
    },
    adjustBrightImg: function () {
      return function (bright) {
        if (bright == 0)
          return this.img.light.carat.lamp.lamp0;
        else if (bright <= 20)
          return this.img.light.carat.lamp.lamp20;
        else if (bright <= 40)
          return this.img.light.carat.lamp.lamp40;
        else if (bright == 50)
          return this.img.light.carat.lamp.lamp50;
        else if (bright <= 60)
          return this.img.light.carat.lamp.lamp60;
        else if (bright <= 80)
          return this.img.light.carat.lamp.lamp80;
        else if (bright <= 90)
          return this.img.light.carat.lamp.lamp90;
        else return this.img.light.carat.lamp.lamp100;
      };
    },
  },
  watch: {
    selectedNode: function(){
      this.getAreas();
    },
  },
  mounted: function () {
    this.selectedAreaIds = [];
    this.getAreas();
    this.getScenes();
  },
  methods: {
    getAreas: function () {
      this.$axios.post(`//${this.domains.trans}/station/light/QueryGroupAreas`, { groupId: this.selectedNode.id }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'areas', res.data);
        }
      });
    },
    countWidth(area) {
      let width = "1px";
      if (area.bright) {
        width = area.bright * 1.41 + "px"
      }
      return width;
    },
    getScenes: function () {
      this.$axios.post(`//${this.domains.trans}/station/config/QueryScene`, { }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'scenes', res.data);
        }
      });
    },
    freeAdjust: function () {
      if (this.selectedAreaIds.length == 0) {
        this.$Message.warning("请选择要调光的区域");
        return;
      }
      let that = this;
      this.$Modal.confirm({
        title: "提示",
        content: `确定要发送调光指令吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/light/FreeAdjustArea`, { groupId: this.selectedNode.id, areaIds: this.selectedAreaIds, bright: this.bright }).then(res => {
            if (res.code !== 0) {
              return;
            }
            let result = [];
            for(let item of res.data.cmds.list){
              result.push(item.cmdName);
            }
            setTimeout(() => {
              that.$Modal.success({
                width: '600',
                title: '指令已发送',
                content: result.join('<br />')
              });
            }, 500);
            // that.$Message.info({
            //   title: '指令',
            //   content: '指令已发送',
            // });
            for(let area of res.data.areas){
              let ass = this.areas.filter(p => p.id == area.id);
              if(ass.length > 0){
                this.$set(ass[0], 'bright', area.bright);
              }
            }
          })
        },
      });
    },
    selectLevel: function (scene) {
      // if (!this.adjust) {
      //   this.$Message.warning("未配置调光属性");
      //   return;
      // }
      if (this.selectedAreaIds.length == 0) {
        this.$Message.warning("请选择要调光的区域");
        return;
      }
      // console.log('scene result', scene);
      let that = this;
      this.$Modal.confirm({
        title: "提示",
        content: `确定要发送调光指令吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/light/FreeAdjustArea`, { groupId: this.selectedNode.id, areaIds: this.selectedAreaIds, bright: scene.bright }).then(res => {
            if (res.code !== 0) {
              return;
            }
            let result = [];
            for(let item of res.data.cmds.list){
              result.push(item.cmdName);
            }
            setTimeout(() => {
              that.$Modal.success({
                width: '600',
                title: '指令已发送',
                content: result.join('<br />')
              });
            }, 500);
            // that.$Message.info({
            //   title: '指令',
            //   content: '指令已发送',
            // });
            for(let area of res.data.areas){
              let ass = this.areas.filter(p => p.id == area.id);
              if(ass.length > 0){
                this.$set(ass[0], 'bright', area.bright);
              }
            }
          })
        },
      });
    },
    selectArea: function (area) {
      let index = this.selectedAreaIds.indexOf(area.id);
      if (index == -1) {
        this.selectedAreaIds.push(area.id);
      } else {
        this.selectedAreaIds.splice(index, 1);
      }
    },
  },
};
</script>
<style scoped>
.a-line {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  background: #142b49;
  margin-top: 30px;
}
.control-title {
  margin-bottom: 15px;
}
.control-title span {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.control-area-box {
  width: 213px;
  height: 205px;
  background: #0f2137;
  border-radius: 10px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 20px;
  position: relative;
  overflow: hidden;
  margin-right: 30px;
}
.control-area-box.selected {
  border: 1px solid #2b89fd;
  box-shadow: 0px 0px 12px 0px rgba(73, 187, 252, 0.5);
}
.box-choice {
  display: flex;
  flex-direction: initial;
}
.box-choice-name {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  line-height: 16px;
  margin-left: 10px;
}
.box-checkbox {
  width: 15px;
  height: 15px;
  border: 1px solid #49bbfc;
  line-height: 26px;
  position: relative;
  cursor: pointer;
}
.box-selected::before {
  position: absolute;
  content: " ";
  width: 5px;
  height: 10px;
  transform: rotate(45deg);
  border-right: 2px solid #49bbfc;
  border-bottom: 2px solid #49bbfc;
  top: 0px;
  left: 4px;
}
.box-top {
  display: flex;
  flex-direction: column;
}
.box-top .area-title-icon {
  width: 42px;
  position: absolute;
  right: 20px;
  top: -13px;
}
.box-name {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  margin-top: 5px;
}
.box-echart-title {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  margin-top: 16px;
}
.box-echart-name {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
}
.box-echart-value {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.box-echart-icon {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
}
.echart-box {
  width: 141px;
  height: 6px;
  background: #194873;
  border-radius: 3px;
  position: relative;
  margin-top: 8px;
}
.echart-box.echart-color {
  background: linear-gradient(90deg, #35b4fc, #ffe954);
}
.echart-spot {
  width: 12px;
  height: 12px;
  background: #35b4fc;
  border: 2px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  margin-left: -6px;
}
.echart-cover {
  /* width: 79px; */
  height: 6px;
  background: #35b4fc;
  border-radius: 3px;
}
.control-list {
  display: flex;
  flex-direction: initial;
  justify-content:flex-start;
}
.adjust-list {
  display: flex;
  flex-direction: initial;
}
.adjust-area-item {
  width: 180px;
  height: 86px;
  background: #0f2137;
  border-radius: 10px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}
.area-item-name {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
}
.area-item-arg {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
}
.area-item-arg .blue {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #49e1fc;
  font-style: inherit;
}
.adjust-area-dimming {
  width: 237px;
  height: 86px;
  background: #0f2137;
  border-radius: 10px;
  position: relative;
}
.adjust-dimming-title {
  position: absolute;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  top: -40px;
  left: 0;
}
.adjust-slider {
  width: 120px;
  margin: 0 10px;
}
.adjust-button {
  width: 46px;
  height: 26px;
  background: #49e1fc;
  border-radius: 6px;

  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #102641;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
}
.adjust-title-small {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
  padding-left: 20px;
  margin-top: 14px;
}
.adjust-title-small span {
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  margin-left: 4px;
}
.adjust-dimming-box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.right-content {
  display: flex;
  flex-direction: column;
}
.power-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.power-button-item {
  width: 148px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  flex-direction: column;
}
.power-button-imgBox {
  width: 54px;
  height: 54px;
  background: #0f2137;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
/* .power-button-imgBox:hover {
  box-shadow: 0px 0px 10px 0px #00c1ff inset;
} */
.power-button-item img {
  width: 28px;
  height: 28px;
}
</style>
