<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">设备固件信息</div>
    <div class="user-edit-content">
      <div class="query-data-area">
        固件名称：<Input v-model="filter.name" placeholder="请输入固件名称" clearable style="width: 300px; margin-right: 10px;"></Input>
      </div>
      <div class="query-data-area mt20">
        固件版本：<Input v-model="filter.version" placeholder="请输入固件版本" clearable style="width: 300px; margin-right: 10px;"></Input>
      </div>
      <div class="query-data-area mt20">
        固件说明：<Input v-model="filter.remark" placeholder="请输入固件说明" clearable style="width: 300px; margin-right: 10px;"></Input>
      </div>

      <div class="query-data-area mt20">
        设备小类：
        <Select v-model="filter.deviceTypes" multiple style="width:350px">
          <Option v-for="item in smallTypes" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </div>
      <div class="query-data-area mt20" style="display: flex;">
        升级文件：
        <Button style="margin-right: 8px" type="primary" @click="fileSave">{{this.filter.fid==''?'点击上传':'更换文件'}}</Button>
        {{filesName}}
        <input class="file-control" ref="file" type="file" @change="fileChanged" />
      </div>
      <Spin fix v-if="showSpin">
        <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
        <div>上传文件中，请稍等。。。</div>
      </Spin>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
      <Button style="margin-right: 8px" type="primary" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalUserEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    smallTypes: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      showModal: this.value,
      editItem: {},
      filesName: '',
      showSpin: false,
      filter: {
        "name": "",
        "version": "",
        "remark": "",
        "fid": "",
        "bigTypeId": 1,
        "deviceTypes": []
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('auth', ['appType', 'isNewMode', 'customerId']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      this.$refs.file.value = '';
      this.filesName = '';
      if (!this.showModal) return;
      this.filter.bigTypeId = this.item.bigTypeId;
      if (this.item.isAdd) {
        this.filter.id = this.item.data.id;
        this.filter.name = this.item.data.name;
        this.filter.version = this.item.data.version;
        this.filter.remark = this.item.data.remark;
        this.filter.fid = this.item.data.fid;
        this.filter.deviceTypes = this.item.data.deviceTypeIds;
      } else {
        this.filter.id = 0;
        this.filter.name = "";
        this.filter.version = "";
        this.filter.remark = "";
        this.filter.fid = "";
        this.filter.deviceTypes = "";
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  methods: {
    fileSave() {
      this.$refs.file.click();
    },
    fileChanged: function (e) {
      this.showSpin = true;
      this.filesName = e.target.files[0].name
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.$axios.request({
        url: `sys/setting/FileSave`,
        method: 'post',
        data: formData,
        responseType: 'json',
        myType: 'file'
      }).then(res => {
        if (res.code == 0) {
          this.filter.fid = res.data;
          this.showSpin = false;
        }
      });
    },
    ok() {
      if (this.filter.name == '') {
        this.$Message.warning('请输入固件名称');
        return false
      }
      if (this.filter.deviceTypes.length == 0) {
        this.$Message.warning('请选择设备小类');
        return false
      }
      if (this.filter.fid == '') {
        this.$Message.warning('请选择升级文件');
        return false
      }
      this.$axios.post(`site/debug/SaveSoftFile`, this.filter).then(res => {
        if (res.code == 0) {
          this.showModal = false;
          this.$emit('saved');
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.user-edit-content {
  display: flex;
  flex-direction: column;
}
.file-control {
  display: none;
}
</style>