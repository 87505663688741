<template>
  <div class="energy-box">
    <div class="energy-horn-left"></div>
    <div class="energy-horn-right"></div>
    <div class="energy-con">
      <div class="energy-horn-spot spot-dark spot-top-left"></div>
      <div class="energy-horn-spot spot-dark spot-top-right"></div>
      <div class="energy-horn-spot spot-lower-left"></div>
      <div class="energy-horn-spot spot-lower-right"></div>
      <div class="energy-top">
        <div>
          <img :src="img.light.carat.home.energy" alt="" class="energy-top-icon">
          <div class="energy-title">能耗分析(单位:KWh)</div>
        </div>
        <img :src="img.light.carat.home.arrow" alt="">
      </div>
      <div class="energy-list">
        <div class="energy-tips-title">
          <div class="energy-tips"><i class="energyColor1"></i>当日能耗(KWh)</div>
          <div class="energy-tips"><i class="energyColor2"></i>节电率(%)</div>
          <div class="energy-tips"><i class="energyColor3"></i>平均额定功率(KW)</div>
          <div class="energy-tips"><i class="energyColor4"></i>当日费用(元)</div>
          <div class="energy-tips"><i class="energyColor5"></i>节省电费(元)</div>
        </div>
        <div class="energy-box-charts">
          <div class="energy-charts">
            <chartsLine :option="energyLineData" id="energyLineData" :key="timer"></chartsLine>
          </div>
          <div class="energy-data">
            <table class="energy-data-tab" border="1" cellspacing="0">
              <tr>
                <td style="width:75px">今日能耗</td>
                <td>{{dataList.today}}</td>
              </tr>
              <tr>
                <td>昨日能耗</td>
                <td>{{dataList.yestoday}}</td>
              </tr>
              <tr>
                <td>本周能耗</td>
                <td>{{dataList.week}}</td>
              </tr>
              <tr>
                <td>本月能耗</td>
                <td>{{dataList.month}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'energyBox',
  components: {
  },
  data() {
    return {
      energyLineData: {
        color: ["#28CA93", "#5C85FC", "#34AFFD", "#64F1F8", "#49B0FD"],
        title: "",
        xColor: "#B5C1CC",
        yColor: "#99A8B6",
        yBorderColor:'rgba(34, 51, 83, 0.45)',
        x: [],
        unit: "",
        legend: {
          data: ['Email'],
          left: "right",
        },
        series: [
          {
            name: "当日能耗(KWh)",
            data: [],
          },
          {
            name: "节电率(%)",
            data: [],
          },
          {
            name: "平均额定功率(KW)",
            data: [],
          },
          {
            name: "当日费用(元)",
            data: [],
          },
          {
            name: "节省电费(元)",
            data: [],
          },
        ],
        grid: {
          top: "5%", // 等价于 y: '16%'
          left: "40",
          right: "40",
          bottom: "3%",
          containLabel: true,
        },
        setSeries: {
          smooth: true, //平滑折线图
        },
      },
      dataList: {
        "today": 0,
        "yestoday": 0,
        "week": 0,
        "month": 0
      },
      timer: 1,
    }
  },
  computed: {
    ...mapState('auth', ['config']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
  },
  watch: {
    windowOnResize() {
      this.timer++;
    },
  },
  created: function () {
  },
  mounted: function () {
    this.energy();
    this.energySimple();
  },
  methods: {
    energySimple() {
      this.$axios.post(`//${this.domains.trans}/station/light/EnergySimple`, { "groupId": 0 }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'dataList', res.data);
        }
      });
    },
    energy: async function () {
      let ajaxData = {
        "groupId": 0,
        "type": "date",
        "start": "2023-02-20",
        "end": "2023-02-24"
      }
      let now = new Date();
      ajaxData.end = now.format('yyyy-MM-dd HH:mm:ss');
      now.setDate(now.getDate() - 7);
      ajaxData.start = now.format('yyyy-MM-dd HH:mm:ss');
      let res = await this.$axios.post(`//${this.domains.trans}/station/light/EnergyCharge`, ajaxData);
      if (res.code !== 0) {
        return;
      }
      this.energyLineData.x = [];

      res.data.list.forEach(el => {
        el.val = (el.val * 1).toFixed(2);
        this.energyLineData.x.push(el.date);
        this.energyLineData.series[0].data.push(this.img.val(el.energy, 2))
        this.energyLineData.series[1].data.push(this.img.val(el.rate * 100, 2))
        this.energyLineData.series[2].data.push(this.img.val(el.pr, 2))
        this.energyLineData.series[3].data.push(this.img.val(el.money, 2))
        this.energyLineData.series[4].data.push(this.img.val(el.save, 2))
      });
      this.timer++;
    },
  },
}
</script>
<style scoped>
.energy-box {
  /* width: 959px; */
  width: calc(100%);
  height: 287px;
  background: rgba(3, 16, 33, 0.9);
  border: 1px solid #2f3c48;
  position: relative;
  padding: 7px 4px;
}
.energy-horn-left {
  width: 8px;
  height: 8px;
  border-top: 2px solid #49e1fc;
  border-left: 2px solid #49e1fc;
  position: absolute;
  top: -2px;
  left: -2px;
}
.energy-horn-right {
  width: 8px;
  height: 8px;
  border-top: 2px solid #49e1fc;
  border-right: 2px solid #49e1fc;
  position: absolute;
  top: -2px;
  right: -2px;
}
.energy-horn-spot {
  width: 3px;
  height: 3px;
  background: #777b82;
  position: absolute;
}
.spot-dark {
  background: #49e1fc;
}
.spot-top-left {
  top: 6px;
  left: 4px;
}
.spot-top-right {
  top: 6px;
  right: 4px;
}
.spot-lower-left {
  bottom: 4px;
  left: 4px;
}
.spot-lower-right {
  bottom: 4px;
  right: 4px;
}
.energy-top {
  display: flex;
  flex-direction: initial;
  width: 100%;
  height: 35px;
  background: rgba(36, 48, 66, 0.8);
  border: 1px solid #445565;
  line-height: 35px;
  padding: 0 16px;
  justify-content: space-between;
}
.energy-top > div {
  display: flex;
  flex-direction: initial;
}
.energy-top img {
  width: 23px;
  height: 16px;
  margin-top: 10px;
}
.energy-top .energy-top-icon {
  width: 18px;
  height: 20px;
  margin-top: 5px;
}
.energy-top .energy-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #baf1fe;
  margin-left: 11px;
}
.energy-list {
  width: 100%;
  height: 237px;
  position: relative;
}
.energy-tips-title {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.energy-tips {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
}
.energy-tips i {
  display: inline-block;
  width: 12px;
  height: 4px;
  vertical-align: middle;
  margin: 0 5px 0 10px;
}
.energy-tips i.energyColor1 {
  background: #02cb86;
}
.energy-tips i.energyColor2 {
  background: #8868fe;
}
.energy-tips i.energyColor3 {
  background: #2e81fd;
}
.energy-tips i.energyColor4 {
  background: #68ffe8;
}
.energy-tips i.energyColor5 {
  background: #2cbcff;
}
.energy-box-charts {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.energy-charts {
  width: calc(100% - 210px);
  height: 225px;
}
.energy-data {
  width: 190px;
  height: 150px;
  display: flex;
  margin-bottom: 25px;
}
.energy-data-tab {
  width: 100%;
  height: 100%;
  border-color: #223353;
}
.energy-data-tab tr td {
  height: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
  line-height: 20px;
  padding: 0 0 0 10px;
  border-color: #223353;
}
</style>