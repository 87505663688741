<template>
  <div class="factory-container">
    <div class="factory-header">
      <div class="layout-header-logo">
        <p class="layout-header-cn">智慧路灯照明应用管理平台</p>
        <p class="layout-header-en">INTELLIGENT STREET LIGHTING APPLICATION MANAGEMENT PLATFORM</p>
      </div>
      <div class="layout-header-content">
        <div class="header-content-area">
          <div class="header-button-area">
            <div :class="['header-button-item',currentItem=='home'?'header-button-active':'']" :style="{backgroundImage: `url(${currentItem == 'home' ? img.light.carat.navTop.checkBg : ''})`}" @click="switchMenu('home')">首页</div>
            <div :class="['header-button-item',currentItem=='spread'?'header-button-active':'']" :style="{backgroundImage: `url(${currentItem == 'spread' ? img.light.carat.navTop.checkBg : ''})`}" @click="switchMenu('spread')">设备状态</div>
            <div :class="['header-button-item',currentItem=='adjust'?'header-button-active':'']" :style="{backgroundImage: `url(${currentItem == 'adjust' ? img.light.carat.navTop.checkBg : ''})`}" @click="switchMenu('adjust')">智能控制</div>
            <div :class="['header-button-item',currentItem=='analysis'?'header-button-active':'']" :style="{backgroundImage: `url(${currentItem == 'analysis' ? img.light.carat.navTop.checkBg : ''})`}" @click="switchMenu('analysis')">数据分析</div>
            <div :class="['header-button-item',currentItem=='config'?'header-button-active':'']" :style="{backgroundImage: `url(${currentItem == 'config' ? img.light.carat.navTop.checkBg : ''})`}" @click="switchMenu('config')">配置管理</div>
            <div :class="['header-button-item',currentItem=='system'?'header-button-active':'']" :style="{backgroundImage: `url(${currentItem == 'system' ? img.light.carat.navTop.checkBg : ''})`}" @click="switchMenu('system')">系统管理</div>
          </div>
          <div class="header-time-area">
            <div>{{date}}</div>
            <div>{{weeks[week]}} {{time}}</div>
          </div>
          <div class="header-user-info">
            <img style="width: 30px; height: 30px; margin-right: 3px" :src="img.headerUserIcon" />
            <HeaderDropDown />
          </div>
        </div>
        <div class="header-content-line">
          <div class="header-content-block"></div>
          <div class="header-content-block-lg"></div>
          <div class="header-content-block"></div>
        </div>
      </div>
    </div>
    <div class="factory-content">
      <component :is="url"></component>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import HeaderDropDown from '@/views/common/menu/HeaderDropDown'
import home from './Home'
import layer from './spread/Index'//组态
import gis from './map/Index'//地图
import spread from './monitr/Index'//单灯列表
import adjust from './adjust/Index'
import config from './config/Index'
import analysis from './analysis/Index'
// import analysis from '../../road/analyse/Index'
import system from '../../../sys/Index.vue'

export default {
  name: 'Home',
  components: {
    HeaderDropDown,
  },
  data() {
    return {
      date: '',
      time: '',
      week: '',
      currentItem: 'home',
      url: home,
      urls: {
        home: home,
        spread: spread,
        adjust: adjust,
        config: config,
        analysis: analysis,
        system: system,
        layer: layer,
        gis: gis,
      },
      timeout: null,
    }
  },
  watch: {
    agentChangeValue() {
      this.debounceInitData();
    },
  },
  computed: {
    ...mapGetters('auth', ['custAndType']),
    ...mapState('common', ['weeks']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'agentChangeValue']),
  },
  created: function () {
  },
  mounted: function () {
    this.setDateTime();
  },
  methods: {
    debounceInitData: function () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.initData, 500);
    },
    initData: function () {
      if (this.customerId == 0) return;
      this.$store.dispatch('group/getDevPoses');
      this.$store.dispatch('group/getGroupImages');
    },
    setDateTime: function () {
      let now = new Date();
      this.date = now.format('yyyy年MM月dd日');
      this.time = now.format('HH:mm:ss');
      this.week = now.getDay();
      setTimeout(this.setDateTime, 1000);
    },
    switchMenu: function (menu) {
      if (this.currentItem == menu) return;
      this.currentItem = menu;

      if (menu == "spread") {
        switch (this.config.deviceStatusStyle) {
          case 1:
            this.url = this.urls['gis'];
            break;
          case 2:
            this.url = this.urls['spread'];
            break;
          case 3:
            this.url = this.urls['layer'];
            break;
          default:
            this.url = this.urls['spread'];
            break;
        }
      } else {
        this.url = this.urls[menu];
      }

    },
  },
}
</script>
<style scoped>
.factory-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.factory-header {
  height: 92px;
  flex: none;
  display: flex;
  padding: 0;
  background-color: #011220;
}
.factory-content {
  height: 100%;
  flex: auto;
}
.factory-content div {
  height: 100%;
}
.layout-header-side {
  width: 650px;
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}
.header-line-area {
  height: 43px;
  flex: none;
  text-align: right;
}
.header-time-area {
  width: 167px;
  flex: none;
  display: flex;
  padding: 10px 30px 5px 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2d8cf0;
}
.header-button-area {
  width: 0;
  flex: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.layout-header-logo {
  width: 370px;
  flex: none;
  margin-left: 30px;
}
.layout-header-cn {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-top: 10px;
}
.layout-header-en {
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  transform: scale(0.83);
  transform-origin: 0 0;
  white-space: nowrap;
}
.layout-header-content {
  width: 100px;
  flex: auto;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 20px;
}
.header-content-area {
  height: 0;
  flex: auto;
  display: flex;
  justify-content: flex-start;
}

.header-user-info {
  /* border: solid 1px red; */
  width: 150px;
  flex: none;
  /* margin: auto 9px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-button-item {
  width: 107px;
  height: 43px;
  text-align: center;
  line-height: 43px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin: auto 32px;
  color: #d1fcfd;
  cursor: pointer;
}
.header-button-active {
  color: #49e1fc;
}
.header-button-active::after {
  content: "";
  width: calc(100%);
  height: 1px;
  display: block;
  margin: 3px auto 0;
  border-bottom: 3px solid #49e1fc;
}
.header-content-line {
  height: 30px;
  flex: none;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 32px;
}
.header-content-block {
  width: 18px;
  height: 3px;
  background: #1667a0;
  margin-top: 7px;
}
.header-content-block-lg {
  width: calc(100% - 58px);
  height: 3px;
  background: #052036;
  margin-top: 7px;
}
</style>