<template>
  <div class="device-box">
    <div class="device-horn-left"></div>
    <div class="device-horn-right"></div>
    <div class="device-con">
      <div class="device-horn-spot spot-dark spot-top-left"></div>
      <div class="device-horn-spot spot-dark spot-top-right"></div>
      <div class="device-horn-spot spot-lower-left"></div>
      <div class="device-horn-spot spot-lower-right"></div>
      <div class="device-top">
        <div>
          <img :src="img.light.carat.home.streetLamp" alt="" class="device-top-icon">
          <div class="device-title">路灯运行状态统计</div>
        </div>
        <img :src="img.light.carat.home.arrow" alt="">
      </div>
      <div class="device-list">
        <div class="device-info">
          <img :src="img.light.carat.home.lamppost" alt="" class="info-img">
          <div class="info-con">
            <div class="info-num">
              <span class="info-name">灯杆数</span>
              <span class="info-value">{{deviceData.pole.count}}</span>
            </div>
            <div class="info-num">
              <span class="info-name">亮灯率</span>
              <span class="info-value">{{deviceData.pole.rate>-1?deviceData.pole.rate:0}}%</span>
            </div>
          </div>
          <div class="info-chart">
            <chartsGauge :option="gaugeData" id="gaugeData1" :key="timer1"></chartsGauge>
            <div class="info-chart-val">{{deviceData.pole.on}}</div>
          </div>
        </div>
        <div class="device-line"></div>
        <div class="device-info">
          <img :src="img.light.carat.home.lamp" alt="" class="info-img">
          <div class="info-con">
            <div class="info-num">
              <span class="info-name">灯具数</span>
              <span class="info-value">{{deviceData.lamp.count}}</span>
            </div>
            <div class="info-num">
              <span class="info-name">亮灯率</span>
              <span class="info-value">{{deviceData.lamp.rate>-1?deviceData.lamp.rate:0}}%</span>
            </div>
          </div>
          <div class="info-chart">
            <chartsGauge :option="gaugeData1" id="gaugeData2" :key="timer1"></chartsGauge>
            <div class="info-chart-val">{{deviceData.lamp.on}}</div>
          </div>
        </div>
        <div class="charts-line">
          <chartsLine :option="chartsLineData" id="LightingRate" :key="timer"></chartsLine>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'deviceBox',
  components: {
  },
  data() {
    return {
      timer: 1,
      timer1: 0,
      gaugeData: {
        lineStyleColor: '#3F5E76',
        color: '#55A3FD',
        width: "7",
        setSeries: {
          progress: {
            show: true,
            overlap: false,
            roundCap: false,//圆角或 平角false
            clip: false,
          },
        },
        data: [{
          value: 68,
          detail: {
            show: false,
            valueAnimation: false,
            offsetCenter: ['0%', '0%']
          }
        }],

      },
      gaugeData1: {
        lineStyleColor: '#3F5E76',
        color: '#55A3FD',
        width: "7",
        setSeries: {
          progress: {
            show: true,
            overlap: false,
            roundCap: false,//圆角或 平角false
            clip: false,
          },
        },
        data: [{
          value: 68,
          detail: {
            show: false,
            valueAnimation: false,
            offsetCenter: ['0%', '0%']
          }
        }],

      },
      analyse: {
        "count": 1,
        "powerbox": {
          "total": 1,
          "online": 0,
          "alarm": 1,
          "running": 0
        },
        "light": {
          "total": 20,
          "online": 0,
          "alarm": 0,
          "running": 0
        },
        "claa": {
          "total": 0,
          "online": 0,
          "alarm": 0,
          "running": 0
        },
        "pole": {
          "total": 0,
          "online": 0,
          "alarm": 0,
          "running": 0
        },
        "lamp": {
          "total": 20,
          "online": 0,
          "alarm": 0,
          "running": 0
        }
      },
      chartsLineData: {
        color: ["#28DBFB", "#2E71FD"],
        title: "",
        x: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun5"],
        unit: "%",
        yBorderColor: 'rgba(34, 51, 83, 0.45)',
        series: [
          {
            name: "灯杆亮灯率",
            data: [150, 230, 224, 218, 135, 147, 222],
          }, {
            name: "灯具亮灯率",
            data: [12, 32, 224, 34, 234, 56, 435],
          },
        ],
        setSeries: {
          smooth: true, //平滑折线图
        },
      },
      deviceData: {
        "gate": {
          "online": 0,
          "count": 0,
          "rate": 0
        },
        "light": {
          "online": 0,
          "count": 0,
          "rate": 0
        },
        "pole": {
          "on": 0,
          "count": 0,
          "rate": 0
        },
        "lamp": {
          "on": 0,
          "count": 0,
          "rate": 0
        }
      }
    }
  },
  computed: {
    ...mapState('auth', ['config']),
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedNode']),
  },
  created: function () {
  },
  mounted: function () {
    this.initData();
    this.homeDevice();
  },
  methods: {
    initData: function () {
      let ajaxData = {
        "start": "",
        "end": "",
      }
      let now = new Date();
      ajaxData.end = now.format('yyyy-MM-dd HH:mm:ss');
      now.setDate(now.getDate() - 7);
      ajaxData.start = now.format('yyyy-MM-dd HH:mm:ss');
      this.$axios.post(`//${this.domains.trans}/station/light/LightOnRateHistory`, ajaxData).then(res => {
        if (res.code == 0) {
          this.chartsLineData.x = [];
          this.chartsLineData.series[0].data = []
          this.chartsLineData.series[1].data = []
          res.data.pole.forEach((el, index) => {
            this.chartsLineData.x.push(el.date);
            this.chartsLineData.series[0].data.push(el.rate);
            this.chartsLineData.series[1].data.push(res.data.lamp[index].rate);
            this.timer++;
          });
        }
      });
    },
    homeDevice() {
      this.$axios.post(`//${this.domains.trans}/station/light/HomeDevice`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, "deviceData", res.data);
          this.gaugeData.data[0].value = res.data.pole.rate
          this.gaugeData1.data[0].value = res.data.lamp.rate
          this.timer1++;
        }
      });
    }
  },
}
</script>
<style scoped>
.device-box {
  width: 420px;
  height: 100%;
  background: rgba(3, 16, 33, 0.9);
  border: 1px solid #2f3c48;
  position: relative;
  padding: 7px 4px;
}
.device-horn-left {
  width: 8px;
  height: 8px;
  border-top: 2px solid #49e1fc;
  border-left: 2px solid #49e1fc;
  position: absolute;
  top: -2px;
  left: -2px;
}
.device-horn-right {
  width: 8px;
  height: 8px;
  border-top: 2px solid #49e1fc;
  border-right: 2px solid #49e1fc;
  position: absolute;
  top: -2px;
  right: -2px;
}
.device-horn-spot {
  width: 3px;
  height: 3px;
  background: #777b82;
  position: absolute;
}
.spot-dark {
  background: #49e1fc;
}
.spot-top-left {
  top: 6px;
  left: 4px;
}
.spot-top-right {
  top: 6px;
  right: 4px;
}
.spot-lower-left {
  bottom: 4px;
  left: 4px;
}
.spot-lower-right {
  bottom: 4px;
  right: 4px;
}
.device-top {
  display: flex;
  flex-direction: initial;
  width: 100%;
  height: 35px;
  background: rgba(36, 48, 66, 0.8);
  border: 1px solid #445565;
  line-height: 35px;
  padding: 0 16px;
  justify-content: space-between;
}
.device-top > div {
  display: flex;
  flex-direction: initial;
}
.device-top img {
  width: 23px;
  height: 16px;
  margin-top: 10px;
}
.device-top .device-top-icon {
  width: 18px;
  height: 20px;
  margin-top: 5px;
}
.device-top .device-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #baf1fe;
  margin-left: 11px;
}
.device-list {
  display: flex;
  flex-direction: column;
  padding: 30px 25px;
  height: 100%;
  justify-content: space-evenly;
}
.device-info {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
}
.device-con {
  height: 100%;
}
.info-con {
  width: calc(100% - 170px);
}
.info-title {
  width: 100%;
  height: 30px;
  background: rgba(6, 19, 37, 0.4);
  line-height: 30px;
}
.info-value {
  font-size: 18px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #49e1fc;
  margin-left: 40px;
}
.info-num {
  padding: 5px 0 0 30px;
}
.info-name {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #f4f4f5;
  line-height: 25px;
}
.info-chart {
  width: 80px;
  height: 80px;
  opacity: 0.8;
  border: 1px solid transparent;
  border-radius: 50%;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #041629, #041629),
    linear-gradient(180deg, #4f9ac9, transparent);
  position: relative;
}
.info-chart-val {
  position: absolute;
  font-size: 18px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #49e1fc;
  width: 100%;
  text-align: center;
  top: 50%;
  margin-top: -15px;
}
.info-rate {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #9eb1c8;
  float: right;
  margin-right: 5px;
}
.info-ratio {
  font-size: 22px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #49e1fc;
  float: right;
}
.info-tab-data {
  width: 100%;
}
.info-tab-data th {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #6d8097;
}
.info-tab-data td {
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.info-tab-data td.color1 {
  color: #ffffff;
}
.info-tab-data td.color2 {
  color: #28ca93;
}
.info-tab-data td.color3 {
  color: #baf1fe;
}
.info-tab-data td.color4 {
  color: #e43e69;
}
.charts-line {
  width: 360px;
  height: 240px;
}
.device-line {
  width: 100%;
  height: 1px;
  /* background: #253751; */
  margin: 20px auto;
  background-image: linear-gradient(to right, #253751, #253751),
    linear-gradient(180deg, #4f9ac9, transparent);
}
</style>