<template>
  <div class="config-container">
    <div class="config-header">
      <div :class="['header-tab-item', item.code == current ? 'header-item-selected' : '']" v-for="(item, idx) in menus" :key="idx"
        @click="clickMenu(item)">{{showLang(item.lang)}}</div>
    </div>
    <div class="config-content">
      <template v-for="(item, idx) in menus">
        <component v-if="item.code == current" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
// import AnalyseEnergyTrend from './trend/Index'
import { mapGetters } from 'vuex'
import AnalyseEnergyTotal from './total/Index'
import AnalyseEnergyCharge from './charge/Index'
export default {
  name: 'Home',
  components: {
    // AuthMenuItem
  },
  props: {
    sys: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      current: 's3',
      menus: [
        // {code: 's1', name: '能耗趋势分析', com: AnalyseEnergyTrend, args: {}},
        { code: 's3', lang:'com.data.energy.cost',name: '能耗费用统计', com: AnalyseEnergyCharge, args: {} },
        { code: 's2', lang:'com.data.energy.comprehensive',name: '能耗综合分析', com: AnalyseEnergyTotal, args: {} },
        // {code: 's4', name: '配电柜资产统计', com: AssetAnalyseBox, args: {}},
        // {code: 's5', name: '资产使用单位统计', com: AssetRecordOrgan, args: {}},
        // {code: 's6', name: '资产使用道路统计', com: AssetRecordRoad, args: {}},
        // {code: 's7', name: '资产使用类型统计', com: AssetRecordType, args: {}},
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
  },
  watch: {
  },
  mounted: function () {

  },
  methods: {
    clickMenu: function (item) {
      this.current = item.code;
    },
  }
}
</script>
<style scoped>
.config-container {
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 3px; */
  padding-top: 5px;
}
.config-header {
  height: 34px;
  flex: none;
  display: flex;
  margin: 15px 0;
}

.header-tab-item {
  border-bottom: 0;
  cursor: pointer;
  padding: 0 10px;
  line-height: 34px;
  background-color: #eef4f9;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.header-tab-item:first-child {
  border-radius: 6px 0px 0px 0px;
}
.header-tab-item:last-child {
  border-radius: 0px 6px 0px 0px;
}
.header-item-selected {
  background-color: #3880fc;
  color: #ffffff;
}
.config-content {
  height: 500px;
  flex: auto;
}
</style>
