<template>
  <div class="alarm-box">
    <amapPage />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import amapPage from '@/components/map/amap'

export default {
  name: 'gis',
  components: {
    amapPage
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('auth', ['config']),
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {

  },
}
</script>
<style scoped>
.alarm-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
</style>