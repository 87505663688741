<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">新增多层图片</div>
    <Form ref="form" class="form-area" :model="form" :rules="rules">
      <FormItem prop="parentId" label="是否底图">
        <i-switch size="large" v-model="form.isBase" :disabled="hasBase">
          <span slot="open">底图</span>
          <span slot="close">分组</span>
        </i-switch>
      </FormItem>
      <FormItem prop="groupId" label="关联分组">
        <Select v-model="form.groupId" style="width: 300px">
          <Option v-for="item in groups" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem prop="linkman" label="选择图片">
        <img v-if="previewUrl != ''" :src="previewUrl" class="preview-image-area" @click="selectFile" />
        <input :class="[previewUrl != '' ? 'file-input-area' : '']" ref="fileInput" type="file" accept=".png,.jpeg,.jpg" @change="fileChanged" />
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'HomeMapEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    hasBase: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showModal: this.value,
      showMapModal: false,
      loading: false,
      form: {
        isBase: false,
        groupId: 0,
        file: null,
      },
      previewUrl: '',
      rules: {},
      // groups: [],
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.form.isBase = false;
        this.form.groupId = 0;
        this.form.file = null;
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('group', ['groups']),
  },
  mounted: function () {
  },
  methods: {
    fileChanged: async function (e) {
      if (e.target.files.length == 0) return;
      let file = e.target.files[0];
      if (file.size / 1024 / 1024 > 5) {
        file.value = "";
        this.$Message.error("图片大小不能大于5M");
        return false;
      }
      this.form.file = e.target.files[0];
      this.previewUrl = window.webkitURL.createObjectURL(this.form.file);
    },
    selectFile: function(){
      this.$refs.fileInput.click();
    },
    ok: function () {
      if(this.form.groupId <= 0){
        this.$Message.warning("不能选择根分区");
        return;
      }
      if(this.form.file == null){
        this.$Message.warning("请选择文件");
        return;
      }
      let formData = new FormData();
      formData.append("isBase", this.form.isBase);
      formData.append("groupId", this.form.groupId);
      formData.append("file", this.form.file);
      // this.$axios.post(`//${this.domains.trans}/sys/setting/SaveHomeMap`, formData)
      this.$axios.request({
        url: `//${this.domains.trans}/sys/setting/SaveHomeMap`,
        method: 'post',
        data: formData,
        responseType: 'json',
        myType: 'file'
      }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$Message.info('保存成功');
          this.$emit('saved');
          this.showModal = false;
        }
      });
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.preview-image-area{
  width: 800px;
}
.file-input-area{
  width: 0;
  height: 0;
}
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.edit-area {
  /* border: solid 1px blue; */
  width: 100%;
  height: 715px;
  display: flex;
}
.fun-area {
  width: 400px;
  height: 100%;
  flex: auto;
  margin-left: 20px;
  border: solid 1px rgba(216, 199, 199, 0.438);
  border-radius: 6px;
  overflow: auto;
  position: relative;
}
.chk-all {
  position: absolute;
  right: 10px;
  top: 10px;
}
.logo-upload {
  width: 410px;
  height: 114px;
  border: solid 1px gray;
  cursor: pointer;
}
.logo-upload input {
  visibility: hidden;
}
.logo-upload img {
  width: 410px;
  height: 114px;
}
.fun-list {
  display: flex;
  /* list-style: none; */
  flex-direction: column;
  margin: 8px 15px;
}
.chk-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
li {
  margin: 0 5px;
}
</style>