<template>
  <div class="alarm-box" ref="alarmBox">
    <div class="map-tooltip" :style="{visibility: tooltipShow ? 'visible':'hidden', left: `${tooltip.x1-50}px`, top: `${tooltip.y1+42}px`}">
      <div class="tooltop-border" style="left: 0;top: 0;border-right: 0;border-bottom:0"></div>
      <div class="tooltop-border" style="right: 0;top: 0;border-left: 0;border-bottom:0"></div>
      <div class="tooltop-border" style="left: 0;bottom: 0;border-right: 0;border-top:0"></div>
      <div class="tooltop-border" style="right: 0;bottom: 0;border-left: 0;border-top:0"></div>
      <div class="tooltip-content">
        <div class="tooltip-content-header">{{tooltip.groupName}}</div>
        <div class="tooltip-content-center">
          <div class="tooltip-energy">
            <div class="tooltip-energy-name">本月用电量：</div>
            <div class="tooltip-energy-value">{{tooltip.monthUsage}} KW/h</div>
          </div>
          <div class="tooltip-energy">
            <div class="tooltip-energy-name">本年用电量：</div>
            <div class="tooltip-energy-value">{{tooltip.yearUsage}} KW/h</div>
          </div>
          <div class="tooltip-energy">
            <div class="tooltip-energy-name">日均能耗：</div>
            <div class="tooltip-energy-avg">{{tooltip.avgDay}} KW</div>
          </div>
          <div class="tooltip-energy">
            <div class="tooltip-energy-name">月均能耗：</div>
            <div class="tooltip-energy-value">{{tooltip.avgMonth}} KW</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-map" v-if="centers.length>0">
      <template v-for="n,index in centers">
        <div :key="index" @mouseover="pointerOver($event, n)" @mouseout="pointerOut" class="center-pointer" :style="{ left: `${n.x1}px`, top: `${n.y1}px`}">
          <div class="container-name">{{n.groupName}}</div>
          <div class="container-triangle"></div>
        </div>
      </template>

    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'manyImg',
  components: {
  },
  data() {
    return {
      centers: [],
      tooltipShow: false,
      tooltip: {
        show: false,
        x: 100,
        y: 100,
      },
    }
  },
  computed: {
    ...mapState('auth', ['config']),
  },
  created: function () {
  },
  mounted: function () {
    this.manyImg()
  },
  methods: {
    manyImg() {
      this.$axios.post(`//${this.domains.trans}/station/light/HomeGroup`, {}).then(res => {
        if (res.code !== 0) return;
        this.$set(this, "centers", res.data);
        this.handle();
      });
    },
    handle() {
      let Xbox = this.$refs.alarmBox.offsetWidth;
      let Ybox = this.$refs.alarmBox.offsetHeight;
      this.centers.forEach(el => {
        el.x1 = Xbox * el.x / (800 - 50);
        el.y1 = Ybox * el.y / (404 - 80);
      });
    },
    pointerOver: function (e, n) {
      this.tooltip = n;
      this.tooltipShow = true;
    },
    pointerOut: function () {
      this.tooltipShow = false;
    },
  },
}
</script>
<style scoped>
.alarm-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.tooltip-content {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  /* padding: 15px 10px; */
}
.tooltip-content-header {
  width: 100%;
  height: 28px;
  background: linear-gradient(90deg, #49e1fc, #49a6fc);
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021727;
  line-height: 28px;
  text-align: left;
  text-indent: 15px;
}
.tooltip-content-center {
  /* border: solid 1px red; */
  flex: auto;
  padding: 5px 15px;
}
.tooltip-content-footer {
  /* border: solid 1px red; */
  height: 50px;
  flex: auto;
  padding: 0 15px;
}
.tooltip-name {
  /* border: solid 1px red; */
  height: 30px;
  line-height: 30px;
  flex: none;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.tooltip-area {
  /* border: solid 1px red; */
  height: 20px;
  line-height: 20px;
  flex: none;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.tooltip-energy {
  height: 35px;
  flex: auto;
  display: flex;
  align-items: center;
}
.tooltip-energy-name {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
  width: 90px;
  flex: none;
}
.tooltip-energy-value {
  /* border: solid 1px red; */
  font-size: 16px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #51e7f3;
  text-align: left;
  width: 80px;
  flex: auto;
}
.tooltip-energy-avg {
  /* border: solid 1px red; */
  font-size: 16px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #f2cf41;
  text-align: left;
  width: 80px;
  flex: auto;
}
.container {
  width: 100%;
  height: 100%;
  background-color: #010b13;
  background-size: 100% auto;
  background-repeat: no-repeat;
  display: flex;
  /* border: solid 1px red; */
  overflow: auto;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0 0 30px 0;
  /* margin: 20px 0 40px 0; */
  position: relative;
}
.container::-webkit-scrollbar {
  display: none;
}

.status-count-item {
  /* border: solid 1px red; */
  height: 10px;
  flex: auto;
  display: flex;
  flex-direction: column;
}

.map-tooltip {
  position: absolute;
  height: 184px;
  background: rgba(13, 44, 77, 0.8);
  z-index: 500;
}
.tooltop-border {
  position: absolute;
  width: 7px;
  height: 7px;
  border: 2px solid #49e1fc;
  opacity: 0.8;
}
.container-map {
  /* border: solid 1px red; */
  /* position: absolute;
  left: 20%;
  top: 150px;
  width: 55%;
  height: 700px; */
}
.center-pointer {
  position: absolute;
  padding-top: 2px;
  cursor: pointer;
}
.container-name {
  padding: 0 10px;
  height: 24px;
  background: linear-gradient(135deg, #49e1fc, #49a6fc);
  border-radius: 4px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #011121;
  line-height: 24px;
}
.container-triangle {
  width: 0;
  height: 0;
  border-top: 12px solid #49a6fc;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  margin-top: 4px;
}
.container-box {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 100%;
}
.mt30 {
  margin-top: 30px;
}
.container-left,
.container-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.container-lower {
  width: calc(100% - 980px);
}
</style>