<template>
  <div class="software">
    <div class="software-tree">
      <treeBox @saved='treeData' />
    </div>
    <div class="software-con">
      <softList :bigTypeId="treeId" />
    </div>
  </div>
</template>
<script>
import treeBox from './treeBox'
import softList from './softList'
export default {
  name: 'software',
  components: {
    treeBox,
    softList,
  },
  data() {
    return {
      treeId: 0
    }
  },
  created: function () {
  },
  computed: {
    
  },
  watch: {
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    treeData(params) {
      this.treeId = params;
    }
  },
}
</script>
<style scoped>
.software {
  width: 100%;
  height: 100%;
  display: flex;
}
.software-tree {
  width: 300px;
  height: 100%;
}
.software-con {
  width: calc(100%);
  height: 100%;
  margin-left: 20px;
}
</style>