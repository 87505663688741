<template>
<Modal v-model="showModal" :mask-closable="false" width="1200px" :styles="{top: '150px', zIndex: 900}">
  <div slot="header">应用列表</div>
  <Table border size="small" stripe highlight-row :loading="main.loading" :columns="main.col" :data="main.list"></Table>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
  </div>
  <ModalEditApp v-model="main.editModal" :item="main.editItem" :appTypes="support.appTypes" @saved="itemDataSaved"/>
  <!-- <ModalEditAppProduct v-model="main.productModal" :item="main.editItem" :appTypes="support.appTypes" @saved="itemDataSaved"/> -->
  <ModalConfigInfo v-model="main.configModal" :item="main.configItem"/>
</Modal>
</template>
<script>
// @ is an alias to /src
import ModalEditApp from './ModalEditApp.vue'
import ModalConfigInfo from './ModalConfigInfo.vue'
export default {
  name: 'SiteAppIndex',
  components: {
    ModalEditApp,
    ModalConfigInfo,
    // ModalEditAppProduct,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      showModal: this.value,
      main: {
        loading: false,
        editModal: false,
        configModal: false,
        deviceModal: false,
        productModal: false,
        configItem:{},
        editItem: {},
        col: [
          {
            title: '应用类型',
            width: 90,
            key: 'type',
            render: (h, params) => {
              return h('div', [
                h('Icon', {
                  props: {
                    type: 'person'
                  }
                }),
                h('strong', this.support.appTypes[parseInt(params.row.type)])
              ]);
            }
          },
          {
            title: '名称',
            width: 200,
            key: 'name'
          },
          {
            title: '应用ID',
            // width: 150,
            key: 'id',
            render: (h, params) => {
              return h('div', [
                h('Icon', {
                  props: {
                    type: 'person'
                  }
                }),
                h('strong', params.row.id.replace(/-/g, ''))
              ]);
            }
          },
          {
            title: '密钥',
            // width: 170,
            key: 'secret',
            render: (h, params) => {
              params.row.n = 'm'
              return h('div', [
                  h('Icon', {
                      props: {
                          type: 'person'
                      }
                  }),
                  h('strong', params.row.isShow ? params.row.appSecret : params.row.pswd),
                  h('Button', {
                    props: {
                      type: 'text',
                      size: 'small',
                      icon: 'ios-eye-outline',
                      shape: 'circle'
                    },
                    style: {
                      marginLeft: '15px'
                      // width: '93px'
                    },
                    on: {
                      click: () => {
                        params.row.isShow = !params.row.isShow;
                      }
                    }
                  }),
              ]);
            }
          },
          {
            title: '版本',
            width: 70,
            key: 'version'
          },
          {
            title: '操作',
            key: 'latitude',
            width: 185,
            renderHeader: (h, params) => {
              return h('div', [
                  h('Button', {
                    props: {
                      type: 'primary',
                      size: 'small'
                    },
                    style: {
                      // marginRight: '5px'
                      width: '135px'
                    },
                    on: {
                      click: () => {
                        this.handleAddApp(params)
                      }
                    }
                  }, '新增应用'),
              ]);
            },
            render: (h, params) => {
              let btns = [
                h('Button', {
                  props: {
                      type: 'success',
                      size: 'small'
                  },
                  style: {
                      marginRight: '5px'
                  },
                  on: {
                      click: () => {
                          this.handleEditConfig(params)
                      }
                  }
                }, '配置'),
                h('Button', {
                  props: {
                      type: 'error',
                      size: 'small'
                  },
                  style: {
                      // marginLeft: '5px',
                      // marginRight: '5px'
                  },
                  on: {
                      click: () => {
                          this.handleDeleteApp(params)
                      }
                  }
                }, '删除')
              ];
              // if(params.row.type == 0){
              //   btns.splice(0, 0, h('Button', {
              //     props: {
              //         type: 'info',
              //         size: 'small'
              //     },
              //     style: {
              //         marginRight: '5px'
              //     },
              //     on: {
              //         click: () => {
              //             this.handleEditProduct(params)
              //         }
              //     }
              //   }, '产品'));
              // }
              if(params.row.type != 99){
                btns.splice(0, 0, h('Button', {
                  props: {
                    type: 'primary',
                    size: 'small'
                  },
                  style: {
                    marginRight: '5px'
                  },
                  on: {
                    click: () => {
                        this.handleEditApp(params)
                    }
                  }
                }, '编辑'));
              }
              return h('div', btns);
            },
          }
        ],
        list: [],
        filter: {
          code: '',
          index: 0,
          size: 10
        }
      },
      support: {
        appTypes: {}
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.getAppList();
        // this.getAppList();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function(){
    // this.getAppList();
  },
  methods: {
    getAppList: async function(){
      let res = await this.$axios.get(`//${this.domains.trans}/app/list`);
      if(res.code == 0){
        for(let item of res.data.list){
          item.isShow = false;
          item.pswd = '********************************************'
        }
        this.$set(this.main, "list", res.data.list);
        this.$set(this.support, "appTypes", res.data.appTypes);
      }else{
        // this.$Message.error(res.data);
      }
    },
    handleEditDevice: function(params){
      this.main.editItem = this.clone(params.row);
      this.main.editItem.index = params.index;
      this.main.deviceModal = true;
    },
    handleEditProduct: function(params){
      this.main.editItem = this.clone(params.row);
      this.main.editItem.index = params.index;
      this.main.productModal = true;
    },
    handleAddApp: function(){
      this.main.editItem = {name: ''};
      this.main.editItem.index = -1;
      this.main.editModal = true;
    },
    handleEditApp: function(params){
      this.main.editItem = this.clone(params.row);
      this.main.editItem.index = params.index;
      this.main.editModal = true;
    },
    handleEditConfig: function(params){
      this.main.configItem = this.clone(params.row);
      this.main.configItem.index = params.index;
      this.main.configModal = true;
    },
    handleDeleteApp: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除应用[${params.row.name}]吗?`,
        onOk: async () => {
          let res = await this.$axios.delete(`app/list/${params.row.id}`);
          if(res.code == 0){
            this.$Message.info(`删除成功`, { timeout: 4000 });
            this.main.list.splice(params.row.index, 1);
          }else{
            // this.$Message.error({
            //   content: `删除失败：${res.data}`,
            //   duration: 4
            // });
          }
        }
      });
    },
    itemDataSaved: function(form){
      form.data.isShow = false;
      form.data.pswd = '********************************************'
      if(form.add){
        this.main.list.push(form.data);
      }else{
        this.$set(this.main.list, form.index, form.data);
      }
    },
    cancel: function(){
      this.main.editModal = false;
      this.main.configModal = false;
      this.main.deviceModal = false;
      this.showModal = false;
    }
  },
}
</script>
<style scoped>
  .flex-single{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
  }
</style>