<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">查看报警数据 - 发生时间: {{item.startTime}}</div>
    <div class="search-data-header">
      <div class="analyse-table">
        <table border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td colspan="4" style="color:#2d8cf0;text-align: center;">
              {{item.source==1?'市民上报':'系统生成'}}
              <!-- <Button style="margin-right: 0; float:right;" size="small" type="warning" @click="showCompareLogs">判定是误报</Button> -->
              
            </td>
          </tr>
          <tr>
            <td style="width: 100px">所在站点</td>
            <td style="width: 300px">{{item.stationName}}</td>
            <td style="width: 100px">发生时间</td>
            <td>{{item.startTime}}</td>
          </tr>
          <tr>
            <td>报警属性</td>
            <td>{{item.pName}}</td>
            <td>报警类型</td>
             <!-- v-if="item.type == 13 || item.type == 23" -->
            <td style="color:#f00">{{item.name}}<Button v-if="item.type == 13 || item.type == 23 || item.type == 1" style="margin-right: 0; float:right;" size="small" type="primary" @click="showCompareLogs">对比记录</Button></td>
          </tr>
          <tr>
            <td>故障现象</td>
            <td colspan="3" style="color:#f00">{{item.extra}}</td>
          </tr>
          <tr v-if="item.source==1">
            <td>联系人</td>
            <td>{{item.sourceName}}</td>
            <td>方式</td>
            <td>{{item.mobile}}</td>
          </tr>
          <tr>
            <td>故障确认</td>
            <td colspan="3">{{item.isConfirm ? '已确认' : '未确认'}}
              <Button v-if="!item.done && !item.isConfirm" style="margin-right: 0; float:right;" size="default" type="primary" @click="confirmAlarm">确认告警</Button>
              <span v-if="item.isConfirm">: 由 {{item.byUser ? '用户' : '工单系统'}} [{{item.confirmName}}] 确认于 [{{item.confirmTime}}]</span>
              <!-- <Button v-if="!item.done && item.isConfirm && (item.type == 13 || item.type == 23) && !item.isFixed" style="margin-right: 10px; float:right;" size="default" type="success" @click="confirmAlarmError">修正为正常</Button>
              <span v-if="item.isFixed" style="margin-right: 0; float:right; color: blue">已修正为正常</span> -->
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="item.source==1 && item.imageIds.length>0" style="overflow: hidden;">
      <div v-for="el,index in item.imageIds" :key="index">
        <div class="showImgBox">
          <img :src="imgListAjax(el)" alt="">
        </div>
      </div>
    </div>
    <div class="log-search-area">
      {{ showLang('com.date.start') }}：
      <DatePicker v-model="form.start" type="datetime" format="yyyy-MM-dd HH:mm" style="margin-right: 10px; width: 155px;"></DatePicker>
      {{ showLang('com.date.end') }}：
      <DatePicker v-model="form.end" type="datetime" format="yyyy-MM-dd HH:mm" style="margin-right: 10px; width: 155px;"></DatePicker>
      <Button size="default" style="margin-right: 5px" @click="getList">{{ showLang('com.op.query') }}</Button>
    </div>
    <div class="search-data-content" ref="table1">
      <div v-if="history.length == 0" style="height: 400px; background-color: white;text-align:center;line-height: 200px">暂无数据</div>
      <virtual-list v-else-if="item.paramType >= 0 && item.paramType < 100" class="table-data" :style="{height: `500px`}" :data-key="'fireTime'" :data-sources="history" :data-component="tempHistoryData" :extra-props="pos" />
      <table v-else-if="item.paramType == 199" class="history-item">
        <tr class="sub-header">
          <td>时间</td>
          <td>灯杆名称</td>
          <td>灯头名称</td>
          <td>电压(V)</td>
          <td>电流(A)</td>
          <td>功率(W)</td>
          <td>亮度(%)</td>
          <td>温度(℃)</td>
          <!-- <td>温度(℃)</td> -->
          <!-- <td>工作时间</td> -->
        </tr>
        <template v-for="(item, idx) in history">
          <tr :class="[idx%2==1?'active':'']" :key="`row1-${idx}`">
            <td rowspan="2">{{new Date(item.fireTime).format('yyyy-MM-dd HH:mm:ss')}}</td>
            <td rowspan="2">{{lightName}}</td>
            <td>{{lightConfig.en1 ? lightConfig.nm1 : '--'}}</td>
            <td rowspan="2" :class="[(item.content.u > lightConfig.uh || item.content.u < lightConfig.ul) ? 'alarm-color' : '']">
              {{item.content.u.toFixed(2)}} ({{lightConfig.ul}} - {{lightConfig.uh}})
            </td>
            <td  :class="[(item.content.c1 > lightConfig.ch || item.content.c1 < lightConfig.cl) ? 'alarm-color' : '']">{{item.content.c1.toFixed(2)}}</td>
            <td>{{item.content.p1.toFixed(2)}}</td>
            <td>{{item.content.op1.toFixed(2)}}</td>
            <td :class="[(item.content.tc > lightConfig.tl) ? 'alarm-color' : '']">芯片：{{item.content.tc.toFixed(1)}}</td>
            <!-- <td :class="[item.content.tc > lightConfig.t ? 'alarm-color' : '']">模块：{{item.content.tm.toFixed(2)}}</td> -->
          </tr>
          <tr :class="[idx%2==1?'active':'']" :key="`row2-${idx}`">
            <td>{{lightConfig.en2 ? lightConfig.nm2: '--'}}</td>
            <td>{{item.content.c2.toFixed(2)}}</td>
            <td>{{item.content.p2.toFixed(2)}}</td>
            <td>{{item.content.op2.toFixed(2)}}</td>
            <td :class="[(item.content.tm > lightConfig.tl) ? 'alarm-color' : '']">模块：{{item.content.tm.toFixed(1)}}</td>
            <!-- <td :class="[item.content.tc > lightConfig.tr ? 'alarm-color' : '']">芯片：{{item.content.tc.toFixed(2)}}</td> -->
          </tr>
        </template>
      </table>
      <Spin size="large" fix v-if="loading"></Spin>
      <ModalAlarmCompare v-model="showCompareModal" :item="item" />
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import TempHistoryData from '@/views/light/road/monitor/station/search/powerbox/PowerHistoryItem'
import VirtualList from 'vue-virtual-scroll-list'
import ModalAlarmCompare from './ModalAlarmCompare'
export default {
  name: 'ModalAlarmData',
  components: {
    ModalAlarmCompare,
    VirtualList,
    'virtual-list': VirtualList
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      tempHistoryData: TempHistoryData,
      showCompareModal: false,
      showModal: this.value,
      loading: false,
      form: {
        stationId: 0,
        start: '',
        end: '',
      },
      alarmTs: 0,
      history: [],
      searchLight: {},
      lightConfig: {},
      lightName: '',
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        let time = new Date(this.item.startTime);
        this.alarmTs = time.getTime();
        this.form.stationId = this.item.stationId;
        time.setMinutes(time.getMinutes() - 65);
        this.form.start = time.format('yyyy-MM-dd HH:mm:ss');
        time.setMinutes(time.getMinutes() + 130);
        this.form.end = time.format('yyyy-MM-dd HH:mm:ss');
        // console.log('modal level edit mounted', this.item)
        this.getList();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    'form.dealType'() {
      if (this.form.dealType == 2) {
        let now = new Date();
        now.setDate(now.getDate() + 7);
        this.$set(this.form.method, 'expire', now.format('yyyy-MM-dd HH:mm:ss'));
      }
    }
  },
  computed: {
    ...mapState('common', ['alarmTypes', 'alarmGroupList', 'alarmLevels', 'dealAlarmTypes', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapGetters('auth', ['showLang']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station)
    },
  },
  mounted: function () {
  },
  methods: {
    showCompareLogs: function(){
      this.showCompareModal = true;
    },
    imgListAjax(id) {
      return `//${this.domains.trans}/station/file/${id}`;
    },
    confirmAlarmError: function(){
      this.$Modal.confirm({
        title: '判定告警',
        content: `确定要修正该告警为正常吗？`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/alarm/FixAlarm`, { id: this.item.id }).then(res => {
            if (res.code == 0) {
              this.item.isFixed = true;
              this.$emit('alarmFixed', {id: this.item.id});
              // this.item.fixedError = res.data;
            }
          });
        }
      });
    },
    confirmAlarm: function(){
      this.$Modal.confirm({
        title: '确认告警',
        content: `确定已知道该告警吗？`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/alarm/ConfirmAlarm`, {id: this.item.id}).then(res => {
            if(res.code == 0){
              if(!res.data.success){
                this.$Modal.error({
                  title: '确认失败',
                  content: res.data.message,
                });
                return;
              }
              this.item.byUser = res.data.byUser;
              this.item.confirmId = res.data.confirmId;
              this.item.confirmName = res.data.confirmName;
              this.item.confirmTime = res.data.confirmTime;
              this.item.isConfirm = res.data.isConfirm;
            }
          });
        }
      });
    },
    getList() {
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      if (this.item.paramType >= 0 && this.item.paramType < 100) {
        let params = { sid: this.form.stationId, start: this.form.start, end: this.form.end };
        let stations = this.selectedStations.filter(p => p.id == this.form.stationId);
        if(stations.length > 0){
          let station = stations[0];
          this.pos = { title: station.name, lat: station.lat, lng: station.lng };
        }
        this.loading = true;
        this.$axios.post(`//${this.domains.trans}/station/history/PowerBox`, params).then(res => {
          this.loading = false;
          if (res.code != 0) {
            // this.$Message.error(res.data);
          } else {
            let empty = [], isExplain = 1;
            let ms = res.data.filter(p => p.type == 1);
            if (ms.length == 0) {
              this.$set(this, 'history', []);
              return;
            }
            empty = ms[0];
            if (empty.cfg.enc || empty.cfg.enp) {
              isExplain = 1;
            } else {
              isExplain = 12;
            }
            let his = [];
            let deltaTs = 99990;
            // let tsNear = 0;

            for (let d of empty.list) {
              let delta = Math.abs(this.alarmTs - d.ts);
              if (delta < deltaTs) {
                deltaTs = delta;
                // tsNear = d.ts;
              }
              // let cfg = station.params.filter(p => p.id == empty.id)[0];
              let item = {
                isExplain: isExplain,
                fireTime: d.ts,
                time: d.ts,
                main: {
                  cfg: empty.cfg,
                  extra: empty.extra,
                  content: d.content
                },
                switchs: [],
                outputs: [],
                contacts: [],
                branchs: [],
                doors: [],
                locks: [],
                smokes: [],
                waters: [],
                leakage: [],
              };

              res.data.filter(p => p.type == 2).map(b => {
                // let bcfg = station.params.filter(p => p.id == b.id)[0];
                let datas = b.list.filter(p => p.ts == d.ts);
                if (datas.length == 0) return;
                item.switchs.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
              });
              res.data.filter(p => p.type == 3).map(b => {
                // let bcfg = station.params.filter(p => p.id == b.id)[0];
                let datas = b.list.filter(p => p.ts == d.ts);
                if (datas.length == 0) return;
                item.outputs.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
              });
              res.data.filter(p => p.type == 4).map(b => {
                // let bcfg = station.params.filter(p => p.id == b.id)[0];
                let datas = b.list.filter(p => p.ts == d.ts);
                if (datas.length == 0) return;
                item.contacts.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
              });
              res.data.filter(p => p.type == 5).map(b => {
                // let bcfg = station.params.filter(p => p.id == b.id)[0];
                let datas = b.list.filter(p => p.ts == d.ts);
                if (datas.length == 0) return;
                item.branchs.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
              });
              res.data.filter(p => p.type == 6).map(b => {
                // let bcfg = station.params.filter(p => p.id == b.id)[0];
                let datas = b.list.filter(p => p.ts == d.ts);
                if (datas.length == 0) return;
                item.doors.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
              });
              res.data.filter(p => p.type == 7).map(b => {
                // let bcfg = station.params.filter(p => p.id == b.id)[0];
                let datas = b.list.filter(p => p.ts == d.ts);
                if (datas.length == 0) return;
                item.locks.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
              });
              res.data.filter(p => p.type == 8).map(b => {
                // let bcfg = station.params.filter(p => p.id == b.id)[0];
                let datas = b.list.filter(p => p.ts == d.ts);
                if (datas.length == 0) return;
                item.smokes.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
              });
              res.data.filter(p => p.type == 9).map(b => {
                // let bcfg = station.params.filter(p => p.id == b.id)[0];
                let datas = b.list.filter(p => p.ts == d.ts);
                if (datas.length == 0) return;
                item.waters.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
              });
              res.data.filter(p => p.type == 11).map(b => {
                // let bcfg = station.params.filter(p => p.id == b.id)[0];
                let datas = b.list.filter(p => p.ts == d.ts);
                if (datas.length == 0) return;
                item.leakage.push({ name: b.name, cfg: b.cfg, content: datas[0].content });
              });
              his.push(item);
            }
            // let idx = -1;
            // for (let i = 0; i < his.length; i++) {
            //   if (his[i].time == tsNear) {
            //     idx = i;
            //     break;
            //   }
            // }
            // if (idx <= 1) {
            //   his.splice(idx + 2, his.length - idx - 2);
            // } else {
            //   his.splice(idx + 2, his.length - idx - 2);
            //   his.splice(0, idx - 1);
            // }
            this.$set(this, 'history', his);
          }
        });
      } else if (this.item.paramType == 199) {
        let params = { sid: this.form.stationId, paramId: this.item.paramId, start: this.form.start, end: this.form.end };
        // for(let station of this.selectedStations){
        //   if(station.station.id == this.form.stationId){
        //     let ls = station.params.filter(p => p.id == this.item.paramId);
        //     if (ls.length > 0) {
        //       this.searchLight = ls[0];
        //     } else {
        //       this.searchLight = {};
        //     }
        //     break;
        //   }
        // }
        this.$axios.post(`//${this.domains.trans}/station/param/HistoryWithCfg`, params).then(res => {
          this.loading = false;
          if (res.code == 0) {
            this.lightConfig = res.data.cfg;
            // console.log('this.lightConfig')
            // console.log(this.lightConfig)
            this.lightName = res.data.name;
            let deltaTs = 99990;
            // let tsNear = 0;
            var list = res.data.list;
            list.forEach(el => {
              let delta = Math.abs(this.alarmTs - el.fireTime);
              if (delta < deltaTs) {
                deltaTs = delta;
                // tsNear = el.fireTime;
              }
              for (let attr in el.content) {
                if (!isNaN(el.content[attr]) && el.content[attr] < 0) {
                  el.content[attr] = 0
                }
              }
            });
            // let idx = -1;
            // for (let i = 0; i < list.length; i++) {
            //   if (list[i].fireTime == tsNear) {
            //     idx = i;
            //     break;
            //   }
            // }
            // if (idx <= 1) {
            //   list.splice(idx + 2, list.length - idx - 2);
            // } else {
            //   list.splice(idx + 2, list.length - idx - 2);
            //   list.splice(0, idx - 1);
            // }
            this.$set(this, 'history', list);
            // console.log('list')
            // console.log(list)
          }
        });
        // console.log('get list', this.history)
      }
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.log-search-area{
  /* border: solid 1px red; */
  height: 50px;
}
.alarm-color {
  color: red;
}
td {
  height: 40px;
  /* white-space: nowrap; */
  width: 120px;
  border: solid 1px #cfe3f7;
}
.sub-header {
  background-color: #cfe3f7;
  /* background: #CFE3F7; */
  height: 46px;
  text-align: center;
}
.history-item {
  /* border-width: 1px;
  border-collapse: collapse;
  border-color: cyan; */
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
}
.search-data-content {
  height: 500px;
  flex: auto;
  /* border: solid 1px blue; */
  overflow: auto;
}
.analyse-table table {
  width: 100%;
  height: 93px;
  margin-bottom: 20px;
}
.analyse-table table td {
  /* width: 115px; */
  /* text-align: center; */
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #d0d9e9;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  padding: 0 10px;
}
.showImgBox {
  width: 300px;
  height: 300px;
  border: 1px solid #b7afaf;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 5px;
}
.showImgBox img {
  max-width: 297px;
  max-height: 297px;
}
/* .analyse-table table tr:last-child td {
  border-bottom: none;
}
.analyse-table table tr td:last-child {
  border-right: none;
} */
</style>