<template>
  <div class="alarm-box">
    <div class="alarm-horn-left"></div>
    <div class="alarm-horn-right"></div>
    <div class="alarm-con">
      <div class="alarm-horn-spot spot-dark spot-top-left"></div>
      <div class="alarm-horn-spot spot-dark spot-top-right"></div>
      <div class="alarm-horn-spot spot-lower-left"></div>
      <div class="alarm-horn-spot spot-lower-right"></div>
      <div class="alarm-top">
        <div>
          <img :src="img.light.carat.home.Alerts" alt="" class="alarm-top-icon">
          <div class="alarm-title">告警状态统计</div>
        </div>
        <img :src="img.light.carat.home.arrow" alt="">
      </div>
      <div class="alarm-tips">
        <div class="alarm-tips-list">
          <p class="alarm-tips-tile"><img :src="img.light.carat.home.police3" alt="">严重报警</p>
          <p class="alarm-tips-val"><i>{{levelData[0]}}</i>个</p>
        </div>
        <div class="alarm-tips-list">
          <p class="alarm-tips-tile"><img :src="img.light.carat.home.police2" alt="">普通报警</p>
          <p class="alarm-tips-val"><i>{{levelData[1]}}</i>个</p>
        </div>
        <div class="alarm-tips-list">
          <p class="alarm-tips-tile"><img :src="img.light.carat.home.police1" alt="">预报警</p>
          <p class="alarm-tips-val"><i>{{levelData[2]}}</i>个</p>
        </div>
      </div>
      <div class="alarm-list">
        <div class="echarts-gauge">
          <baseCircle id="baseCircle" :key="timer" />
        </div>
        <div class="echarts-pie">
          <chartsPie :option="chartsPieData1" id="chartsPeoplePie1" :key="timer"></chartsPie>
        </div>
        <div class="echarts-tips">
          <p class="echarts-num">{{total}}</p>
          <p class="echarts-title">报警总数</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import baseCircle from '@/components/charts/baseCircle'
export default {
  name: 'alarmBox',
  components: {
    baseCircle,
  },
  data() {
    return {
      timer: 0,
      total: 0,
      policeTypeData: "",
      chartsPieData1: {
        id: "chartsPeoplePie1",
        series: [
          { value: 80, name: "过流" },
          { value: 5, name: "过压" },
          { value: 9, name: "欠流" },
          { value: 35, name: "欠亚" },
        ],
        color: ["#76FFFE", "#58B8FF", "#DB2C82", "#B5EAFF"],
        content: {
        },
        setSeries: {
          type: 'pie',
          radius: ['77%', '91%'],
          label: {
            show: false,
            position: 'center'
          },
          itemStyle: {
            borderColor: '#2D1535',
            borderWidth: 2
          },
        }
      },
      levelData: [0, 0, 0],//level
    }
  },
  computed: {
    ...mapState('auth', ['config']),
  },
  created: function () {
  },
  mounted: function () {
    this.policeType();
  },
  methods: {
    policeType: async function () {
      let res = await this.$axios.post(`//${this.domains.trans}/station/alarm/QueryStationTypesOld`, {});
      if (res.code !== 0) return;
      this.policeTypeData = res.data;
      this.policeAjax();
    },
    policeAjax: async function () {
      let res = await this.$axios.post(`//${this.domains.trans}/station/home/QueryAlarmLevelAndTypes`, {});
      if (res.code !== 0) return;
      this.timer = new Date().getTime();
      let newAlatrm = res.data.light.type.concat(res.data.power.type)
      this.total = this.analysisTotal(newAlatrm);
      this.chartsPieData1.series = this.typeAdd(newAlatrm, true);
      this.levelHandle(res.data.light.level)
      this.levelHandle(res.data.power.level)
    },
    levelHandle(data) {
      data.forEach(el => {
        switch (el.level) {
          case 10:
            this.levelData[0] = el.cnt + this.levelData[0]
            break;
          case 20:
            this.levelData[1] = el.cnt + this.levelData[1]
            break;
          case 30:
            this.levelData[2] = el.cnt + this.levelData[2]
            break;

        }
      });
    },
    analysisTotal(data) {
      let num = 0;
      for (let { cnt } of data) {
        num = num + cnt;
      }
      return num;
    },
    typeAdd(data, is) {
      for (let i = 0; i < data.length; i++) {
        data[i].value = data[i].cnt;
        let name = this.policeTypeData[data[i].type];
        data[i].name = name;
      }
      if (is) {
        data = data.sort((n1, n2) => {
          return n2.cnt - n1.cnt;
        });
        if (data.length > 6) {
          data[5].name = "其他";
          data.forEach((el, index) => {
            if (index > 5) {
              data[5].value = el.cnt * 1 + data[5].cnt * 1
            }
          });
        }
      }
      return data;
    },
  },
}
</script>
<style scoped>
.alarm-box {
  width: 420px;
  height: 346px;
  background: rgba(3, 16, 33, 0.9);
  border: 1px solid #2f3c48;
  position: relative;
  padding: 7px 4px;
}
.alarm-horn-left {
  width: 8px;
  height: 8px;
  border-top: 2px solid #49e1fc;
  border-left: 2px solid #49e1fc;
  position: absolute;
  top: -2px;
  left: -2px;
}
.alarm-horn-right {
  width: 8px;
  height: 8px;
  border-top: 2px solid #49e1fc;
  border-right: 2px solid #49e1fc;
  position: absolute;
  top: -2px;
  right: -2px;
}
.alarm-horn-spot {
  width: 3px;
  height: 3px;
  background: #777b82;
  position: absolute;
}
.spot-dark {
  background: #49e1fc;
}
.spot-top-left {
  top: 6px;
  left: 4px;
}
.spot-top-right {
  top: 6px;
  right: 4px;
}
.spot-lower-left {
  bottom: 4px;
  left: 4px;
}
.spot-lower-right {
  bottom: 4px;
  right: 4px;
}
.alarm-top {
  display: flex;
  flex-direction: initial;
  width: 100%;
  height: 35px;
  background: rgba(36, 48, 66, 0.8);
  border: 1px solid #445565;
  line-height: 35px;
  padding: 0 16px;
  justify-content: space-between;
}
.alarm-top > div {
  display: flex;
  flex-direction: initial;
}
.alarm-top img {
  width: 23px;
  height: 16px;
  margin-top: 10px;
}
.alarm-top .alarm-top-icon {
  width: 18px;
  height: 20px;
  margin-top: 5px;
}
.alarm-top .alarm-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #baf1fe;
  margin-left: 11px;
}
.alarm-tips {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  margin-top: 5px;
}
.alarm-tips-tile {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
}
.alarm-tips-tile img {
  vertical-align: middle;
  margin-right: 5px;
}

.alarm-tips-val {
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
}
.alarm-tips-val i {
  font-size: 22px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #ffffff;
  font-style: normal;
}
.alarm-list {
  width: 100%;
  height: 220px;
  position: relative;
}
.alarm-title-small {
  font-size: 14px;
}
.echarts-gauge {
  width: 220px;
  height: 220px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -110px;
  margin-top: -100px;
}
.echarts-pie {
  width: 180px;
  height: 180px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -90px;
  margin-top: -80px;
}
.echarts-tips {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  width: 100px;
  left: 50%;
  margin-left: -50px;
}
.echarts-num {
  font-size: 30px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #db2c82;
  text-align: center;
}
.echarts-title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
  text-align: center;
}
</style>