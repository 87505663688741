<template>
  <div class="station-alarm-container">
    <div class="alarm-data-query">
      <template v-for="(cmd, idx) in canDoCmds">
        <AuthButton v-if="idx < cmdcnt" :key="idx" :opCode="cmd.opcode" size="default" style="margin-right: 5px" @click="clickCmd(cmd)">{{cmd.name}}</AuthButton>
      </template>
      <Dropdown style="margin-right: 10px" v-if="canDoCmds.length > cmdcnt">
        <Button type="primary">
          其他<Icon type="ios-arrow-down"></Icon>
        </Button>
        <div slot="list" class="btns-flex" style="width: 500px">
          <template v-for="(cmd, idx) in canDoCmds">
            <AuthButton v-if="idx >= cmdcnt" :key="idx" :opCode="cmd.opcode" size="default" style="margin: 5px" @click="clickCmd(cmd)">{{cmd.name}}</AuthButton>
          </template>
        </div>
      </Dropdown>
      <AuthButton opCode='sc' type="success" size="default" style="margin-right: 5px" @click="sendFailed">重发失败</AuthButton>
      <AuthButton opCode='sc' type="success" size="default" style="margin-right: 5px" @click="exportResult">导出结果</AuthButton>
      <Checkbox v-model="clearOldLog">发送指令时，是否清除旧指令记录？</Checkbox>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'LightControlMenuCommon',
  components: {
  },
  props: {
  },
  data() {
    return {
      clearOldLog: this.clearOldLogs,
      showArgModal: false,
      cmdItem: { item: {}, other: {}, args: {} },
      cmdcnt: 8,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs', 'monitorCmds']),
    ...mapState('common', ['deviceTypes', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    canDoCmds: function () {
      let cmds = [];
      for (let cmd of this.monitorCmds) {
        if (this.funCodes(cmd.opcode)) cmds.push(cmd);
      }
      return cmds;
    }
  },
  watch: {
    clearOldLog() {
      this.$store.commit('cmd/setOldLogStatus', this.clearOldLog)
    },
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    // console.log('monitor cmds mounted', this.monitorCmds)
  },
  destroyed: function () {
  },
  methods: {
    exportResult: function(){
      this.$emit('cmd', {code: 'export', args: {}})
    },
    sendFailed: function () {
      this.$emit('cmd', {code: 'sendFailed', args: {clearOldLog: this.clearOldLog}})
    },
    getCmdCount: async function () {
      this.$emit('cmd', {code: 'getCmdCount', args: {}});
    },
    clearCmds: async function () {
      this.$emit('cmd', {code: 'clearCmds', args: {}});
    },
    clickCmd: function (item) {
      // console.log('click cmd', item)
      this.$emit('cmd', {code: 'clickCmd', args: {item}});
    },
  }
}
</script>
<style scoped>
.transfer-flex {
  width: 50%;
}
.btns-flex {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}
.station-alarm-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-data-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.alarm-data-area >>> .plTableBox .el-table tr .umy-table-beyond {
  white-space: break-spaces !important;
  color: #324252;
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>