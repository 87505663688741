<template>
  <Modal v-model="showModal" :mask-closable="false" draggable width="1000px">
    <div slot="header">数据解析结果</div>
    <p contenteditable="true">
      <pre class="protocol-result">{{protocolResult}}</pre>
    </p>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'ModalProtocolResult',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      tabData: [],
      protocolResult: {},
    }
  },
  watch: {
    msgs(){
      this.$refs.messageContent.scrollTop = this.$refs.messageContent.scrollHeight;
    },
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.initData();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  computed: {
  },
  mounted: function(){
  },
  methods: {
    initData: async function () {
      // console.log('show protocol', this.item);
      this.$axios.post(`//${this.domains.trans}/station/command/ProtocalResult`, {
        id: this.item.id,
        content: this.item.content
      }).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'protocolResult', res.data);
      })
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.message-content{
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
}
.protocol-result{
  width: 100%;
  height: 500px;
  overflow: auto;
}
</style>