<template>
  <div class="energy-container">
    <div class="energy-header">
      <div class="energy-header-item">
        <div class="energy-header-icon bgColor1"><img :src="img.dataAnalysis.lightning" alt=""></div>
        <div class="energy-header-con">
          <span class="energy-header-title">{{showLang('com.data.today.energy')}}:</span>
          <span class="energy-header-valve">{{totalData.today}} KWh</span>
        </div>
      </div>
      <div class="energy-header-item">
        <div class="energy-header-icon bgColor2"><img :src="img.dataAnalysis.lightning" alt=""></div>
        <div class="energy-header-con">
          <span class="energy-header-title">{{showLang('com.data.yestoday.energy')}}:</span>
          <span class="energy-header-valve">{{totalData.yestoday}} KWh</span>
        </div>
      </div>
      <div class="energy-header-item">
        <div class="energy-header-icon bgColor3"><img :src="img.dataAnalysis.lightning" alt=""></div>
        <div class="energy-header-con">
          <span class="energy-header-title">{{showLang('com.data.week.energy')}}:</span>
          <span class="energy-header-valve">{{totalData.week}} KWh</span>
        </div>
      </div>
      <div class="energy-header-item">
        <div class="energy-header-icon bgColor4"><img :src="img.dataAnalysis.lightning" alt=""></div>
        <div class="energy-header-con">
          <span class="energy-header-title">{{showLang('com.data.month.energy')}}:</span>
          <span class="energy-header-valve">{{totalData.month}} KWh</span>
        </div>
      </div>
      <div class="energy-header-item">
        <div class="energy-header-icon bgColor5"><img :src="img.dataAnalysis.lightning" alt=""></div>
        <div class="energy-header-con">
          <span class="energy-header-title">{{showLang('com.data.year.energy')}}:</span>
          <span class="energy-header-valve">{{ totalData.year}} KWh</span>
        </div>
      </div>
    </div>
    <div class="energy-check">
      <chartsLine class="chart-content" :option="energyCheckData" id="chartEnergyCheck" :key="energyCheckTimer"></chartsLine>
    </div>
    <div class="energy-trend">
      <div class="trend-types">
        <div :class="['trend-types-item', energyTrendType == 'date' ? 'trend-types-item-select' : '']" @click="selectType('date')">{{showLang('com.date.day.trend')}}</div>
        <div :class="['trend-types-item', energyTrendType == 'month' ? 'trend-types-item-select' : '']" @click="selectType('month')">{{showLang('com.date.monthly.trend')}}</div>
        <div :class="['trend-types-item', energyTrendType == 'year' ? 'trend-types-item-select' : '']" @click="selectType('year')">{{showLang('com.date.annual.trend')}}</div>
      </div>
      <chartsBar :option="energyTrendData" id="chartEnergyTrend" :key="energyTrendTimer" @click="energyTrendClick"></chartsBar>
    </div>
    <div class="energy-content">
      <u-table :data="energyDetail" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="600" stripe :empty-text="showLang('com.data.no')">
        <u-table-column type="index" :label="showLang('com.export.cn.seq')" width="55"></u-table-column>
        <u-table-column prop="date" :label="showLang('com.date')" sortable fit></u-table-column>
        <u-table-column v-if="appType == 'light'" prop="paramName" :label="showLang('com.device.lamp')" sortable fit></u-table-column>
        <u-table-column v-if="appType != 'light'" prop="stationName" :label="showLang('com.site.name')" sortable fit></u-table-column>
        <u-table-column v-if="appType != 'light'" prop="pcnt" :label="showLang('com.device.branch.num')" sortable fit></u-table-column>
        <u-table-column v-if="appType != 'light'" prop="power" :label="showLang('com.device.branch.energy')+'(KWh)'" sortable fit></u-table-column>
        <u-table-column v-if="appType != 'light'" prop="lcnt" :label="showLang('com.lamp.num')" sortable fit></u-table-column>
        <u-table-column v-if="appType != 'light'" prop="lamp" :label="showLang('energy.nav.lamp')+'(KWh)'" sortable fit></u-table-column>
        <u-table-column prop="val" :label="showLang('com.stat.q.total.val')" sortable fit></u-table-column>
        <u-table-column prop="rate" :label="showLang('com.proportion1')" sortable fit></u-table-column>
      </u-table>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'AnalyseEnergyTrend',
  components: {
  },
  props: {
  },
  data() {
    return {
      spin: {
        show: false,
        content: 'Loading...',
      },
      editModal: false,
      tabHeight: 500,
      tabData: [],
      formats: {
        year: 'yyyy',
        month: 'yyyy-MM',
        date: 'yyyy-MM-dd',
      },
      filter: {
        groupId: 0, //分组节点
        stationId: 0, //站点
        type: 'month', // year/month/day
        start: '',
        end: '',
      },
      energyCheckTimer: 0,
      energyCheckData: {
        title: "同比能耗",
        x: [],
        unit: "KWh",
        series: [
          {
            name: "能耗1",
            data: [],
          },
          {
            name: "能耗2",
            data: [],
          },
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {}, //下阴影
        },
      },
      energyTrendType: 'date', //year,month,date
      energyTrendTimer: 0,
      energyTrendData: {
        // color: ['#5470C6', '#91CC75', '#FAC858'],
        title: "能耗趋势",
        x: [],
        unit: "",
        series: [
          {
            name: "当日能耗",
            data: [],
            barMaxWidth: 30
          },
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {}, //下阴影
        },
      },
      totalData: {
        year: 0,
        month: 0,
        week: 0,
        yestoday: 0,
        today: 0
      },
      energyDetail: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('auth', ['appType']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('common', ['productCodes']),
  },
  watch: {
    windowOnResize() {
      // this.setTableHeight();
    },
    selectedNode() {
      this.getEnergyTotal();
      this.getEnergyCheck();
      this.getEnergyTrend();
    },
  },
  mounted: function () {
    let now = new Date();
    this.filter.end = now.format('yyyy-MM-dd');
    now.setMonth(now.getMonth() - 1);
    this.filter.start = now.format('yyyy-MM-dd');
    // setTimeout(this.setTableHeight, 200);
    this.getEnergyTotal();
    this.getEnergyCheck();
    this.getEnergyTrend();
    this.energyTrendData.title=this.showLang('com.stat.energy.analysis')
    this.energyTrendData.series[0].name=this.showLang('com.stat.day.energy')
    this.energyCheckData.title=this.showLang('com.stat.q.YOY')
  },
  methods: {
    getEnergyTotal: function () {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      let urlType = 'analyse';
      if (this.appType == 'light') {
        urlType = 'light';
      }
      this.$axios.post(`//${this.domains.trans}/station/${urlType}/EnergyTotal`, { groupId, stationId }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'totalData', res.data);
        }
      });
    },
    getEnergyCheck: function () {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      let urlType = 'analyse';
      if (this.appType == 'light') {
        urlType = 'light';
      }
      this.$axios.post(`//${this.domains.trans}/station/${urlType}/EnergyCheck`, { groupId, stationId }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          // this.$set(this, 'totalData', res.data);
          let x = [], y1 = [], y2 = [];
          for (let d of res.data.list1) {
            x.push(d.date);
            y1.push(d.val);
          }
          for (let d of res.data.list2) {
            y2.push(d.val);
          }
          this.$set(this.energyCheckData, 'x', x);
          this.$set(this.energyCheckData.series[0], 'name', res.data.year1+this.showLang('com.date.year'));
          this.$set(this.energyCheckData.series[1], 'name', res.data.year2+this.showLang('com.date.year'));
          this.$set(this.energyCheckData.series[0], 'data', y1);
          this.$set(this.energyCheckData.series[1], 'data', y2);
          this.energyCheckTimer++;
        }
      });
    },
    selectType: function (type) {
      this.energyTrendType = type;
      this.getEnergyTrend();
    },
    energyTrendClick: function (params) {
      // console.log('energy chart click', params)
      this.getEnergyDetail(params.name);
    },
    getEnergyTrend: function () {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      let now = new Date();
      let start, end;
      if (this.energyTrendType == 'year') {
        start = `${now.getFullYear() - 5}-01-01`;
        end = `${now.getFullYear() + 1}-01-01`;
      } else if (this.energyTrendType == 'month') {
        end = `${now.getFullYear() + 1}-01-01`;
        now.setMonth(now.getMonth() - 12);
        start = `${now.getFullYear()}-${now.getMonth() + 1}-01`;
      } else if (this.energyTrendType == 'date') {
        end = `${now.getFullYear() + 1}-01-01`;
        now.setMonth(now.getMonth() - 12);
        start = `${now.getFullYear()}-${now.getMonth() + 1}-01`;
      }
      let args = {
        type: this.energyTrendType,
        start: start,
        end: end,
        groupId,
        stationId,
      }
      let urlType = 'analyse';
      if (this.appType == 'light') {
        urlType = 'light';
      }
      this.$axios.post(`//${this.domains.trans}/station/${urlType}/EnergyTrend`, args).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          // this.$set(this, 'totalData', res.data);
          let x = [], y = [];
          for (let d of res.data) {
            x.push(d.date);
            y.push(d.val);
          }
          this.$set(this.energyTrendData, 'x', x);
          this.$set(this.energyTrendData.series[0], 'data', y);
          this.energyTrendTimer++;
          if (res.data.length > 0) {
            let dv = res.data[res.data.length - 1];
            this.getEnergyDetail(dv.date);
          }
        }
      });
    },
    getEnergyDetail: function (date) {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      let args = {
        type: this.energyTrendType,
        date: date,
        groupId,
        stationId,
      }
      let urlType = 'analyse';
      if (this.appType == 'light') {
        urlType = 'light';
      }
      this.$axios.post(`//${this.domains.trans}/station/${urlType}/EnergyDetail`, args).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'energyDetail', res.data);
        }
      });
    },
    exportData() {
      let tab = [];
      this.tabData.map(p => {
        tab.push({ time: new Date(p.time).format('yyyy-MM-dd'), value: p.value });
      })
      exportXlsx(tab, {
        time:this.showLang('com.date'),
        value: this.showLang('com.stat.q.val'),
      }, this.showLang('com.stat.energy.derived.record'));
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 10;
    },
  }
}
</script>
<style scoped>
.trend-types {
  /* border: solid 1px red; */
  position: absolute;
  top: 5px;
  right: 5px;
  /* width: 100px; */
  height: 30px;
  display: flex;
  z-index: 30;
}
.trend-types-item {
  border: solid 1px rgba(155, 150, 150, 0.527);
  padding: 0 10px;
  line-height: 25px;
  cursor: pointer;
}
.trend-types-item-select {
  background-color: blue;
  color: wheat;
}
.energy-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.bgColor1 {
  background-color: #fc58b1;
}
.bgColor2 {
  background-color: #02cb9e;
}
.bgColor3 {
  background-color: #a468fe;
}
.bgColor4 {
  background-color: #5a78fc;
}
.bgColor5 {
  background-color: #49b0fd;
}
.energy-header {
  height: 64px;
  margin: 3px;
  flex: none;
  display: flex;
  justify-content: space-around;
}
.energy-header-item {
  text-align: center;
  flex: none;
  width: 257px;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 4px 15px 1px rgba(31, 108, 223, 0.1);
  border-radius: 8px;
  line-height: 64px;
  display: flex;
  justify-content: flex-start;
  padding: 16px 0 16px 20px;
}
.energy-header-icon {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  line-height: 32px;
}
.energy-header-icon img {
  vertical-align: middle;
}
.energy-header-title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.energy-header-valve {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.energy-header-con {
  height: 32px;
  line-height: 32px;
  display: flex;
  justify-content: space-around;
  width: 200px;
}
.energy-check {
  height: 250px;
  margin: 3px;
  flex: none;
  display: flex;
  /* position: relative; */
}
.energy-trend {
  height: 250px;
  margin: 3px;
  flex: none;
  display: flex;
  position: relative;
}
.energy-content {
  height: 600px;
  margin: 3px;
  flex: none;
  display: flex;
}
</style>
