<template>
  <div class="station-alarm-container">
    <div class="alarm-data-query">
      <template v-for="(cmd, idx) in lightCmds">
        <Button v-if="idx < cmdcnt" :key="idx" type="primary" size="default" style="margin-right: 5px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
      </template>
      <Dropdown style="margin-right: 10px" v-if="lightCmds.length > cmdcnt">
        <Button type="primary">
          {{showLang('com.op.other')}}<Icon type="ios-arrow-down"></Icon>
        </Button>
        <div slot="list" class="btns-flex" style="width: 650px">
          <template v-for="(item, idx) in layoutData">
            <div :key="idx" class="layoutDataBox">
              <p class="layoutData-title"><i class="layoutData-drop"></i>{{item}}</p>
              <div class="layout-Box">
                <span v-for="(cmd,ind) in moreCmds" :key="ind">
                  <Button v-if="cmd.group == item" :key="`${cmd.code}`+ind" type="primary" size="default" style="margin-right: 5px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
                </span>
              </div>
            </div>
            <!-- <AuthButton :key="idx"  size="default" style="margin: 5px" @click="clickCmd(cmd)">{{showLang(cmd.lang)}}</AuthButton> -->
          </template>
          <!-- -->
        </div>
      </Dropdown>
      <Button opCode='sc' type="success" size="default" style="margin-right: 5px"
        @click="sendFailed">{{showLang('com.but.failed.resend')}}</Button>
      <Button opCode='sc' type="success" size="default" style="margin-right: 5px"
        @click="exportResult">{{showLang('com.op.export.results')}}</Button>
      <Checkbox v-model="clearOldLog">{{showLang('com.lamp.is.ins')}}</Checkbox>
      <!-- <Checkbox v-model="showExtra">{{showLang('com.lamp.show.attribute')}}</Checkbox> -->
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'LightControlMenuCommon',
  components: {
  },
  props: {
  },
  data() {
    return {
      clearOldLog: this.clearOldLogs,
      showArgModal: false,
      showExtra: false,
      cmdItem: { item: {}, other: {}, args: {} },
      cmdcnt: 8,
      layoutData: [],
      lightCmds: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('auth', ['config']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs']),
    ...mapState('common', ['deviceTypes', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    moreCmds: function(){
      return this.lightCmds.slice(this.cmdcnt)
    },
  },
  watch: {
    clearOldLog() {
      this.$store.commit('cmd/setOldLogStatus', this.clearOldLog)
    },
    showExtra() {
      this.$emit('cmd', { code: 'showExtra', args: { show: this.showExtra } })
    }
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    this.getLightCommands();
  },
  destroyed: function () {
  },
  methods: {
    getLightCommands: function(){
      this.$axios.post(`//${this.domains.trans}/station/command/QueryLampCmds`, {}).then(res => {
        if(res.code != 0)return;
        let groups = [];
        let cmds = [];
        res.data.map(p => {
          if(this.config.lightShowCommands.includes(p.code)){
            cmds.push(p);
          }
        })
        let more = cmds.slice(this.cmdcnt);
        more.map(p => {
          if(!groups.includes(p.group)){
            groups.push(p.group);
          }
        })
        // more = cmds.slice(this.cmdcnt);
        this.$set(this, 'lightCmds', cmds);
        this.$set(this, 'layoutData', groups);
      });
    },
    exportResult: function () {
      this.$emit('cmd', { code: 'export', args: {} })
    },
    sendFailed: function () {
      this.$emit('cmd', { code: 'sendFailed', args: { clearOldLog: this.clearOldLog } })
    },
    getCmdCount: async function () {
      this.$emit('cmd', { code: 'getCmdCount', args: {} });
    },
    clearCmds: async function () {
      this.$emit('cmd', { code: 'clearCmds', args: {} });
    },
    clickCmd: function (item) {
      this.$emit('cmd', { code: 'clickCmd', args: { item } });
    },
  }
}
</script>
<style scoped>
.transfer-flex {
  width: 50%;
}
.btns-flex {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 35px;
  height: 500px;
  overflow-y: auto;
}
.station-alarm-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.alarm-data-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.alarm-data-area >>> .plTableBox .el-table tr .umy-table-beyond {
  white-space: break-spaces !important;
  color: #324252;
}
.layoutData-drop {
  width: 6px;
  height: 6px;
  background: #9bacbb;
  border-radius: 50%;
  position: absolute;
  left: -15px;
  top: 50%;
  margin-top: -3px;
}
.layoutData-title {
  margin: 10px 0 5px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #021629;
  position: relative;
  font-weight: bold;
}
.layoutDataBox {
  display: flex;
  flex-direction: column;
}
/* .layout-Box {
  display: flex;
  flex-direction: initial;
} */
.layout-Box span {
  display: block;
  float: left;
  margin-bottom: 5px;
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>