<template>
  <Modal v-model="showModal" :mask-closable="false" width="1060px">
    <div slot="header">组织 【{{item.name}}】 项目 【{{item.appName}}】 选择成员</div>
    <div class="query-data-area">
      用户名：
      <Input v-model="filter.code" placeholder="请输入用户名" clearable style="width: 200px; margin-right: 10px;"></Input>
      姓名：
      <Input v-model="filter.name" placeholder="请输入姓名" clearable style="width: 200px; margin-right: 10px;"></Input>
      手机号：
      <Input v-model="filter.mobile" placeholder="请输入手机号" clearable style="width: 200px; margin-right: 10px;"></Input>
      <Button type="primary" @click="search">查询</Button>
    </div>
    <div class="edit-area">
      <vxe-table ref="chkTable" resizable border show-overflow row-key :row-config="{isHover: true}" :data="list" :scroll-y="{enabled: false}" height="500">
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="stats" title="状态" width="70" header-align="center">
          <template #default="{ row }">
            {{getUserState(row)}}
          </template>
        </vxe-column>
        <vxe-column field="code" title="登录名" width="200"></vxe-column>
        <vxe-column field="name" title="姓名" width="200"></vxe-column>
        <vxe-column field="mobile" title="手机号" width="150"></vxe-column>
        <vxe-column field="createTime" title="注册时间" width="180"></vxe-column>
        <vxe-column field="custCount" title="参管项目数" width="90" header-align="center"></vxe-column>
        <vxe-column field="weiXinCount" title="注册微信" width="80" header-align="center">
          <template #default="{ row }">
            {{row.weiXinCount > 0 ? '已注册' : '未注册'}}
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div slot="footer" class="select-footer">
      <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
      <div>
        <Button style="margin-right: 8px" @click="cancel">关闭</Button>
        <Button type="primary" style="margin-right: 8px" @click="ok">加入项目</Button>
      </div>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalUserList',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      editItem: {},
      showEditModal: false,
      loading: false,
      isAdd: false,
      list: [],
      total: 0,
      filterName: '',
      filter: {
        custId: 0,
        code: '',
        name: '',
        mobile: '',
        index: 1,
        size: 20,
      },
      userChanged: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('auth', ['token', 'appType', 'isNewMode']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.search();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  mounted: function () {
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    search: function(){
      this.filter.index = 1;
      this.getList();
    },
    getUserState: function (params) {
      if (!params.active) {
        return '已停用';
      }
      if(params.locked){
        return '已锁定';
      }
      if (params.needExpire) {
        let expire = new Date(params.expire);
        expire.setDate(expire.getDate() + 1);
        if (new Date().getTime() > expire.getTime()) {
          return '已过期';
        }
      }
      return '正常';
    },
    getList: function () {
      this.filter.custId = this.item.id;
      this.filter.appType = this.appType;
      this.filter.isNewMode = this.isNewMode;
      this.$axios.post(`site/customer/QueryCustWaitUser`, this.filter).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'total', res.data.count);
        }
      });
    },
    ok: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要加入项目的用户');
        return;
      }
      let list = chks.map(p => p.id);
      this.$axios.post(`site/customer/AddUserToCust`, {userIds: list, custId: this.item.id}).then(res => {
        if (res.code == 0) {
          this.userChanged = true;
          this.getList();
        }
      });
    },
    cancel() {
      this.showModal = false;
      if(this.userChanged){
        this.$emit('saved');
      }
    },
  }
}
</script>
<style scoped>
.select-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.query-data-area {
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  /* border: solid 1px rgba(143, 138, 138, 0.452); */
  margin-bottom: 5px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.edit-area {
  /* border: solid 1px blue; */
  width: 100%;
  /* height: 600px; */
  display: flex;
}
.form-area {
  width: 410px;
  flex: none;
}
.fun-area {
  width: 400px;
  flex: auto;
  margin-left: 20px;
  border: solid 1px rgba(216, 199, 199, 0.438);
  border-radius: 6px;
  overflow: auto;
  position: relative;
}
.chk-all {
  position: absolute;
  top: 10px;
  right: 10px;
}
.logo-upload {
  width: 410px;
  height: 114px;
  border: solid 1px gray;
  cursor: pointer;
}
.logo-upload input {
  visibility: hidden;
}
.logo-upload img {
  width: 410px;
  height: 114px;
}
.fun-list {
  display: flex;
  /* list-style: none; */
  flex-direction: column;
  margin: 8px 15px;
}
.chk-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
li {
  margin: 0 5px;
}
</style>