<template>
  <div class="tree-node">
    <div :class="['node-item', selectedNode.id == node.id ? 'node-selected':'']" @click="nodeClick">
      <div :class="['node-item-flag', selectedNode.id == node.id ? 'node-selected-flag':'']"></div>
      <div :style="{width: `${(this.curPath.length - 1) * 20}px`, height: '44px', flex: 'none'}"></div>
      <img class="node-img" :src="getImgType()">
      <span class="node-text" :title="node.name">{{node.name}}</span>
      <img v-if="node.children && node.children.length > 0" class="expand-img" :src="node.expand ? img.treeCollectImg : img.treeExpandImg">
    </div>
    <div v-if="node.expand" class="tree-nodes">
      <template v-if="node.children">
        <MyTreeNode v-for="(n, idx) in node.children" :path="curPath" :level="idx" :key="n.id" :node="n" />
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MyTreeNode from './MyTreeNode'
  export default {
    name: 'MyTreeNode',
    components:{
      MyTreeNode,
    },
    props: {
      node: {
        type: Object,
        default(){ return {};}
      },
      path: {
        type: Array,
        default(){ return [];}
      },
      level: {
        type: Number,
        default: 0,
      },
    },
    data () {
      return {
        curPath: [...this.path, this.level],
      }
    },
    computed: {
      ...mapState('group', ['selectedNode'])
    },
    mounted: function(){
    },
    methods: {
      getImgType: function(){
        switch (this.node.type) {
          case 'root': return this.img.tree.root.common;
          case 'group':{
            if(this.selectedNode.id == this.node.id){
              return this.img.tree.group.select;
            }else{
              return this.img.tree.group.common;
            }
          }
          default:{
            if(!this.node.online){
              return this.img.powerbox.status.offline;
            }else if(this.node.alarm){
              return this.img.powerbox.status.alarm;
            }else{
              return this.img.powerbox.status.online;
            }
          }
        }
      },
      nodeClick: function(){
        if(this.selectedNode.id != this.node.id){
          this.$store.commit('group/selectNode', this.node);
          if(!this.node.expand)this.node.expand = true;
        }else{
          this.node.expand = !this.node.expand;
        }
      },
    }
  }
</script>
<style scoped>
  .tree-node{
    width: 100%;
  }
  .node-item{
    height: 48px;
    display: flex;
    background-color: #041B32;
  }
  .node-item-flag{
    width: 4px;
    background-color: transparent;
  }
  .node-text{
    margin-left: 5px;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    user-select:none;
    flex: auto;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .node-img{
    width: 15px;
    height: 15px;
    margin: 15px 0 0 15px;
    flex: none;
  }
  .expand-img{
    width: 12px;
    height: 8px;
    margin: 15px 15px 0 0;
    flex: none;
  }
  .node-selected{
    background: #02203C;
  }
  .node-selected-flag{
    background-color: #0CD3FC;
  }
</style>