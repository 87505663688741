<template>
<div class="alarm-rule-container">
  <div class="query-data-area">
    <!-- <AuthButton opCode='lge' size="default" v-for="(btn, idx) in btns" :key="idx" type="primary" @click="docmd(btn)" style="margin: 0 5px;">{{btn.name}}</AuthButton> -->
    <AuthButton opCode='lpd2' size="default" type="error" @click="deleteParams" style="margin: 0 5px;">批量删除</AuthButton>
    <Button size="default" type="success" @click="handleExport" style="margin: 0 5px;">导出所选灯杆信息</Button>
  </div>
  <div class="alarm-data-area" ref="table1">
    <u-table ref="chkTable" :data="list" style="width: 100%;" use-virtual row-key="id" border stripe :height="tabHeight" default-expand-all>
      <u-table-column type="index" header-align="center" fixed="left"></u-table-column>
      <u-table-column type="selection" header-align="center" fixed="left"></u-table-column>
      <u-table-column prop="name" label="名称" header-align="center" fixed="left">
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <u-table-column prop="code" label="灯杆码" width="280" header-align="center">
        <template slot-scope="params">
          {{params.row.code}}
        </template>
      </u-table-column>
      <u-table-column prop="stationName" label="所在分组" sortable width="350" header-align="center">
        <template slot-scope="params">
          {{params.row.groupName}}
        </template>
      </u-table-column>
      <u-table-column prop="type" label="灯杆类型" sortable width="150" header-align="center">
        <template slot-scope="params">
          {{params.row.asset.poleType}}
        </template>
      </u-table-column>
      <u-table-column prop="armType" label="灯臂类型" sortable width="150" header-align="center">
        <template slot-scope="params">
          {{params.row.asset.armType}}
        </template>
      </u-table-column>
      <u-table-column prop="height" label="高度" sortable width="80" header-align="center">
        <template slot-scope="params">
          {{params.row.asset.height}}
        </template>
      </u-table-column>
      <u-table-column width="200">
        <template slot="header">
          <AuthButton opCode='lpa2' style="margin-right: 5px" @click="fastAdd">快捷录入</AuthButton>
          <AuthButton opCode='lpa2' style="margin-right: 5px" @click="handleAdd">新增</AuthButton>
          <AuthButton opCode='lpv2' style="margin-right: 5px" @click="getList">刷新</AuthButton>
        </template>
        <template slot-scope="scope">
          <AuthButton opCode='lpe2' style="margin-right: 5px" @click="handleEdit(scope)">编辑</AuthButton>
          <AuthButton opCode='lpd2' type="error" @click="handleDelete(scope)">删除</AuthButton>
        </template>
      </u-table-column>
    </u-table>
    <ModalPoleEdit v-model="showEditModal" :item="editItem" :groups="selectedGroups" @saved="itemSaved" />
    <ModalBatchAdd v-model="showBatchModal" :item="editItem" :groups="selectedGroups" @saved="itemSaved" />
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import ModalPoleEdit from './ModalPoleEdit'
import ModalBatchAdd from './ModalBatchAdd'
export default {
  name: 'ConfigLampPoleIndex',
  components:{
    ModalPoleEdit,
    ModalBatchAdd,
  },
  props: {
  },
  data () {
    return {
      curTab: 'hour24',
      list: [],
      tabHeight: 600,
      editItem: {isAdd: true, data: {}},
      showEditModal: false,
      showBatchModal: false,
      btns: [
        {code: 'updatePowerRated', name: '设定额定功率'},
        {code: 'updateCurrentRated', name: '设定额定电流'},
        {code: 'updateCurrentRate', name: '设定电流倍率'},
        {code: 'updateLeakageRate', name: '设定漏电阈值'},
        {code: 'updateSteelRate', name: '设定防盗阈值'},
      ]
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes']),
    ...mapState('group', ['groups', 'selectedDevices', 'selectedNode', 'selectedGroups']),
    selectedStations: function(){
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function(){
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => {return p.id});
      return pids;
    },
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    selectedNode(){
      this.getList();
    }
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 100);
    this.getList();
  },
  destroyed: function(){
  },
  methods: {
    handleExport: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if(chks.length == 0){
        this.$Message.warning('请选择要导出的记录');
        return;
      }
      let list = chks.map(p => p.id);
      this.$axios.request({
        url: `//${this.domains.trans}/station/light/ExportLampPole`,
        method: 'post',
        data: {url: `${window.location.protocol}//${window.location.host}/alarm/upload`, ids: list},
        responseType: 'blob'
      }).then(res => {
        let url = window.URL.createObjectURL(res);
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `普通灯杆信息.xlsx`
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url)
      })
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table1.clientHeight - 10;
    },
    fastAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showBatchModal = true;
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    itemSaved: function(){
      this.getList();
    },
    deleteParams: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if(chks.length == 0){
        this.$Message.warning('请选择要删除的记录');
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除已选的[${list.length}]条记录吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/light/DeleteLampPole`, {list: list}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除[${params.row.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/light/DeleteLampPole`, {list: [params.row.id]}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getList();
            }
          });
        }
      });
    },
    getList: function(){
      // console.log('windowOnResize', this.selectedStationIds.join(','))
      let groupId = this.selectedNode.id;
      this.$axios.post(`//${this.domains.trans}/station/light/QueryLampPole`, {groupId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'list', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-rule-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area{
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  border: solid 1px rgba(143, 138, 138, 0.452);
  margin-bottom: 5px;
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  padding-left: 10px;
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>