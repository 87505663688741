<template>
<Form ref="form" :model="form" :label-width="120" style="margin-top: 30px; width: 300px">
  <FormItem prop="deviceStatusStyle" label="设备状态页风格">
    <Select v-model="form.style">
      <Option :value="1">地图</Option>
      <Option :value="2">列表</Option>
      <Option :value="3">区域分布</Option>
    </Select>
  </FormItem>
  <FormItem prop="expired" :label-width="0">
    <AuthButton opCode='sdpe' size="default" long type="primary" style="margin-right: 5px" @click="saveSetting">保存</AuthButton>
  </FormItem>
</Form>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'DevicePage',
  components:{
  },
  props: {
  },
  data () {
    return {
      tabHeight: 600,
      loading: false,
      form: {
        style: 1,
      },
      previewUrl: '',
      previewFile: null,
      maps: [],
      showGroupModal: false,
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['alarmLevels', 'productCodes', 'alarmTypes', 'alarmGroupList']),
    hasBase: function(){
      return this.maps.filter(p => p.isBase).length > 0
    }
  },
  watch: {
    windowOnResize(){
    },
  },
  mounted: function(){
    this.$store.dispatch('group/getGroupAndStations', -1)
    this.previewUrl = '';
    this.getList();
  },
  destroyed: function(){
  },
  methods: {
    deviceStatusStyleChanged: function(){
      // if(this.form.homeCenterStype == 3){
      //   this.getMapFiles();
      // }
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/sys/setting/QueryDevicePageStyle`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          if(!res.data.style){
            res.data.style = 1;
          }
          this.$set(this, 'form', res.data);
        }
      });
    },
    saveSetting: function(){
      // let formData = new FormData();
      // formData.append("deviceStatusStyle", this.form.homeCenterStype);
      // if(this.form.homeCenterStype == 2){
      //   if(this.previewFile == null){
      //     this.$Message.warning("请选择文件");
      //     return;
      //   }
      //   formData.append("file", this.previewFile);
      // }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/sys/setting/SaveDevicePageStyle`, this.form).then(res => {
      this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功')
        }
      });
    },
  }
}
</script>
<style scoped>
.file-input-area{
  width: 0;
  height: 0;
}
.preview-image-area{
  width: 800px;
}
.chk-item{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin-bottom: 5px;
}
.alarm-type{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin: 5px;
  padding: 5px;
}
.alarm-type-name{
  padding: 5px 0 1px 0;
  border-bottom: solid 1px rgba(219, 175, 175, 0.568);
  margin-bottom: 10px;
}
.alarm-level-container{
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* align-content: stretch; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-level-item{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 3px;
  width: 300px;
  flex: auto;
  margin: 5px;
  display: flex;
  flex-direction: column;
}
.level-item-header{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 10px;
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.level-item-content{
  border: solid 1px rgba(219, 175, 175, 0.568);
  height: 400px;
  flex: auto;
}
.header-title{
  /* border: solid 1px red; */
  width: 100px;
  flex: auto;
  font-weight: bolder;
  font-size: 20px;
}
.header-btn{
  /* margin: 10px; */
  flex: none;
  /* border: solid 1px red; */
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>