<template>
  <div class="alarm-rule-container">
    <div class="query-data-area">
      <AuthButton opCode='la' size="default" type="primary" @click="importLampNew" style="margin: 0 5px;">批量导入</AuthButton>
      <!-- <AuthButton opCode='la' size="default" type="info" @click="importLampOld" style="margin: 0 5px;">旧版导入</AuthButton> -->
      <AuthButton opCode='ld' size="default" type="error" @click="deleteParams" style="margin: 0 5px;">批量删除</AuthButton>
      <AuthButton opCode='lv' size="default" type="primary" @click="exportLamp" style="margin: 0 5px;">批量导出</AuthButton>
      <input ref="fileInput" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
      <AuthButton opCode='le' size="default" type="primary" @click="importLampData" style="margin: 0 5px;">导入编辑数据</AuthButton>
    </div>
    <div class="alarm-data-area" ref="table1">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="list" :height="tabHeight" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',highlight: true}">
          <vxe-column width="90" type="checkbox" field="checkbox" fixed="left" title="全选"></vxe-column>
          <vxe-column field="name" title="名称" width="150" header-align="center" fixed="left">
            <template #default="{ row }">
              {{row.name}}
            </template>
          </vxe-column>
          <vxe-column field="stationName" title="所在分区"  width="250" header-align="center">
            <template #default="{ row }">
              {{row.groupName}}
            </template>
          </vxe-column>
          <vxe-column field="poleName" title="所在灯杆"  width="150" header-align="center">
            <template #default="{ row }">
              {{row.poleName}}
            </template>
          </vxe-column>
          <vxe-column field="areaName" title="所在分组"  width="150" header-align="center">
            <template #default="{ row }">
              {{row.areaName}}
            </template>
          </vxe-column>
          <vxe-column field="code" title="通信ID"  width="150" header-align="center">
            <template #default="{ row }">
              {{row.code}}
            </template>
          </vxe-column>
          <vxe-column field="deviceTypeName" title="设备类型"  width="250" header-align="center">
            <template #default="{ row }">
              {{row.deviceTypeName}}
            </template>
          </vxe-column>
          <vxe-colgroup title="控制方式" header-align="center">
            <vxe-column field="mode" title="模式" header-align="center"  width="90">
              <template #default="{ row }">
                {{controlModes[row.content.mode]}}
              </template>
            </vxe-column>
            <vxe-column field="timeName1" title="计时日表" header-align="center"  width="90">
              <template #default="{ row }">
                {{row.timeName1}}
              </template>
            </vxe-column>
            <vxe-column field="timeName2" title="准时日表" header-align="center"  width="90">
              <template #default="{ row }">
                {{row.timeName2}}
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup title="电压监测" header-align="center">
            <vxe-column field="ur" title="额定值" header-align="center"  width="90">
              <template #default="{ row }">
                {{row.content.ur}} V
              </template>
            </vxe-column>
            <vxe-column field="uh" title="上限值" header-align="center"  width="90">
              <template #default="{ row }">
                {{row.content.uh}} %
              </template>
            </vxe-column>
            <vxe-column field="ul" title="下限值" header-align="center"  width="90">
              <template #default="{ row }">
                {{row.content.ul}} %
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup title="一路控制通道" header-align="center">
            <vxe-column field="en1" title="启用" header-align="center"  width="60">
              <template #default="{ row }">
                {{row.content.en1 ? '启用' : '禁用'}}
              </template>
            </vxe-column>
            <vxe-column field="nm1" title="名称" header-align="center"  width="60">
              <template #default="{ row }">
                {{row.content.nm1}}
              </template>
            </vxe-column>
            <vxe-column field="pr1" title="额定功率" header-align="center"  width="100">
              <template #default="{ row }">
                {{row.content.pr1}} W
              </template>
            </vxe-column>
            <vxe-column field="lc1" title="灯具数" header-align="center"  width="100">
              <template #default="{ row }">
                {{row.content.lc1}}
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup title="二路控制通道" header-align="center">
            <vxe-column field="en2" title="启用" header-align="center" width="60">
              <template #default="{ row }">
                {{row.content.en2 ? '启用' : '禁用'}}
              </template>
            </vxe-column>
            <vxe-column field="nm2" title="名称" header-align="center" width="60">
              <template #default="{ row }">
                {{row.content.nm2}}
              </template>
            </vxe-column>
            <vxe-column field="pr2" title="额定功率" header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.pr2}} W
              </template>
            </vxe-column>
            <vxe-column field="lc2" title="灯具数" header-align="center"  width="100">
              <template #default="{ row }">
                {{row.content.lc2}}
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup title="三路控制通道" header-align="center">
            <vxe-column field="en3" title="启用" header-align="center"  width="60">
              <template #default="{ row }">
                {{row.content.en3 ? '启用' : '禁用'}}
              </template>
            </vxe-column>
            <vxe-column field="nm3" title="名称" header-align="center" width="60">
              <template #default="{ row }">
                {{row.content.nm3}}
              </template>
            </vxe-column>
            <vxe-column field="pr3" title="额定功率" header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.pr3}} W
              </template>
            </vxe-column>
            <vxe-column field="lc3" title="灯具数" header-align="center"  width="100">
              <template #default="{ row }">
                {{row.content.lc3}}
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup title="四路控制通道" header-align="center">
            <vxe-column field="en4" title="启用" header-align="center"  width="60">
              <template #default="{ row }">
                {{row.content.en4 ? '启用' : '禁用'}}
              </template>
            </vxe-column>
            <vxe-column field="nm4" title="名称" header-align="center"  width="60">
              <template #default="{ row }">
                {{row.content.nm4}}
              </template>
            </vxe-column>
            <vxe-column field="pr4" title="额定功率" header-align="center"  width="100">
              <template #default="{ row }">
                {{row.content.pr4}} W
              </template>
            </vxe-column>
            <vxe-column field="lc4" title="灯具数" header-align="center"  width="100">
              <template #default="{ row }">
                {{row.content.lc4}}
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup title="漏电阈值" header-align="center">
            <vxe-column field="ll" title="一级"  header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.ll}} mA
              </template>
            </vxe-column>
            <vxe-column field="lh" title="二级"  header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.lh}} mA
              </template>
            </vxe-column>
            <vxe-column field="lx" title="三级"  header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.lx || '-'}} mA
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup title="水浸监测通道" header-align="center">
            <vxe-column field="l1" title="一级"  header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.l1}}
              </template>
            </vxe-column>
            <vxe-column field="l2" title="二级"  header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.l2}}
              </template>
            </vxe-column>
            <vxe-column field="l3" title="三级"  header-align="center" width="100">
              <template #default="{ row }">
                {{row.content.l3}}
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-column width="120" fixed="right">
            <template slot="header">
              <AuthButton opCode='la' style="margin-right: 5px" @click="handleAdd">新增</AuthButton>
              <AuthButton opCode='lv' style="margin-right: 5px" @click="getList">刷新</AuthButton>
            </template>
            <template #default="{ row }">
              <AuthButton opCode='le' style="margin-right: 5px" @click="handleEdit(row)">编辑</AuthButton>
              <AuthButton opCode='ld' type="error" @click="handleDelete(row)">删除</AuthButton>
            </template>
          </vxe-column>

      </vxe-table>
      <ModalLampEdit v-model="showEditModal" :item="editItem" :areas="areas" :gates="gates" :groups="selectedGroups" @saved="itemSaved" />
      <ModalLampImportNew v-model="showImportModalNew" :item="editItem" :areas="areas" :gates="gates" :groups="selectedGroups" @saved="itemSaved" />
      <!-- <ModalLampImportOld v-model="showImportModalOld" :item="editItem" :stations="selectedStations" @saved="itemSaved" /> -->
      <!-- <ModalArgEdit v-model="showArgModal" /> -->
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ModalLampEdit from './ModalLampEdit'
import ModalLampImportNew from './ModalLampImportNew'
// import ModalLampImportOld from './ModalLampImportOld'
// import ModalArgEdit from './ModalArgEdit'
export default {
  name: 'ConfigLampIndex',
  components: {
    ModalLampEdit,
    ModalLampImportNew,
    // ModalLampImportOld,
    // ModalArgEdit,
  },
  props: {
  },
  data() {
    return {
      curTab: 'hour24',
      list: [],
      tabHeight: 600,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showArgModal: false,
      showImportModalNew: false,
      showImportModalOld: false,
      dataRefresh: 0,
      areas: [],
      gates: [],
      btns: [
        { code: 'updatePowerRated', name: '设定额定功率' },
        { code: 'updateCurrentRated', name: '设定额定电流' },
        { code: 'updateCurrentRate', name: '设定电流倍率' },
        { code: 'updateLeakageRate', name: '设定漏电阈值' },
        { code: 'updateSteelRate', name: '设定防盗阈值' },
      ]
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'controlModes']),
    ...mapState('group', ['selectedDevices', 'selectedNode', 'selectedGroups']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.getList();
    }
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    this.queryAreas();
    this.queryGates();
    this.getList();
  },
  destroyed: function () {
  },
  methods: {
    importLampData: function(){
      if(this.$refs.fileInput.files.length == 0){
        this.$Message.warning('请选择要导入的文件');
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.fileInput.files[0]);
      this.$axios.request({
        url: `//${this.domains.trans}/station/light/ImportLightData`,
        method: 'post',
        data: formData,
        responseType: 'json',
        myType: 'file'
      }).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          if(res.data.length > 0){
            this.$Modal.info({
              title: "部分导入成功",
              content: res.data.join('<br/>')
            });
          }else{
            this.$Modal.info({
              title: "导入结果",
              content: "全部导入成功"
            });
          }
        }
      })
    },
    exportLamp: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要导出的记录');
        return;
      }
      this.$axios.request({
        url: `//${this.domains.trans}/station/light/ExportLightData`,
        method: 'post',
        data: {list: chks.map(p => p.id)},
        responseType: 'blob'
      }).then(res => {
        let url = window.URL.createObjectURL(res);
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `单灯信息.xlsx`
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url)
      })
    },
    queryAreas: function(){
      this.$axios.post(`//${this.domains.trans}/station/config/QueryArea`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'areas', res.data);
        }
      });
    },
    queryGates: function(){
      this.$axios.post(`//${this.domains.trans}/station/light/QueryGateWay`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'gates', res.data);
        }
      });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 10;
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params };
      this.showEditModal = true;
    },
    itemSaved: function () {
      this.getList();
    },
    deleteParams: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning('请选择要删除的记录');
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除已选的[${list.length}]条记录吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/light/DeleteLight`, { list: list }).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除[${params.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/light/DeleteLight`, { list: [params.id] }).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.getList();
            }
          });
        }
      });
    },
    getList: function () {
      // console.log('windowOnResize', this.selectedStationIds.join(','))
      let groupId = this.selectedNode.id;
      this.$axios.post(`//${this.domains.trans}/station/light/QueryLight`, { groupId }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'list', res.data);
          this.dataRefresh++;
        }
      });
    },
    importLampNew: function () {
      this.item = {};
      this.showImportModalNew = true;
    },
    importLampOld: function () {
      this.$Modal.info({
        title: '功能迁移说明',
        content: '因操作繁琐，旧版导入功能已废弃，非标单灯ID导入方法：在批量导入界面，不要勾选“识别单灯类型”，并且手动指定单灯类型即可',
      })
      // this.item = {};
      // this.showImportModalOld = true;
    },
  }
}
</script>
<style scoped>
.alarm-rule-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area {
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  border: solid 1px rgba(143, 138, 138, 0.452);
  margin-bottom: 5px;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  padding-left: 10px;
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>