<template>
<Form ref="form" :model="form" :label-width="100" style="margin-top: 30px; width: 300px">
  <FormItem prop="homeCenterStype" label="首页风格">
    <Select v-model="form.homeCenterStype" @on-change="homeCenterStypeChanged">
      <Option :value="1">地图</Option>
      <Option :value="2">静态图片</Option>
      <Option :value="3">多层图片</Option>
    </Select>
  </FormItem>
  <FormItem v-if="form.homeCenterStype == 2" prop="homeCenterImage" label="图片内容">
    <div class="form-row-flex">
      <img v-if="previewUrl != ''" :src="previewUrl" class="preview-image-area" />
      <input :class="[previewUrl != '' ? 'file-input-area' : '']" ref="fileInput" type="file" accept=".png,.jpeg,.jpg" @change="fileChanged" />
      <div class="position-level-area" @click="selectFile">
        <div class="position-level-item" v-for="(group, index) in form.homeCenterGroups" :key="index" :style="{top: `${group.y}px`, left: `${group.x}px`}">
          <div class="container-name">{{getGroupName(group.id)}}</div>
          <div class="container-triangle"></div>
        </div>
      </div>
    </div>
  </FormItem>
  <FormItem v-if="form.homeCenterStype == 2" prop="homeCenterGroups" label="分组统计位置">
    <div class="form-row-flex" v-for="(group, index) in form.homeCenterGroups" :key="index">
      {{index + 1}}、
      <Select v-model="group.id" style="width: 200px; flex: none; margin-right: 10px">
        <Option :value="grp.id" v-for="(grp, idx) in groups" :key="idx">{{grp.name}}</Option>
      </Select>
      <Input v-model="group.x" maxlength="100" type="number" style="width: 150px;flex: none; margin-right: 10px">
        <span slot="prepend">X坐标</span>
      </Input>
      <Input v-model="group.y" maxlength="100" type="number" style="width: 150px;flex: none; margin-right: 10px">
        <span slot="prepend">Y坐标</span>
      </Input>
      <Button size="default" type="error" style="margin-right: 5px;flex: none;" @click="delGroupItem(index)">移除</Button>
    </div>
  </FormItem>
  <FormItem v-if="form.homeCenterStype == 3" prop="maps" label="图片列表">
    <u-table :data="maps" :border="false" :height="500" style="width: 800px">
      <template slot="empty">
        没有查询到符合条件的记录
      </template>
      <u-table-column prop="img" label="图片">
        <template slot-scope="scope">
          <div class="listImg">
            <img :src="scope.row.url" alt="" width="400px">
          </div>
        </template>
      </u-table-column>
      <u-table-column prop="groupName" label="分组" width="180">
        <template slot-scope="scope">
          {{scope.row.isBase ? '[底图]' : ''}}{{scope.row.groupName}}
        </template>
      </u-table-column>
      <u-table-column label="操作" width="120">
        <template slot="header">
          <AuthButton opCode='sshe' style="margin-right: 5px" @click="handleAdd">新增</AuthButton>
          <AuthButton opCode='sshe' style="margin-right: 5px" @click="getMapFiles">刷新</AuthButton>
        </template>
        <template slot-scope="scope">
          <AuthButton opCode='sshe' size="default" type="error" style="margin-right: 5px" @click="delImg(scope.row)">删除</AuthButton>
        </template>
      </u-table-column>
    </u-table>
  </FormItem>
  <FormItem :label-width="0">
    <div class="form-row-flex">
      <AuthButton opCode='sshe' size="default" long type="primary" style="margin-right: 5px" @click="saveSetting">保存</AuthButton>
      <AuthButton opCode='sshe' size="default" long type="success" style="margin-right: 5px" @click="addGroupItem">新增分组统计位置</AuthButton>
    </div>
  </FormItem>
  <HomeMapEdit v-model="showGroupModal" :hasBase="hasBase" @saved="getMapFiles" />
</Form>
</template>
<script>
import { mapState } from 'vuex'
import HomeMapEdit from './HomeMapEdit'
export default {
  name: 'AlarmSettingIndex',
  components:{
    HomeMapEdit,
  },
  props: {
  },
  data () {
    return {
      tabHeight: 600,
      loading: false,
      form: {
        homeCenterStype: 1,
        homeCenterImage: '',
        homeCenterGroups: [
          {id: 0, x: 0, y: 0},
        ]
      },
      previewUrl: '',
      previewFile: null,
      maps: [],
      showGroupModal: false,
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('group', ['groups']),
    ...mapState('common', ['alarmLevels', 'productCodes', 'alarmTypes', 'alarmGroupList']),
    hasBase: function(){
      return this.maps.filter(p => p.isBase).length > 0
    }
  },
  watch: {
    windowOnResize(){
    },
  },
  mounted: function(){
    this.$store.dispatch('group/getGroupAndStations', -1)
    this.previewUrl = '';
    this.getList();
  },
  destroyed: function(){
  },
  methods: {
    getGroupName: function(id){
      let grps = this.groups.filter(p => p.id == id);
      if(grps.length == 0)return '';
      return grps[0].name;
    },
    addGroupItem: function(){
      this.form.homeCenterGroups.push({id: 0, x: 0, y: 0});
    },
    delGroupItem: function(idx){
      this.form.homeCenterGroups.splice(idx, 1);
    },
    delImg(el) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除这张图片吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/sys/setting/DeleteHomeMap`, {id: el.id}).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.$Message.info('删除成功');
              this.getMapFiles();
            }
          });
        }
      });

    },
    homeCenterStypeChanged: function(){
      if(this.form.homeCenterStype == 3){
        this.getMapFiles();
      }
    },
    getMapFiles: function(){
      this.$axios.post(`//${this.domains.trans}/sys/setting/QueryHomeMaps`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          for(let m of res.data){
            m.url = `//${this.domains.trans}/station/file/${m.img}`
          }
          this.$set(this, 'maps', res.data);
          // console.log('get sets result', res.data)
        }
      });
    },
    handleAdd: function(){
      this.showGroupModal = true;
    },
    selectFile: function(){
      this.$refs.fileInput.click();
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/sys/setting/QueryHome`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          if(!res.data.homeCenterStype){
            res.data.homeCenterStype = 1;
          }
          if(!res.data.homeCenterImage){
            res.data.homeCenterImage = '';
          }
          if(res.data.homeCenterImage != ''){
            this.previewUrl = `//${this.domains.trans}/station/file/${res.data.homeCenterImage}`
          }
          // for(let m of res.data.maps){
          //   m.url = `//${this.domains.trans}/station/file/${m.img}`
          // }
          this.$set(this, 'form', res.data);
          // console.log('get sets result', res.data)
        }
      });
    },
    saveSetting: function(){
      let formData = new FormData();
      formData.append("homeCenterStype", this.form.homeCenterStype);
      if(this.form.homeCenterStype == 2){
        if(this.form.homeCenterImage == ''){
          if(this.previewFile == null){
            this.$Message.warning("请选择图片文件");
            return;
          }
        }
        if(this.previewFile != null){
          formData.append("file", this.previewFile);
        }
        let groups = [];
        for(let grp of this.form.homeCenterGroups){
          if(grp.id <= 0){
            this.$Message.warning("请选择分区");
            return;
          }
          if(this.form.homeCenterGroups.filter(p => p.id == grp.id).length > 1){
            this.$Message.warning(`重复选择分区:${this.getGroupName(grp.id)}`);
            return;
          }
          if(grp.x <= 0 || grp.x >= 800){
            this.$Message.warning("x坐标必须在 0 - 800 之间");
            return;
          }
          groups.push(`${grp.id},${grp.x},${grp.y}`);
        }
        formData.append("groups", groups.join(';'));
      }
      this.loading = true;
      // this.$axios.post(`//${this.domains.trans}/sys/setting/SaveHome`, formData)
      this.$axios.request({
        url: `//${this.domains.trans}/sys/setting/SaveHome`,
        method: 'post',
        data: formData,
        responseType: 'json',
        myType: 'file'
      }).then(res => {
      this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功');
          this.previewFile = null;
        }
      });
    },
    fileChanged: function(e){
          // console.log('get sets result', e)
      if(e.target.files.length > 0){
        this.previewFile = e.target.files[0];
        this.previewUrl = window.webkitURL.createObjectURL(this.previewFile);
      }
    },
  }
}
</script>
<style scoped>
.position-level-item{
  position: absolute;
  width: 100px;
}
.container-name {
  padding: 0 10px;
  height: 24px;
  background: linear-gradient(135deg, #49e1fc, #49a6fc);
  border-radius: 4px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #011121;
  line-height: 24px;
  text-align: center;
}
.container-triangle {
  width: 0;
  height: 0;
  border-top: 12px solid #49a6fc;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  margin-top: 4px;
}
.position-level-area{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* border: solid 1px blue; */
}
.form-row-flex{
  width: 800px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  /* border: solid 1px red; */
  position: relative;
}
.file-input-area{
  width: 0;
  height: 0;
}
.preview-image-area{
  width: 800px;
}
.chk-item{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin-bottom: 5px;
}
.alarm-type{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin: 5px;
  padding: 5px;
}
.alarm-type-name{
  padding: 5px 0 1px 0;
  border-bottom: solid 1px rgba(219, 175, 175, 0.568);
  margin-bottom: 10px;
}
.alarm-level-container{
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* align-content: stretch; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-level-item{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 3px;
  width: 300px;
  flex: auto;
  margin: 5px;
  display: flex;
  flex-direction: column;
}
.level-item-header{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 10px;
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.level-item-content{
  border: solid 1px rgba(219, 175, 175, 0.568);
  height: 400px;
  flex: auto;
}
.header-title{
  /* border: solid 1px red; */
  width: 100px;
  flex: auto;
  font-weight: bolder;
  font-size: 20px;
}
.header-btn{
  /* margin: 10px; */
  flex: none;
  /* border: solid 1px red; */
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>