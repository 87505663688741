<template>
<Modal v-model="showModal" :mask-closable="false" draggable width="1200px">
  <div slot="header">后台任务查询 - {{tabData.length}}</div>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    <Button type="info" :loading="loading" @click="getList" style="margin-right: 10px;">查询</Button>
    <!-- <Button type="success" @click="exportData">导出</Button> -->
  </div>
  <u-table :data="tabData" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="500" stripe>
    <u-table-column type="index"></u-table-column>
    <u-table-column width="60" label="状态">
      <template slot-scope="scope">
        {{scope.row.enable ? '运行' : '停用'}}
      </template>
    </u-table-column>
    <u-table-column prop="name" label="名称" sortable fit width="250"></u-table-column>
    <u-table-column prop="count" label="执行次数" fit width="80"></u-table-column>
    <u-table-column prop="successCount" label="成功次数" fit width="80"></u-table-column>
    <u-table-column prop="startTime" label="开始时间" sortable fit width="160"></u-table-column>
    <u-table-column prop="endTime" label="结束时间" sortable fit width="160"></u-table-column>
    <u-table-column>
      <template slot-scope="scope">
        <Button size="small" type="success" style="margin-right: 5px" @click="handleInfo(scope)">信息</Button>
        <Button size="small" type="error" @click="handleError(scope)">错误</Button>
      </template>
    </u-table-column>
  </u-table>
  <ModalTaskInfo v-model="showInfoModal" :item="editItem" />
  <ModalTaskError v-model="showErrorModal" :item="editItem" />
  <Spin size="large" fix v-if="loading"></Spin>
</Modal>
</template>
<script>
import { mapState } from 'vuex'
import ModalTaskInfo from './ModalTaskInfo'
import ModalTaskError from './ModalTaskError'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'ModalTaskMonitor',
  components:{
    ModalTaskInfo,
    ModalTaskError,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      showModal: this.value,
      showInfoModal: false,
      showErrorModal: false,
      loading: false,
      curTab: 'group',
      tabHeight:1,
      tabData:[//列表数据
      ],
      tabHead:{ //头部标题
        conn: "客户端",
        code: '注册包',
        ctime: '连接时间',
        ttime: "通信时间",
        devid: '是否关联设备',
        name: '设备名称',
        type: '类型名称',
      },
      editItem: {},
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.getList();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  mounted: function(){
  },
  destroyed: function(){
  },
  methods: {
    handleInfo: function(params){
      this.editItem = {data: params.row};
      this.showInfoModal = true;
    },
    handleError: function(params){
      this.editItem = {data: params.row};
      this.showErrorModal = true;
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table1.clientHeight - 5;
    },
    exportData(){
      if(this.tabData.length == 0){
        this.$Message.warning('当前无数据');
        return;
      }
      let name = '设备在线连接导出';
      exportXlsx(this.tabData, this.tabHead, name);
    },
    getList(){
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/job/QueryJob`, {}).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
          //  console.log(res.data)
        }else{
          // console.log(res.data)
          this.$set(this, 'tabData', res.data);
        }
      });
    },
    cancel: function(){
      this.showModal = false;
    }
  }
}
</script>
<style scoped>
.search-data-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header{
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content{
  height: 100px;
  flex: auto;
  /* border: solid 1px blue; */
}
.search-data-query{
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
  justify-content: flex-end;
}
.search-data-view{
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
</style>