<template>
<div class="organ-container">
  <div class="organ-header">
    <div class="header-tab-item">
      {{showLang('com.date.start')}}：
      <DatePicker :type="filter.type" v-model="filter.start" :format="formats[filter.type]" style="width: 115px;margin-right:10px"></DatePicker>
      {{showLang('com.date.end')}}：
      <DatePicker :type="filter.type" v-model="filter.end" :format="formats[filter.type]" style="width: 115px;margin-right:10px"></DatePicker>
      <Button v-if="funCodes('aeuv')" type="primary" @click="getList" style="margin-right:10px">{{showLang('com.op.query')}}</Button>
      <Button v-if="funCodes('aeue')" type="success" @click="exportData">{{showLang('com.op.export')}}</Button>
    </div>
  </div>
  <div class="organ-content">
    <Spin fix v-if="spin.show">{{spin.content}}</Spin>
    <div class="chart-area">
      <chartsLine :option="chartsLineData" id="chartsLineData" :key="timer"></chartsLine>
    </div>
    <div class="data-area" ref="table">
      <u-table :data="tabData" use-virtual showBodyOverflow="title" showHeaderOverflow="title" :height="tabHeight" :empty-text="showLang('com.data.no')" stripe>
        <u-table-column type="index" :label="showLang('com.export.cn.seq')" width="55"></u-table-column>
        <u-table-column prop="date" :label="showLang('com.date')" sortable fit></u-table-column>
        <u-table-column prop="val" :label="showLang('com.data.average.online.rate')" sortable fit></u-table-column>
      </u-table>
    </div>
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'AnalyseEnergyTrend',
  components: {
  },
  props: {
  },
  data(){
    return {
      spin: {
        show: false,
        content: 'Loading...',
      },
      editModal: false,
      tabHeight:500,
      tabData:[],
      formats: {
        year: 'yyyy',
        month: 'yyyy-MM',
        day: 'yyyy-MM-dd',
      },
      filter: {
        groupId: 0, //分组节点
        stationId: 0, //站点
        type: 'date', // year/month/date
        start: '',
        end: '',
      },
      timer: 0,
      chartsLineData: {
        title: "",
        x: [],
        unit: "%",
        series: [
          {
            name: "平均在线率",
            data: [],
          },
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {}, //下阴影
        },
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('auth', ['appType']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('common', ['productCodes']),
    stationIds: function(){
      return this.selectedDevices.map(p => p.id);
    },
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    selectedNode(){
      this.getList();
    },
  },
  mounted: function(){
    let now = new Date();
    this.filter.end = now.format('yyyy-MM-dd');
    now.setMonth(now.getMonth() - 1);
    this.filter.start = now.format('yyyy-MM-dd');
    setTimeout(this.setTableHeight, 200);
    this.getList();
    //showLang('com.data.average.online.rate')
    this.chartsLineData.series[0].name=this.showLang('com.data.average.online.rate')
  },
  methods: {
    exportData(){
      let tab = [];
      this.tabData.map(p => {
        tab.push({time: p.date, value: p.val});
      })
      exportXlsx(tab, {
        time: this.showLang('com.date'),
        value: this.showLang('com.data.average.online.rate'),
      }, this.showLang('com.data.average.online.rate.export'));
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 10;
    },
    getList: function(){
      let start = new Date(this.filter.start).format('yyyy-MM-dd');
      let end = new Date(this.filter.end).format('yyyy-MM-dd');
      this.spin.show = true;
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        stationId = this.selectedNode.id;
      }else{
        groupId = this.selectedNode.id;
      }
      let args = {
        type: this.filter.type,
        start: start,
        end: end,
        groupId,
        stationId,
      }
      let urlType = 'station/analyse/LightOnline';
      if(this.appType == 'light'){
        urlType = 'station/light/OnlineTrend';
      }
      this.$axios.post(`//${this.domains.trans}/${urlType}`, args).then(res => {
        this.spin.show = false;
        if(res.code == 0){
          this.$set(this, 'tabData', res.data);
          let x = [], y = [];
          for(let d of res.data){
            x.push(d.date);
            y.push(d.val);
          }
          this.$set(this.chartsLineData, 'x', x);
          this.$set(this.chartsLineData.series[0], 'data', y);
          this.timer++;
        }
      });
    },
  }
}
</script>
<style scoped>
.organ-container{
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.organ-header{
  height: 40px;
  margin: 3px;
  flex: none;
}
.chart-area{
  height: 300px;
  flex: none;
}
.data-area{
  height: 500px;
  flex: auto;
}
.organ-content{
  /* border: solid 1px red; */
  margin: 3px;
  height: 500px;
  flex: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
.header-tab-item{
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 40px;
  /* justify-content: flex-end; */
  /* padding: 0 20px; */
}
</style>
