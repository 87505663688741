<template>
<div class="config-device-container">
  <div class="device-data-area" ref="table">
    <u-table :data="devices" ref="plTable" use-virtual :big-data-checkbox="checked" style="width: 100%;" row-key="id" border :height="tabHeight" default-expand-all>
      <u-table-column type="index" label="序号" width="50"></u-table-column>
      <u-table-column type="selection" width="55"></u-table-column>
      <u-table-column prop="name" label="设备名称" sortable width="200"></u-table-column>
        <u-table-column prop="smallType" label="设备小类" sortable width="250">
          <template slot-scope="scope">
            {{getSmallTypeName(scope.row.content.stid)}}
          </template>
        </u-table-column>
      <u-table-column prop="type" label="协议类型" sortable width="250">
        <template slot-scope="scope">
          {{scope.row.deviceTypeName}}
        </template>
      </u-table-column>
      <u-table-column prop="connectTime" label="通信ID" sortable width="160">
        <template slot-scope="scope">
          {{deviceCodeName(scope.row.code)}}
        </template>
      </u-table-column>
      <u-table-column prop="enlux" label="光控监测" sortable width="160">
        <template slot-scope="scope">
          {{!scope.row.content.enlux ? '未启用':`采集间隔时间${scope.row.content.tlux}秒`}}
        </template>
      </u-table-column>
      <u-table-column width="230">
        <template slot="header">
          <Button v-if="funCodes('cgwa')" size="small" type="primary" @click="handleAdd" style="margin-right: 5px">新增</Button>
          <Button v-if="funCodes('cgwv')" size="small" type="primary" @click="getList" style="margin-right: 5px">刷新</Button>
          <Button v-if="funCodes('cgwd')" size="small" type="error" @click="batchDelete()" style="margin-right: 5px">批量删除</Button>
        </template>
        <template slot-scope="scope">
          <Button v-if="funCodes('cgwa')" size="small" type="primary" @click="handleEdit(scope)" style="margin-right: 5px">编辑</Button>
          <Button v-if="funCodes('cgwa')" size="small" type="error" @click="handleDelete(scope)" style="margin-right: 5px">删除</Button>
        </template>
      </u-table-column>
    </u-table>
    <ModalDeviceEdit v-model="editModal" :item="editItem" :accounts="accounts" @saved="getList" />
  </div>
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalDeviceEdit from './ModalDeviceEdit'
export default {
  name: 'ConfigDeviceIndex',
  components: {
    ModalDeviceEdit
  },
  props: {
  },
  data() {
    return {
      tabHeight: 300,
      editModal: false,
      checked: true,
      loading: false,
      editItem: {
        isAdd: true,
        data: {}
      },
      devices: [],
      filter: {
        name: '',
      },
      accounts: [],
    }
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('group', ['groups', 'selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['productCodes', 'deviceTypes']),
    ...mapGetters('common', ['getBigTypeName', 'getSmallTypeName', 'getSmallTypes']),
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 50);
    this.getAccounts();
    this.getList();
    // console.log('station device mounted', this.list)
  },
  methods: {
    deviceCodeName: function(code){
      if(code.indexOf('ZXKL:') == -1)return code;
      let id = code.substr(5);
      let accts = this.accounts.filter(p => p.id == id);
      if(accts.length == 0)return '-';
      return `克拉账号：${accts[0].name}`;
    },
    getAccounts: async function(){
      let res = await this.$axios.post(`//${this.domains.trans}/station/account/GetAccounts`, {type: 4});
      if(res.code !== 0){
        return;
      }
      this.$set(this, "accounts", res.data);
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/station/light/QueryGateWay`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'devices', res.data);
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 3;
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: { } };
      this.editModal = true;
    },
    batchDelete() {
      let chks = this.$refs.plTable.getCheckboxRecords();
      if(chks.length == 0){
        this.$Message.warning('请选择要删除的网关');
        return false;
      }
      let list = [];
      chks.forEach(el => {
        list.push(el.id)
      });
      this.$Modal.confirm({
        title: "提示",
        content: `确定要批量删除设备吗?`,
        onOk: async () => {
          let res = await this.$axios.post(`//${this.domains.trans}/station/light/DeleteGateWay`, { list: list });
          if (res.code == 0) {
            this.getList();
            this.$Message.info(`删除成功`, { timeout: 4000 });
          }
        }
      });
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params.row };
      this.editModal = true;
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除设备 ${params.row.name}[ID:${params.row.code}] 吗?`,
        onOk: async () => {
          let res = await this.$axios.post(`//${this.domains.trans}/station/light/DeleteGateWay`, { list: [params.row.id] });
          if (res.code == 0) {
            this.getList();
            this.$Message.info(`删除成功`, { timeout: 4000 });
          } else {
            // this.$Message.error({
            //   content: `删除失败：${res.data}`,
            //   duration: 4
            // });
          }
        }
      });
    },
  }
}
</script>
<style scoped>
.config-device-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  background-color: white;
  border-radius: 8px;
}
.device-search-area {
  height: 42px;
  flex: none;
  /* border: solid 1px #dcdee2; */
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 0 3px;
  display: flex;
  align-items: center;
}
.device-data-area {
  height: 100%;
  flex: auto;
  border-radius: 6px;
}
</style>