<template>
  <div class="pole-main">
    <div class="amap-float-box" v-if="isSearchShow">
      <div class="float-box-left">
        <Input v-model="deviceSearch" placeholder="请输入单灯ID或名称" style="width:300px;height: 32px;">
        <Button slot="append" icon="ios-search" @click="searchBut()"><span class="left-search">搜索</span></Button>
        </Input>
        <div class="amap-tool-drag">
          <span class="amap-tool-name">设置位置</span>
          <i-switch v-model="enableMoveDevice" size="large" true-color="#1B5FA8" false-color="#6D8194" title="打开可拖动设备以改变设备位置">
            <span slot="open">开启</span>
            <span slot="close">禁止</span>
          </i-switch>
        </div>
      </div>
      <div class="float-box-fixed">
        <p class="float-box-title" :title="selectedNode.name">{{selectedNode.name}}</p>
        <div class="fixed-box-total">
          <span class="total-name">单灯总数</span>
          <span class="total-num">{{allItems.length}}</span>
        </div>
        <div class="fixed-box-list">
          <div class="status-list" v-for="el,index in statusList" :key="index" @click="statusListBut(index)" :class="[el.active?'status-list-bg':'','status-list-name']">
            <div class="status-list-box">
              <div>
                <img :src="el.active?el.imgHover:el.img" alt="">
                <span :class="[el.active?'status-list-active':'','status-list-name']">{{el.name}}</span>
              </div>
              <span :class="[el.active?'status-list-active':'','status-list-num']">{{el.num}}</span>
            </div>
            <div class="status-list-border" v-if="index != 0"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <el-amap-search-box class="search-box" :search-option="{city: '中国',citylimit: true}" :on-search-result="onSearchResult"></el-amap-search-box> -->
    <el-amap vid="amapDemo" ref="map" :zoom="amapInfo.zoom" :events="events" :amap-manager="amapManager" :center="amapInfo.center" class="amap-demo" :viewMode="amapInfo.viewMode" :mapStyle="amapInfo.mapStyle" :pitch="amapInfo.pitch">
      <template v-if="showItems.length>0">
        <el-amap-marker v-for="(el,index) in showItems" :icon="el.imgUrl" :position="el.position" :events="el.events" :key="index">
        </el-amap-marker>
        <el-amap-info-window v-if="poiInfoWindow" isCustom="true" :position="poiInfoWindow.position" :visible="poiInfoWindow.visible" :offset="poiInfoWindow.offset">
          <div class="slot-info-window">
            <ModalMakerInfo :showInfo="showInfo" @cmd="makerCmdClick" />
          </div>
        </el-amap-info-window>
      </template>
    </el-amap>
    <ModalSetDevicePos v-model="moveDevicePos.show" :pos="moveDevicePos" :productList="posItems" @posSaved="posSaved" />
    <ModalControlOutput v-model="showArgModal" :command="cmdItem" @saved="argsSaved" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { AMapManager } from 'vue-amap';
import ModalSetDevicePos from '@/components/map/common/ModalSetDevicePos'
import ModalMakerInfo from '@/components/map/common/ModalMakerInfo'
import ModalControlOutput from '@/views/light/road/monitor/station/base/control/ModalControlOutput'
let amapManager = new AMapManager();
export default {
  name: 'amap12',
  components: {
    ModalMakerInfo,
    ModalControlOutput,
    ModalSetDevicePos,
  },
  props: {
    isSearchShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map: null,
      amapManager,
      deviceSearch: "",
      posItems: [],
      showItems: [],
      allItems: [],
      amapInfo: {
        viewMode: "3D",    //是否为3D地图模式
        zoom: 17.5,           //初始化地图级别
        pitch: 48,//倾斜角度
        center: [114.0582, 22.6305674], //初始化地图中心点位置
        mapStyle: "amap://styles/a746f98de21592484f82bc1eaf0bef66",
      },
      events: {
        click: (el) => {
          this.poiInfoWindow.visible = false;
          this.setUpAddress(el)
        }
      },
      filter: {
        groupId: 0, //所选分组ID
      },
      showInfo: {},
      poiInfoWindow: {
        content: null,
        visible: false,
        offset: [1, 1],
        position: [1, 1],
      },
      moveDevicePos: {
        show: false,
        lat: 0,
        lng: 0,
        groupId: 0,
        stationId: 0
      },
      statusList: [
        {
          name: "报警",
          num: 0,
          active: false,
          index: 0,
          img: this.img.light.carat.gis.LightsOutPolice,
          imgHover: this.img.light.carat.gis.LightsOutPolice,
        }
        , {
          name: "离线",
          num: 0,
          active: false,
          index: 1,
          img: this.img.light.carat.gis.LightsLine,
          imgHover: this.img.light.carat.gis.LightsLine,
        },
        {
          name: "灭灯",
          num: 0,
          active: false,
          index: 2,
          img: this.img.light.carat.gis.LightsOut,
          imgHover: this.img.light.carat.gis.LightsOut,
        },
        {
          name: "亮灯",
          num: 0,
          active: false,
          index: 3,
          img: this.img.light.carat.gis.LightsOn,
          imgHover: this.img.light.carat.gis.LightsOn,
        },
      ],
      statusListIndex: -1,
      enableMoveDevice: false,
      showArgModal: false,
      stationTabName: 'light',
      cmdIds: [],
      cmdItem: {},
    }
  },
  computed: {
    ...mapState('auth', ['user', 'config']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapGetters('auth', ['funCodes']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    cmdResultRefresh() {
      this.cmdResultChanged();
    },
    selectedNode() {
      this.filter.groupId = this.selectedNode.id;
      this.initList(true);
      this.poiInfoWindow.visible = false;
    },
  },
  created: function () {
  },
  mounted: function () {
    this.initList(true);
    this.amapInfo.center = [this.config.lng, this.config.lat];
  },
  methods: {
    makerCmdClick(cmd) {
      if (cmd.infoClose && cmd.infoClose) {
        this.poiInfoWindow.visible = false;
        return;
      }
      if (cmd.cmd.needArgs || cmd.cmd.needPswd) {
        this.$set(this, "cmdItem", cmd.cmd);
        this.showArgModal = true;
      } else {
        let cmds = [];
        cmds.push({ id: this.showInfo.id, code: this.showInfo.code, name: this.showInfo.name, deviceId: this.showInfo.deviceId, args: {}, source: "lampGis" });
        this.$set(this, 'cmdIds', [this.showInfo.id]);
        this.$store.dispatch('cmd/sendCommands', cmds).then(res => {
          if (res.code != 0) {
            this.$Modal.error({
              title: '指令发送出错',
              content: res.data
            });
            return;
          }
        });
      }
    },
    statusListBut(index) {
      if (this.statusListIndex == index) {
        this.statusList[index].active = !this.statusList[index].active;
        this.statusListIndex = this.statusList[index].active ? this.statusListIndex : -1;
      } else {
        if (this.statusListIndex != -1) {
          this.statusList[this.statusListIndex].active = false;
        }
        this.statusListIndex = index;
        this.statusList[index].active = true;
      }
      if (this.statusListIndex != -1) {
        this.showItems = this.statusList[this.statusListIndex].items
      } else {
        this.initList(true);
      }
    },
    searchBut() {
      this.showItems = [];
      if (this.deviceSearch == "") {
        this.showItems = this.allItems;
      }
      this.allItems.forEach(el => {
        if (el.name.indexOf(this.deviceSearch) != -1 || (el.id + "").indexOf(this.deviceSearch) != -1) {
          this.showItems.push(el)
        }
      });
    },
    setUpAddress(el) {
      if (this.enableMoveDevice) {
        let sits = [];
        this.showItems.map(p => sits.push({
          type: 199,
          id: p.id,
          name: p.name,
          lat: p.lat,
          lng: p.lng
        }));
        this.$set(this, 'posItems', sits);
        let groupId = 0, stationId = 0;
        if(this.selectedNode.type == 'root' || this.selectedNode.type == 'group'){
          groupId = this.selectedNode.id;
        }else{
          stationId = this.selectedNode.id;
        }
        this.moveDevicePos.lat = el.lnglat.lat;
        this.moveDevicePos.lng = el.lnglat.lng;
        this.moveDevicePos.groupId = groupId;
        this.moveDevicePos.stationId = stationId;
        this.moveDevicePos.show = true;
      // console.log('get pos item', this.moveDevicePos)
      }
    },
    initList: function (isPosition) {
      this.$axios.post(`//${this.domains.trans}/station/light/QueryGis`, this.filter).then(res => {
        if (res.code == 0) {
          res.data.forEach(el => {
            el.events = {
              click: () => {
                this.openPoiwindow(el);
              },
            }
            if (el.lng == 0 && el.lat == 0) {
              el.position = [this.config.lng, this.config.lat];
            } else {
              el.position = [el.lng, el.lat];
            }
            el.imgUrl = this.imgStatus(el);
            el.lampList = this.lampList(el).arr;
            el.lampsNum = this.lampList(el).lamps;
          });
          this.$set(this, 'showItems', res.data);
          this.$set(this, 'allItems', res.data);
          if (isPosition) {
            this.getMapCenter();
          }
          this.isStatusList();
        }
      });
    },
    getMapCenter: async function () {
      let minlat = 90, minlng = 180, maxlat = 0, maxlng = 0;
      let cnt = 0, data = this.showItems;
      for (let item of data) {
        if (item.lat <= 0 || item.lng <= 0) continue;
        if (item.lat < minlat) minlat = item.lat;
        if (item.lat > maxlat) maxlat = item.lat;
        if (item.lng < minlng) minlng = item.lng;
        if (item.lng > maxlng) maxlng = item.lng;
        cnt++;
      }
      let c = {
        lat: cnt == 0 ? this.config.lat : (minlat + maxlat) / 2,
        lng: cnt == 0 ? this.config.lng : (minlng + maxlng) / 2,
        // zoom: cnt == 0 ? 12 : this.getZoom(minlat, minlng, maxlat, maxlng)
      }
      this.amapInfo.center = [c.lng, c.lat];
      this.amapInfo.zoom = 17.5;
    },
    getZoom: function (minlat, minlng, maxlat, maxlng) {
      let zooms = [50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 25000, 50000, 100000, 200000, 500000, 1000000, 2000000];//级别18-3对应的比例尺
      let pmin = new this.BMap.Point(minlng, minlat);
      let pmax = new this.BMap.Point(maxlng, maxlat);
      let dis = this.map.getDistance(pmin, pmax);
      for (let i = 0; i < zooms.length; i++) {
        if (zooms[i] > dis) return 18 - i + 4;
      }
      return 17.5;
    },
    openPoiwindow(el) {
      this.$set(this, "showInfo", el);
      this.poiInfoWindow.position = el.position;
      this.poiInfoWindow.visible = true;
    },
    lampList(el) {
      let arr = [], lamps = 0;
      for (let i = 1; i <= 4; i++) {
        if (el.content[`en${i}`]) {
          lamps = lamps + el.content[`lc${i}`]
          arr.push({
            n: el.content[`nm${i}`],
            op: el.lastData && typeof (el.lastData[`op${i}`]) == 'number' && el.lastData[`op${i}`] >= 0 ? el.lastData[`op${i}`] : '-',
            c: el.lastData && typeof (el.lastData[`c${i}`]) == 'number' && el.lastData[`c${i}`] >= 0 ? el.lastData[`c${i}`].toFixed(2) : '-',
            p: el.lastData && typeof (el.lastData[`p${i}`]) == 'number' && el.lastData[`p${i}`] >= 0 ? el.lastData[`p${i}`] : '-',
            lc: el.content && typeof (el.content[`lc${i}`]) == 'number' && el.content[`lc${i}`] >= 0 ? el.content[`lc${i}`] : '-',
            cl: el.lastData && typeof (el.lastData[`cl${i}`]) == 'number' && el.lastData[`cl${i}`] >= 0 ? el.lastData[`cl${i}`].toFixed(2) : '-',
          })
        }

      }
      return { arr, lamps };
    },
    isStatusList() {
      this.statusList.forEach(el => {
        switch (el.name) {//  亮灯   
          case '离线':
            el.num = this.showItems.filter(p => p.imgStatus == 1).length;
            el.items = this.showItems.filter(p => p.imgStatus == 1);
            break;
          case '亮灯':
            el.num = this.showItems.filter(p => p.imgStatus == 3).length
            el.items = this.showItems.filter(p => p.imgStatus == 3);
            break;
          case '灭灯':
            el.num = this.showItems.filter(p => p.imgStatus == 5).length
            el.items = this.showItems.filter(p => p.imgStatus == 5);
            break;
          case '报警':
            el.num = this.showItems.filter(p => p.alarm).length
            el.items = this.showItems.filter(p => p.alarm);
            break;
        }
      });
    },
    imgStatus(el) {
      if (!el.online) {
        el.imgStatus = 1;
        return this.img.light.carat.gis.LightsLine; //离线 
      } else {
        if (el.running) {//亮灯
          if (el.alarm) {//是否报警
            el.imgStatus = 2;
            return this.img.light.carat.gis.LightsPolice
          } else {
            el.imgStatus = 3;
            return this.img.light.carat.gis.LightsOn;
          }
        } else {//灭灯
          if (el.alarm) {//是否报警
            el.imgStatus = 4;
            return this.img.light.carat.gis.LightsOutPolice
          } else {
            el.imgStatus = 5;
            return this.img.light.carat.gis.LightsOut;
          }
        }
      }
    },
    onSearchResult(pois) {
      if (pois.length > 0) {
        this.amapInfo.center = [pois[0].lng, pois[0].lat];
      } else {
        this.amapInfo.center = [this.config.lng, this.config.lat];
      }
    },
    posSaved: function (params) {
      if (params.success) {
        this.initList(false)
      }
      this.moveDevicePos.show = false;
    },
    // ModalControlOutput 
    argsSaved: function (params) {
      let cmdids = [];
      if (params.cmd.type == 'light') {
        let chks = this.$refs.lightList1.getSelectedItems();
        let cmds = []
        let ns = [];
        // let ds = {};
        for (let chk of chks) {
          ns.push(chk.name);
          chk.result = '';
          chk.status = 0;
          let args = this.clone(params.args);
          let code = params.cmd.code;
          if (params.cmd.code == 'openSingle' || params.cmd.code == 'closeSingle') {
            code = 'handSingle';
          } else if (params.cmd.code == 'handControl') {
            args.point = false;
          } else if (params.cmd.code == 'setYearPlan') {
            args.timeId = chk.timeId;
          } else if (params.cmd.code == 'update') {
            args.flag = 1;
          }
          cmdids.push(chk.id);
          cmds.push({ id: chk.id, code: code, name: params.cmd.name, deviceId: chk.devId, args: args });
        }
        if (params.cmd.code == 'update') {
          this.$Modal.confirm({
            title: "提示",
            content: `确定要升级设备[${ns}]吗?`,
            onOk: async () => {
              this.$set(this, 'cmdIds', cmdids);
              this.$store.dispatch('cmd/sendCommands', cmds);
            },
          });
        } else {
          this.$set(this, 'cmdIds', cmdids);
          this.$store.dispatch('cmd/sendCommands', cmds);
        }
      }
    },
    cmdResultChanged: function () {
      let cmds = this.commands.filter(p => this.cmdIds.indexOf(p.id) != -1);
      if (cmds.length == 0) return;
      let contents = [];
      for (let cmd of cmds) {
        contents.push(this.getCmdStatus(cmd));

      }
      let content = contents.join(' ');
      this.$store.commit('auth/hideSpin');
      setTimeout(() => {
        this.$Modal.success({
          title: '执行结果',
          content: content
        });
      }, 500);
    },
    getCmdStatus: function (cmd) {
      let p = '<p>', p1 = '</p>';
      if (this.stationTabName == 'light') {
        p = '';
        p1 = ' ';
      }
      let content = `${p}指令已创建${p1}`;
      ////status: 0、等待发送，1、等待回复，2、指令超时，3、执行失败，4、执行中，5、失败重发，9、执行成功
      if (cmd.status == 1) {
        if (cmd.args.channel) {
          content = `${p}等待设备通道K${cmd.args.channel}回复${p1}`;
        } else {
          content = `${p}等待设备回复${p1}`
        }
      }
      else if (cmd.status == 3) {
        if (cmd.args.channel) {
          content = `${p}通道K${cmd.args.channel}执行失败：${cmd.result}${p1}`;
        } else {
          content = `${p}执行失败：${cmd.result}${p1}`
        }
      } else if (cmd.status == 4) {
        if (cmd.args.channel) {
          content = `${p}通道K${cmd.args.channel}执行中：${cmd.content.pkg} / ${cmd.content.total}${p1}`;
        } else {
          content = `${p}执行中：${cmd.content.pkg} / ${cmd.content.total}${p1}`
        }
      } else if (cmd.status == 5) {
        if (cmd.args.channel) {
          content = `${p}通道K${cmd.args.channel}失败重试：${cmd.result}${p1}`;
        } else {
          content = `${p}失败重试：${cmd.result}${p1}`;
        }
      } else if (cmd.status == 9) {
        if (cmd.code == "getTimeTable") {
          let day = "day" + cmd.args.day;
          let con = cmd.content[day];
          //{0: '关闭', 1: '开启', 2: '无效'},
          content = `${p} 时间表：${cmd.args.month}月${cmd.args.day}日<br> ${con.t1} - ${this.powerTableActions[con.a1]}<br>${con.t2} - ${this.powerTableActions[con.a2]}<br> ${con.t3} - ${this.powerTableActions[con.a3]}<br> ${con.t4} - ${this.powerTableActions[con.a4]}${p1}`;
        } else if (cmd.code == "getclock") {
          content = `${p}设备当前时间: ${cmd.content.nowTime}${p1}`;
        } else if (cmd.code == "setclock") {
          let myDate = new Date();
          content = `${p}已校准时钟: ${myDate.format('yyyy-MM-dd HH:mm:ss')}${p1}`;
        } else if (cmd.code == 'getYearPlan') {
          // console.log('get year plan', cmd)
          if (cmd.args.month != cmd.content.month) {
            content = `${p}执行成功：但返回月份不对，需要:${cmd.args.month}，实际:${cmd.content.month}${p1}`;
          } else {
            let strs = [];
            let obj = cmd.content[`day${cmd.args.day}`];
            if (cmd.content.mode == 4) { //北京模式
              for (let i = 1; i <= 4; i++) {
                let ao = this.powerTableActions[obj[`a${i}`]];
                let to = obj[`o${i}`];
                // let ac = this.powerTableActions[obj[`ac${i}`]];
                let tc = obj[`c${i}`];
                strs.push(`${p}${i}、${to}-${tc}:${ao}${p1}`);
              }
            } else if (cmd.content.mode == 5) { //集中器模式
              for (let i = 1; i <= 6; i++) {
                let a = obj[`a${i}`]; //this.monitorActionTypes[];
                let t = obj[`t${i}`];
                let v = this.monitorActionTypes[a]; //a == 1 ? '' : obj[`v${i}`];
                if (a != 1) {
                  v += ` - ${obj[`v${i}`]}`
                }
                strs.push(`${p}${i}、${t} - ${v}${p1}`);
              }
            } else {
              for (let i = 1; i <= 4; i++) {
                let a = this.powerTableActions[obj[`a${i}`]];
                let t = obj[`t${i}`];
                strs.push(`${p}${i}、${t} - ${a}${p1}`);
              }
            }
            content = `${p}通道K${cmd.args.channel}执行成功：${cmd.args.month}月${cmd.args.day}日计划：${strs.join(' ')}${p1}`
          }
        } else if (cmd.code == 'getInfo') {
          content = `${p}硬件版本：${cmd.content.hardware}${p1}${p}软件版本：${cmd.content.software}${p1}${p}生产厂商：${cmd.content.manufacture}${p1}`
        } else if (cmd.code == 'getSimInfo') {
          content = `${p}ICCID: ${cmd.content.iccid}${p1}${p}IMEI: ${cmd.content.imei}${p1}${p}RSSI: ${cmd.content.rssi}${p1}`
        } else if (cmd.code == 'getNetwork') {
          content = `${p}IP:${cmd.content.host}${p1}${p}端口:${cmd.content.port}${p1}${p}心跳间隔:${cmd.content.heart}秒${p1}`
        } else if (cmd.code == 'getDayPlan1') {
          content = '';
          for (let n = 1; n <= 6; n++) {
            content += `${p}时段${n}：${cmd.content[`enable1${n}`] ? '启用' : '禁用'}；${p1}${p}时间：${cmd.content[`expire1${n}`]}分,1通道亮度：${cmd.content[`bright1${n}`]}，1通道色温：${cmd.content[`color1${n}`]},2通道亮度：${cmd.content[`bright2${n}`]}，2通道色温：${cmd.content[`color2${n}`]};${p1}`
          }
        } else if (cmd.code == 'getDayPlan2') {
          content = '';
          for (let n = 1; n <= 6; n++) {
            content += `${p}时段${n}：${cmd.content[`enable1${n}`] ? '启用' : '禁用'}；${p1}${p}时间：${cmd.content[`start1${n}`]},1通道亮度：${cmd.content[`bright1${n}`]}，1通道色温：${cmd.content[`color1${n}`]},2通道亮度：${cmd.content[`bright2${n}`]}，2通道色温：${cmd.content[`color2${n}`]};${p1}`
          }
        } else if (cmd.code == 'getGroup') {
          content = `${p}分组号: ${cmd.content.no}${p1}`
        } else if (cmd.code == 'getPlanType') {
          content = `${p}日表类型: ${cmd.content.type == 0 ? '计时日表' : '准时日表'}`
        } else if (cmd.code == 'getAlarmRange') {
          content = `电压下限：${cmd.content.vLow}；
                电压上限：${cmd.content.vHigh}；
                电流下限：${cmd.content.cLow}；
                电流上限：${cmd.content.cHigh}；
                温度下限：${cmd.content.tLow}；
                温度上限：${cmd.content.tHigh}；
                上报间隔：${cmd.content.interval}分；`
        } else if (cmd.code == 'getUnionParam') {
          content = `联动编号：${cmd.content.rfsn}；
                433应用频道：${cmd.content.channel}；
                433发送功率：${cmd.content.txpower}；
                是否发送433信号：${cmd.content.txen}；
                重发次数：${cmd.content.repeat}；
                重发周期：${cmd.content.period}；
                响应范围：${cmd.content.range}；
                是否接收433信号：${cmd.content.rxen}；
                联动方式：${cmd.content.mode}；
                灯1延时时间：${cmd.content.keep1}；
                灯2延时时间：${cmd.content.keep2}；
                是否启用感应启用：${cmd.content.sensor ? '启用' : '禁用'}；`
        } else if (cmd.code == 'getAlarmStatus') {
          content = `电压报警：${cmd.content.voltage ? '启用' : '禁用'}；
                电流报警：${cmd.content.current ? '启用' : '禁用'}；
                温度报警：${cmd.content.temp ? '启用' : '禁用'}；`
        } else if (cmd.code == 'getGyroLevel') {
          content = `倾斜阈值：${cmd.content.level}；`
        } else if (cmd.args.channel) {
          content = `${p}通道K${cmd.args.channel}执行成功${p1}`;
        } else {
          content = `${p}执行成功${p1}`;
        }
      }
      return content;
    },
  },
}
</script>
<style scoped>
.pole-main {
  width: calc(100%);
  height: 100%;
  position: relative;
}
.container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}
.slot-info-window {
  width: 621px;
  display: flex;
  margin-left: 50%;
}
.amap-float-box {
  position: absolute;
  width: 460px;
  top: 10px;
  display: flex;
  background: transparent;
  z-index: 1;
  flex-direction: revert;
  justify-content: space-between;
}
.float-box-left {
  height: 34px;
  display: flex;
  margin-left: 70px;
}
.float-box-right {
  width: 440px;
  height: 44px;
  background: #112c4b;
  opacity: 0.94;
  border-radius: 6px;
  display: flex;
  line-height: 44px;
  justify-content: space-evenly;
}
.float-box-title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 15px;
  margin-top: 20px;
}
.fixed-box-list {
  height: 250px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
}
.status-list {
  display: flex;
  flex-direction: column;
  justify-content: inherit;
  cursor: pointer;
  padding: 10px 15px;
  position: relative;
  margin-top: 20px;
}
.status-list-bg {
  background: #19395f;
}
.status-list img {
  vertical-align: middle;
  margin-right: 5px;
}
.status-list .status-list-active {
  color: #49e1fc;
}
.status-list-name {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
}
.status-list-num {
  color: #99aac0;
  line-height: 30px;
}

.amap-float-box /deep/ .ivu-input-group .ivu-input {
  background: #112c4b;
  border: 1px solid #112c4b;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #94a3b8;
}
.amap-float-box /deep/ .ivu-input-group-append {
  background: #42cee8;
  border: none;
}

.amap-float-box /deep/ .ivu-icon {
  font-size: 20px;
  color: #011c31;
  font-weight: bold;
}
.left-search {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #011c31;
  vertical-align: text-bottom;
}
.amap-tool-drag {
  width: 139px;
  height: 34px;
  background: #112c4b;
  opacity: 0.96;
  border-radius: 6px;
  margin-left: 20px;
  line-height: 34px;
  text-align: center;
}
.amap-tool-name {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #94a3b8;
  margin-right: 5px;
}
.float-box-fixed {
  width: 160px;
  height: 376px;
  background: rgba(17, 44, 75, 0.9);
  border-radius: 6px;
  margin-right: 50px;
  position: fixed;
  right: 0;
}
.fixed-box-total {
  margin: 15px 0 5px;
  display: flex;
  flex-direction: revert;
  justify-content: space-between;
  padding: 0 15px;
}
.total-name {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.total-num {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.status-list-box {
  display: flex;
  flex-direction: revert;
  justify-content: space-between;
}
.status-list-border {
  width: 140px;
  height: 1px;
  background: linear-gradient(90deg, #102946, #071224, #102946);
  position: absolute;
  top: 60px;
  left: 50%;
  margin-left: -70px;
}
</style>