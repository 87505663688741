<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">编辑云联友科账户信息</div>
    <Form ref="form" :model="form" class="form" :rules="rules" :label-width="100">
      <FormItem prop="name" label="账户名称">
        <Input ref="name" type="text" maxlength="20" v-model="form.name" placeholder="请输入名称">
        </Input>
      </FormItem>
      <FormItem prop="appKey" label="客户端ID">
        <Input ref="appKey" type="text" maxlength="50" v-model="form.appKey" placeholder="请输入客户端ID">
        </Input>
      </FormItem>
      <FormItem prop="appSecret" label="客户端密钥">
        <Input ref="appSecret" type="text" maxlength="50" v-model="form.appSecret" placeholder="请输入客户端密钥">
        </Input>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
  export default {
    name: 'ModalRoleEdit',
    components:{
    },
    model: {
      prop: 'value',
      event: 'showChanged'
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      item: {
        type: Object,
        default(){ return {};}
      }
    },
    data () {
      return {
        showModal: this.value,
        loading: false,
        isAdd: false,
        index: -1,
        form: {
          id: 0,
          name: '',
          appKey: '',
          appSecret: '',
        },
        rules: {
          appKey: [
            { required: true, message: '不能为空', trigger: 'blur' },
            { type: 'string', min: 30, max: 50, message: '长度必须 30 - 50 个字符', trigger: 'blur' }
          ],
          appSecret: [
            { required: true, message: '不能为空', trigger: 'blur' },
            { type: 'string', min: 32, max: 50, message: '长度必须 32 - 50 个字符', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '名称不能为空', trigger: 'blur' },
            { type: 'string', min: 2, max: 20, message: '名称长度必须2 - 20个字符', trigger: 'blur' }
          ],
        },
      }
    },
    watch: {
      // item(newVal){
        
      // },
      value(newVal) {
        this.showModal = newVal;
        if(this.showModal){
          // console.log('edit item', this.item)
          this.isAdd = this.item.isAdd;
          if(!this.isAdd){
            this.form.id = this.item.data.id;
            this.form.appKey = this.item.data.appKey;
            this.form.appSecret = this.item.data.appSecret;
            this.form.name = this.item.data.name;
            this.index = this.item.index;
          }else{
            this.form.id = 0;
            this.form.name = '';
            this.form.appKey = '';
            this.form.appSecret = '';
          }
        }
      },
      showModal(val){
        this.$emit('showChanged', val);
      }
    },
    methods: {
      ok: async function () {
        let result = await this.$refs.form.validate();
        if(!result){
          // this.hideLoading();
          return;
        }
        this.loading = true;
        let res = await this.$axios.post(`//${this.domains.trans}/pole/account/SaveYunlianyouke`, this.form);
        this.loading = false;
        if(res.code !== 0){
          // this.$Message.error(res.data);
          return;
        }
        this.form.id = res.data;
        this.$Message.info('保存成功');
        setTimeout(() => {
          this.showModal = false;
          this.$emit('saved');
        }, 800);
      },
      cancel () {
        this.showModal = false;
        this.$refs.form.resetFields();
      },
    }
  }
</script>
<style scoped>
  .form{
    height: calc(100%);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>