<template>
  <!-- 仪表盘 -->
  <div :id="id" class="chartsBox"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  props: {
    id: {
      type: String,
      default: "baseCircle",
    },
    option: {
      type: Object,
      default: () => { },
      // {
      //  lineStyleColor: '底色',
      //  color: '显示颜色',
      //  width:"底色宽度",
      //  setSeries:{},
      //  data: [{
      //       value: 20,
      //       name: 'Perfect',
      //       title: {
      //         offsetCenter: ['0%', '0%']
      //       },
      //       detail: {
      //         valueAnimation: true,
      //         offsetCenter: ['0%', '-10%']
      //       }
      //     }],
      //};
    },
  },
  data() {
    return {
      chart: null,
      chartDom: null,
      defaultData: {
        series: [
          {
            type: 'gauge',
            startAngle: 360,
            endAngle: 0,
            center: ['50%', '50%'],
            radius: '100%',
            min: 0,
            max: 1,
            splitNumber: 20,
            axisLine: {
              lineStyle: {
                width: 0,
              }
            },
            axisTick: {
              length: 7,
              lineStyle: {
                color: 'rgba(75, 152, 234, 0.6)',//小刻度颜色
                width: 2
              }
            },
            pointer: {
              show: false
            },
            splitLine: {
              length: 10,
              lineStyle: {
                color: '#4B98EA',//大刻度颜色
                width: 2
              }
            },
            axisLabel: {
              color: '#464646',
              fontSize: 20,
              distance: -60,
              rotate: 'tangential',
              formatter: function () {
                return '';
              }
            },
            title: {
              show: false
            },
            detail: {
              show: false
            },
            data: [
              {
                value: 0.7,
              }
            ]
          }
        ]
      },
    };
  },
  watch: {
    data() {
      this.initOption();
    },
  },
  methods: {
    // handle() {
    //   return this.defaultData;
    // },
    initOption() {
      let newPromise = new Promise((resolve) => {
        resolve();
      });
      newPromise.then(() => {
        this.chartDom = document.getElementById(this.id);
        this.chart = echarts.init(this.chartDom);
        this.chart.setOption(this.defaultData);
      });
    },
  },
  mounted: function () {
    this.initOption();
  },
};
</script>
<style scoped>
.chartsBox {
  width: 100%;
  height: 100%;
}
</style>

