<template>
  <Modal v-model="showModal" :mask-closable="false" draggable width="1000px">
    <div slot="header">应用 {{item.name}} 的配置信息</div>
    <div style="text-align:right;max-height: 500px; overflow-y:auto">
      <Table row-key="id" border size="small" stripe highlight-row :loading="loading" :columns="cols" :max-height="500" :data="list"></Table>
    </div>
    <div slot="footer">
      <Form :model="editItem" inline>
        <FormItem label="配置编码" :label-width="80" style="margin-bottom: 0;">
          <Input type="text" v-model="editItem.code" placeholder="配置编码" />
        </FormItem>
        <FormItem label="配置名称" :label-width="80" style="margin-bottom: 0;">
          <Input type="text" v-model="editItem.name" placeholder="配置名称" />
        </FormItem>
        <FormItem style="margin-bottom: 0;">
          <Button type="primary" @click="addConfig">新增配置</Button>
        </FormItem>
      </Form>
    </div>
    <ConfigTableExpand style="display: none" />
  </Modal>
</template>
<script>
import ConfigTableExpand from './ConfigTableExpand.vue'
export default {
  name: 'ModalConfigInfo',
  components:{
    ConfigTableExpand
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default (){return {};}
    }
  },
  data () {
    return {
      editItem: {code: '', name: ''},
      title: '查看配置信息',
      showModal: this.value,
      loading: false,
      list: [],
      cols: [
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            return h(ConfigTableExpand, {
              props: {
                row: params.row
              }
            })
          }
        },
        {
          title: '编码',
          key: 'code',
        },
        {
          title: '名称',
          key: 'name'
        },
        {
          title: '创建时间',
          key: 'createTime'
        },
        {
          title: '修改时间',
          key: 'updateTime'
        },
        {
          title: '操作',
          width: 135,
          render: (h, params) => {
            let btns = [
              h('Button', {
                props: {
                    type: 'primary',
                    size: 'small'
                },
                style: {
                    marginRight: '5px'
                },
                on: {
                    click: () => {
                        this.updateConfig(params)
                    }
                }
              }, '更新'),
              h('Button', {
                props: {
                    type: 'error',
                    size: 'small'
                },
                style: {
                    marginRight: '5px'
                },
                on: {
                    click: () => {
                        this.deleteConfig(params)
                    }
                }
              }, '删除'),
            ];
            return h('div', btns);
          },
        }
      ],
    }
  },
  watch: {
    item(){
      this.getConfigs();
    },
    value(newVal) {
      this.showModal = newVal;
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  methods: {
    addConfig: function(){
      if(this.editItem.code == '' || this.editItem.name == ''){
        this.$Message.warning('请输入编码和名称');
        return;
      }
      let t =this.list.find(item => {
        return item.code == this.editItem.code || item.name == this.editItem.name;
      });
      if(t){
        this.$Message.warning('已存在编码或名称');
        return;
      }
      this.list.splice(0, 0, {appId: this.item.id, code: this.editItem.code, name: this.editItem.name, developContent: '', testContent: '', productContent: ''})
      this.editItem.code = '';
      this.editItem.name = '';
    },
    getConfigs: async function(){
      let res = await this.$axios.get(`//${this.domains.trans}/app/config/${this.item.id}`);
      if(res.code == 0){
        this.$set(this, "list", res.data);
      }else{
        // this.$Message.error(res.data);
      }
    },
    updateConfig: async function(params){
      let res = await this.$axios.post(`//${this.domains.trans}/app/config`, {appId:this.item.id,code:params.row.code,name:params.row.name,developContent:params.row.developContent,testContent:params.row.testContent,productContent:params.row.productContent});
      if(res.code == 0){
        this.getConfigs();
        this.$Message.info(`保存成功`, { timeout: 4000 });
      }else{
        // this.$Message.error({
        //   content: `保存失败：${res.data}`,
        //   duration: 4
        // });
      }
    },
    deleteConfig: async function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除应用[${this.item.name}]的配置[${params.row.name}]吗?`,
        onOk: async () => {
          let res = await this.$axios.delete(`//${this.domains.trans}/app/config/${this.item.id}?code=${params.row.code}`);
          if(res.code == 0){
            this.$Message.info(`删除成功`, { timeout: 4000 });
            this.getConfigs();
          }else{
            // this.$Message.error({
            //   content: `删除失败：${res.data}`,
            //   duration: 4
            // });
          }
        }
      });
    },
    cancel () {
      this.showModal = false;
      this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>