<template>
<div ref="table1" class="setting-table">
  <div class="setting-header">
    <Checkbox v-model="form.cmdAuthEnable">是否启用指令授权？</Checkbox>
    <Checkbox v-model="form.cmdAuthByUsbKey">是否启用U盾鉴权？</Checkbox>
    <Checkbox v-model="form.cmdPswdBeforeUsbKey">U盾鉴权之前是否验证用户密码？</Checkbox>
    <Button v-if="funCodes('slcae')" style="margin-right: 5px" type="primary" @click="saveSetting">保存</Button>
    <Button v-if="funCodes('slcav')" style="margin-right: 5px" type="info" @click="getList">刷新</Button>
  </div>
  <div class="setting-content">
    <div class="setting-item" style="width: 300px">
      <div class="setting-item-header">
        <!-- {{userSelectAll}}
        this.userSelectAll = true; -->
        授权员列表：
        <Checkbox v-model="userSelectAll" @on-change="handleUserSelectAll">全选</Checkbox>
      </div>
      <div class="setting-item-content">
        <div class="cmd-auth-user-list">
          <div class="cmd-auth-user-item">
            <div style="width: 100px; flex: auto; padding-left: 20px; font-weight: bold;">授权人员</div>
            <div style="width: 60px; flex: none; text-align: center; font-weight: bold;">权限</div>
          </div>
          <div class="cmd-auth-user-item" v-for="item in form.cmdAuthUserLevels" :key="item.id">
            <Checkbox v-model="item.chk" :label="`${item.name}[${item.code}]`" @on-change="handleUserSelect">{{item.name}}[{{item.code}}]</Checkbox>
            <i-switch v-if="item.chk" v-model="item.all" size="large" true-color="green" false-color="blue">
              <span slot="open">A级</span>
              <span slot="close">B级</span>
            </i-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="setting-item" style="width: 300px">
      <div class="setting-item-header">
        授权权限配置：
      </div>
      <div class="setting-item-header" style="font-weight: bold;height: 50px">
        A级权限可任意时段进行授权操作；
      </div>
      <div class="setting-item-header" style="font-weight: bold;height: 50px">
        B级权限可操作时段配置：
      </div>
      <div class="setting-item-header" style="margin-left: 10px">
        授权关联时间表：
      </div>
      <div class="setting-item-header" style="height: 50px;margin-left: 10px">
        <Select :placeholder="showLang('save.select')" v-model="form.cmdAuthTimeId">
          <template v-for="(s, idx) in timeTables">
            <Option :value="s.id" :key="idx">{{s.name}}</Option>
          </template>
        </Select>
      </div>
      <div class="setting-item-header" style="margin-left: 10px">
        开灯授权时间段：
      </div>
      <div class="setting-item-header" style="height: 50px;margin-left: 10px">
        从 开灯时间
        <Select :placeholder="showLang('save.select')" v-model="form.cmdOpenOpenFlag" style="width: 70px">
          <Option :value="-1">之前</Option>
          <Option :value="1">之后</Option>
        </Select>
        <Input type="number" v-model="form.cmdOpenOffsetOpen" style="width: 80px" />
        分钟
      </div>
      <div class="setting-item-header" style="height: 50px;margin-left: 10px">
        至 关灯时间
        <Select :placeholder="showLang('save.select')" v-model="form.cmdOpenCloseFlag" style="width: 70px">
          <Option :value="-1">之前</Option>
          <Option :value="1">之后</Option>
        </Select>
        <Input type="number" v-model="form.cmdOpenOffsetClose" style="width: 80px" />
        分钟
      </div>
      <div class="setting-item-header" style="margin-left: 10px">
        关灯授权时间段：
      </div>
      <div class="setting-item-header" style="height: 50px;margin-left: 10px">
        从 关灯时间
        <Select :placeholder="showLang('save.select')" v-model="form.cmdCloseCloseFlag" style="width: 70px">
          <Option :value="-1">之前</Option>
          <Option :value="1">之后</Option>
        </Select>
        <Input type="number" v-model="form.cmdCloseOffsetClose" style="width: 80px" />
        分钟
      </div>
      <div class="setting-item-header" style="height: 50px;margin-left: 10px">
        至 开灯时间
        <Select :placeholder="showLang('save.select')" v-model="form.cmdCloseOpenFlag" style="width: 70px">
          <Option :value="-1">之前</Option>
          <Option :value="1">之后</Option>
        </Select>
        <Input type="number" v-model="form.cmdCloseOffsetOpen" style="width: 80px" />
        分钟
      </div>
      <div class="setting-item-content">
      </div>
    </div>
    <div class="setting-item">
      <div class="setting-item-header">
        监控器指令列表：
        <!-- <span>已选[{{form.cmdAuthPowerboxs.length}}]</span> -->
        <Checkbox v-model="powerboxSelectAll" @on-change="handlePowerboxSelectAll">全选</Checkbox>
      </div>
      <div class="setting-item-content">
        <CheckboxGroup v-model="form.cmdAuthPowerboxs" class="check-group" @on-change="handlePowerboxGroupChanged">
          <Checkbox v-for="(item, idx) in allPowerboxs" :key="idx" :label="item"></Checkbox>
        </CheckboxGroup>
      </div>
    </div>
    <div class="setting-item">
      <div class="setting-item-header">
        控制通道指令列表：
        <!-- <span>已选[{{form.cmdAuthOutputs.length}}]</span> -->
        <Checkbox v-model="outputSelectAll" @on-change="handleOutputSelectAll">全选</Checkbox>
      </div>
      <div class="setting-item-content">
        <CheckboxGroup v-model="form.cmdAuthOutputs" class="check-group" @on-change="handleOutputGroupChanged">
          <Checkbox v-for="(item, idx) in allOutputs" :key="idx" :label="item"></Checkbox>
        </CheckboxGroup>
      </div>
    </div>
    <div class="setting-item">
      <div class="setting-item-header">
        电流通道指令列表：
        <!-- <span>已选[{{form.cmdAuthCurrents.length}}]</span> -->
        <Checkbox v-model="currentSelectAll" @on-change="handleCurrentSelectAll">全选</Checkbox>
      </div>
      <div class="setting-item-content">
        <CheckboxGroup v-model="form.cmdAuthCurrents" class="check-group" @on-change="handleCurrentGroupChanged">
          <Checkbox v-for="(item, idx) in allCurrents" :key="idx" :label="item"></Checkbox>
        </CheckboxGroup>
      </div>
    </div>
    <div class="setting-item">
      <div class="setting-item-header">
        集中器指令列表：
        <!-- <span>已选[{{form.cmdAuthMonitors.length}}]</span> -->
        <Checkbox v-model="monitorSelectAll" @on-change="handleMonitorSelectAll">全选</Checkbox>
      </div>
      <div class="setting-item-content">
        <CheckboxGroup v-model="form.cmdAuthMonitors" class="check-group" @on-change="handleMonitorGroupChanged">
          <Checkbox v-for="(item, idx) in allMonitors" :key="idx" :label="item"></Checkbox>
        </CheckboxGroup>
      </div>
    </div>
    <div class="setting-item" style="flex:auto;">
      <div class="setting-item-header">
        灯控指令列表：
        <!-- <span>已选[{{form.cmdAuthLights.length}}]</span> -->
        <Checkbox v-model="lightSelectAll" @on-change="handleLightSelectAll">全选</Checkbox>
      </div>
      <div class="setting-item-content">
        <CheckboxGroup v-model="form.cmdAuthLights" class="check-group" @on-change="handleLightGroupChanged">
          <Checkbox v-for="(item, idx) in allLights" :key="idx" :label="item"></Checkbox>
        </CheckboxGroup>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'CommandAuth',
  components:{
  },
  props: {
  },
  data () {
    return {
      tabHeight: 600,
      loading: false,
      cols: [],
      form: {
        cmdAuthEnable: false,
        cmdAuthByUsbKey: false,
        cmdPswdBeforeUsbKey: false,
        cmdAuthTimeId: 0,
        cmdOpenOpenFlag: -1,
        cmdOpenCloseFlag: 1,
        cmdCloseCloseFlag: -1,
        cmdCloseOpenFlag: 1,
        cmdOpenOffsetOpen: -60,
        cmdOpenOffsetClose: 60,
        cmdCloseOffsetOpen: -60,
        cmdCloseOffsetClose: 0,
        cmdAuthUserLevels: [],
        cmdAuthPowerboxs: [],
        cmdAuthOutputs: [],
        cmdAuthCurrents: [],
        cmdAuthMonitors: [],
        cmdAuthLights: [],
      },
      allUsers: [],
      allPowerboxs: [],
      allOutputs: [],
      allCurrents: [],
      allMonitors: [],
      allLights: [],
      timeTables: [],
      userSelectAll: false,
      powerboxSelectAll: false,
      outputSelectAll: false,
      currentSelectAll: false,
      monitorSelectAll: false,
      lightSelectAll: false,
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['alarmLevels', 'productCodes', 'alarmTypes', 'alarmGroupList']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 100);
    this.getList();
  },
  destroyed: function(){
  },
  methods: {
    handleUserSelectAll: function(){
      for(let item of this.form.cmdAuthUserLevels){
        item.chk = this.userSelectAll;
      }
    },
    handleUserSelect: function(){
      this.userSelectAll = this.form.cmdAuthUserLevels.filter(p => p.chk).length == this.allUsers.length;
    },
    handlePowerboxSelectAll: function(){
      if (this.powerboxSelectAll) {
        let list = [];
        for(let k in this.allPowerboxs){
          list.push(this.allPowerboxs[k]);
        }
        this.form.cmdAuthPowerboxs = list;
      } else {
        this.form.cmdAuthPowerboxs = [];
      }
    },
    handlePowerboxGroupChanged: function(){
      let list = [];
      for(let k in this.allPowerboxs){
        list.push(this.allPowerboxs[k]);
      }
      this.powerboxSelectAll = this.form.cmdAuthPowerboxs.length == list.length;
    },
    handleOutputSelectAll: function(){
      if (this.outputSelectAll) {
        let list = [];
        for(let k in this.allOutputs){
          list.push(this.allOutputs[k]);
        }
        this.form.cmdAuthOutputs = list;
      } else {
        this.form.cmdAuthOutputs = [];
      }
    },
    handleOutputGroupChanged: function(){
      let list = [];
      for(let k in this.allOutputs){
        list.push(this.allOutputs[k]);
      }
      this.outputSelectAll = this.form.cmdAuthOutputs.length == list.length;
      // console.log('out put changed',  this.form.cmdAuthOutputs.length, list.length)
    },
    handleCurrentSelectAll: function(){
      if (this.currentSelectAll) {
        let list = [];
        for(let k in this.allCurrents){
          list.push(this.allCurrents[k]);
        }
        this.form.cmdAuthCurrents = list;
      } else {
        this.form.cmdAuthCurrents = [];
      }
    },
    handleCurrentGroupChanged: function(){
      let list = [];
      for(let k in this.allCurrents){
        list.push(this.allCurrents[k]);
      }
      this.currentSelectAll = this.form.cmdAuthCurrents.length == list.length;
    },
    handleMonitorSelectAll: function(){
      if (this.monitorSelectAll) {
        let list = [];
        for(let k in this.allMonitors){
          list.push(this.allMonitors[k]);
        }
        this.form.cmdAuthMonitors = list;
      } else {
        this.form.cmdAuthMonitors = [];
      }
    },
    handleMonitorGroupChanged: function(){
      let list = [];
      for(let k in this.allMonitors){
        list.push(this.allMonitors[k]);
      }
      this.monitorSelectAll = this.form.cmdAuthMonitors.length == list.length;
    },
    handleLightSelectAll: function(){
      if (this.lightSelectAll) {
        let list = [];
        for(let k in this.allLights){
          list.push(this.allLights[k]);
        }
        this.form.cmdAuthLights = list;
      } else {
        this.form.cmdAuthLights = [];
      }
    },
    handleLightGroupChanged: function(){
      let list = [];
      for(let k in this.allLights){
        list.push(this.allLights[k]);
      }
      this.lightSelectAll = this.form.cmdAuthLights.length == list.length;
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table1.clientHeight
      // console.log('set tab height', this.tabHeight, this.$refs.table1.clientHeight)
    },
    selectChangeEvent: function(){
          // console.log('=chk e', e);
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/sys/setting/QueryCmdAuth`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'form', {
            cmdAuthEnable: res.data.cmdAuthEnable,
            cmdAuthByUsbKey: res.data.cmdAuthByUsbKey,
            cmdPswdBeforeUsbKey: res.data.cmdPswdBeforeUsbKey,
            cmdOpenOpenFlag: res.data.cmdOpenOffsetOpen > 0 ? 1 : -1,
            cmdOpenOffsetOpen: Math.abs(res.data.cmdOpenOffsetOpen),
            cmdOpenCloseFlag: res.data.cmdOpenOffsetClose > 0 ? 1 : -1,
            cmdOpenOffsetClose: Math.abs(res.data.cmdOpenOffsetClose),
            cmdCloseCloseFlag: res.data.cmdCloseOffsetClose > 0 ? 1 : -1,
            cmdCloseOffsetClose: Math.abs(res.data.cmdCloseOffsetClose),
            cmdCloseOpenFlag: res.data.cmdCloseOffsetOpen > 0 ? 1 : -1,
            cmdCloseOffsetOpen: Math.abs(res.data.cmdCloseOffsetOpen),
            cmdAuthTimeId: res.data.cmdAuthTimeId,
            cmdAuthUserLevels: res.data.allUsers.map(user => {
              let us = res.data.cmdAuthUserLevels.filter(p => p.id == user.id);
              user.chk = us.length > 0;
              user.all = us.length > 0 ? us[0].all : false;
              return user;
            }),
            cmdAuthPowerboxs: res.data.cmdAuthPowerboxs.map(code => {
              return res.data.allPowerboxs[code];
            }),
            cmdAuthOutputs: res.data.cmdAuthOutputs.map(code => {
              return res.data.allOutputs[code];
            }),
            cmdAuthCurrents: res.data.cmdAuthCurrents.map(code => {
              return res.data.allCurrents[code];
            }),
            cmdAuthMonitors: res.data.cmdAuthMonitors.map(code => {
              return res.data.allMonitors[code];
            }),
            cmdAuthLights: res.data.cmdAuthLights.map(code => {
              return res.data.allLights[code];
            }),
          });
          this.$set(this, 'timeTables', res.data.timeTables);
          if(this.form.cmdAuthTimeId <= 0 && res.data.timeTables.length > 0){
            this.form.cmdAuthTimeId = res.data.timeTables[0].id;
          }
          this.$set(this, 'allUsers', res.data.allUsers);
          this.$set(this, 'allPowerboxs', res.data.allPowerboxs);
          this.$set(this, 'allOutputs', res.data.allOutputs);
          this.$set(this, 'allCurrents', res.data.allCurrents);
          this.$set(this, 'allMonitors', res.data.allMonitors);
          this.$set(this, 'allLights', res.data.allLights);
          this.handlePowerboxGroupChanged();
          this.handleOutputGroupChanged();
          this.handleCurrentGroupChanged();
          this.handleMonitorGroupChanged();
          this.handleLightGroupChanged();
          this.userSelectAll = this.form.cmdAuthUserLevels.filter(p => p.chk).length == this.allUsers.length;
          // console.log('get list', this.form);
        }
      });
    },
    saveSetting: function(){
      if(this.form.cmdAuthTimeId <= 0){
        this.$Message.warning('至少选择一个授权关联时间表');
        return;
      }
      if(this.form.cmdOpenOffsetOpen < 0 || this.form.cmdOpenOffsetOpen > 300){
        this.$Message.warning('开灯授权时段的开灯时间范围必须在 0 - 300 之间');
        return;
      }
      if(this.form.cmdOpenOffsetClose < 0 || this.form.cmdOpenOffsetClose > 300){
        this.$Message.warning('开灯授权时段的关灯时间范围必须在 0 - 300 之间');
        return;
      }
      if(this.form.cmdCloseOffsetClose < 0 || this.form.cmdCloseOffsetClose > 300){
        this.$Message.warning('关灯授权时段的关灯时间范围必须在 0 - 300 之间');
        return;
      }
      if(this.form.cmdCloseOffsetOpen < 0 || this.form.cmdCloseOffsetOpen > 300){
        this.$Message.warning('关灯授权时段的开灯时间范围必须在 0 - 300 之间');
        return;
      }
      let users = [], powerboxs = [], outputs = [], currents = [], monitors = [], lights = [];
      for(let u of this.form.cmdAuthUserLevels){
        if(u.chk)users.push({all: u.all, id: u.id});
      }
      if(users.length == 0){
        this.$Message.warning('至少选择一个授权员');
        return;
      }
      for(let p of this.form.cmdAuthPowerboxs){
        for(let k in this.allPowerboxs){
          if(p == this.allPowerboxs[k]){
            powerboxs.push(k);
          }
        }
      }
      for(let p of this.form.cmdAuthOutputs){
        for(let k in this.allOutputs){
          if(p == this.allOutputs[k]){
            outputs.push(k);
          }
        }
      }
      for(let p of this.form.cmdAuthCurrents){
        for(let k in this.allCurrents){
          if(p == this.allCurrents[k]){
            currents.push(k);
          }
        }
      }
      for(let p of this.form.cmdAuthMonitors){
        for(let k in this.allMonitors){
          if(p == this.allMonitors[k]){
            monitors.push(k);
          }
        }
      }
      for(let p of this.form.cmdAuthLights){
        for(let k in this.allLights){
          if(p == this.allLights[k]){
            lights.push(k);
          }
        }
      }
      let form = {
        cmdAuthEnable: this.form.cmdAuthEnable,
        cmdAuthByUsbKey: this.form.cmdAuthByUsbKey,
        cmdPswdBeforeUsbKey: this.form.cmdPswdBeforeUsbKey,
        cmdOpenOffsetOpen: this.form.cmdOpenOffsetOpen * this.form.cmdOpenOpenFlag,
        cmdOpenOffsetClose: this.form.cmdOpenOffsetClose * this.form.cmdOpenCloseFlag,
        cmdCloseOffsetOpen: this.form.cmdCloseOffsetOpen * this.form.cmdCloseOpenFlag,
        cmdCloseOffsetClose: this.form.cmdCloseOffsetClose * this.form.cmdCloseCloseFlag,
        cmdAuthTimeId: this.form.cmdAuthTimeId,
        cmdAuthUserLevels: users,
        cmdAuthPowerboxs: powerboxs,
        cmdAuthOutputs: outputs,
        cmdAuthCurrents: currents,
        cmdAuthMonitors: monitors,
        cmdAuthLights: lights
      }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/sys/setting/SaveCmdAuth`, form).then(res => {
      this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功')
          // if(this.form.cmdAuthByUsbKey){
          //   setTimeout(() => {
          //     window.location.reload();
          //   }, 2000);
          // }
        }
      });
    },
  }
}
</script>
<style scoped>
.cmd-auth-user-list{
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.cmd-auth-user-item{
  border-bottom: solid 1px rgba(208, 208, 214, 0.452);
  height: 30px;
  padding: 0 10px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.check-group{
  display: flex;
  flex-direction: column;
}
.setting-table{
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.setting-header{
  border: solid 1px rgba(208, 208, 214, 0.452);
  height: 50px;
  flex: none;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}
.setting-content{
  /* border: solid 1px red; */
  height: 500px;
  flex: auto;
  display: flex;
}
.setting-item{
  border: solid 1px rgba(208, 208, 214, 0.452);
  width: 230px;
  flex: none;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.setting-item-header{
  border-bottom: solid 1px rgba(208, 208, 214, 0.452);
  height: 30px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}
.setting-item-content{
  /* border: solid 1px red; */
  height: 300px;
  flex: auto;
  padding: 5px 10px;
  overflow: auto;
}
.file-input-area{
  width: 0;
  height: 0;
}
.preview-image-area{
  width: 800px;
}
.chk-item{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin-bottom: 5px;
}
.alarm-type{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin: 5px;
  padding: 5px;
}
.alarm-type-name{
  padding: 5px 0 1px 0;
  border-bottom: solid 1px rgba(219, 175, 175, 0.568);
  margin-bottom: 10px;
}
.alarm-level-container{
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* align-content: stretch; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-level-item{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 3px;
  width: 300px;
  flex: auto;
  margin: 5px;
  display: flex;
  flex-direction: column;
}
.level-item-header{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 10px;
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.level-item-content{
  border: solid 1px rgba(219, 175, 175, 0.568);
  height: 400px;
  flex: auto;
}
.header-title{
  /* border: solid 1px red; */
  width: 100px;
  flex: auto;
  font-weight: bolder;
  font-size: 20px;
}
.header-btn{
  /* margin: 10px; */
  flex: none;
  /* border: solid 1px red; */
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>