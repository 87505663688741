<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">查看访问明细，时间：{{item.createTime}}，IP：{{item.ip}}</div>
    <Form ref="form" :label-width="100">
      <FormItem prop="name" label="接口名称">
        {{item.name}}，URL：{{item.url}} 
      </FormItem>
      <FormItem prop="args" label="请求参数">
        {{args}}
      </FormItem>
      <FormItem prop="content" label="响应内容">
        {{content}}
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'ModalAccessDetail',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    }
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      index: -1,
      args: {},
      content: {},
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(this.showModal){
        this.getData();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  methods: {
    getData: function(){
      this.$set(this, 'args', this.item.arguments);
      this.$set(this, 'content', this.item.content);
      // this.$axios.post(`//${this.domains.trans}/sys/open/QueryAccessDetail`, {id: this.item.id}).then(res => {
      //   if(res.code != 0){
      //     // this.$Message.error(res.data);
      //   }else{
      //     this.$set(this, 'args', res.data.args);
      //     this.$set(this, 'content', res.data.content);
      //   }
      // });
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.float-chk{
  position: absolute;
  top: 8px;
  right: 30px;
}
.form{
  height: calc(100%);
  overflow: auto;
}
.readonly{
  background: #eee
}
.fun-container{
  border: solid 1px rgba(216, 199, 199, 0.438);
  border-radius: 6px;
  height: 100%;
  overflow: auto;
}
.fun-list{
  display: flex;
  /* list-style: none; */
  flex-direction: column;
  margin: 8px 15px;
}
.chk-list{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
li{
  margin: 0 5px;
}
</style>