<template>
<Form ref="form" :model="form" label-position="top" style="margin-top: 30px; width: 400px">
  <FormItem prop="energyPrice" label="可访问IP列表">
    <div class="ip-list-area">
      <div class="ip-list-item">
        <div class="ip-item-header">新增：</div>
        <div class="ip-item-title">
          <Input v-model="ip" type="text"></Input>
        </div>
        <div class="ip-item-btn"><Button size="small" type="info" style="margin-right: 5px" @click="addIp">增加</Button></div>
      </div>
      <div class="ip-list-item" v-for="(item, idx) in ips" :key="idx">
        <div class="ip-item-header">{{ idx + 1 }}、</div>
        <div class="ip-item-title">{{ item }}</div>
        <div class="ip-item-btn"><Button size="small" type="error" style="margin-right: 5px" @click="removeIp(idx)">移除</Button></div>
      </div>
    </div>
  </FormItem>
  <FormItem prop="expired" :label-width="0">
    <AuthButton opCode='ssae' size="default" long type="primary" style="margin-right: 5px" @click="saveSetting">保存</AuthButton>
  </FormItem>
</Form>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'AlarmSettingIndex',
  components:{
  },
  props: {
  },
  data () {
    return {
      tabHeight: 600,
      loading: false,
      ips: [],
      ip: '',
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['alarmLevels', 'productCodes', 'alarmTypes', 'alarmGroupList']),
  },
  watch: {
    windowOnResize(){
    },
  },
  mounted: function(){
    this.getList();
    // this.getStationAreaSort();
  },
  destroyed: function(){
  },
  methods: {
    isIp: function(ip){
      ip = ip.trim();
      if(ip.length == 0 || ip.length > 15){
        this.$Message.warning('IP长度不对');
        return false;
      }
      let ips = ip.split('.');
      if(ips.length != 4){
        this.$Message.warning('IP格式不对');
        return false;
      }
      for(let i of ips){
        if(i < 0 || i > 255){
        this.$Message.warning('IP数字不对');
        return false;
        }
      }
      return true;
    },
    addIp: function(){
      let ip = this.ip.trim();
      if(!this.isIp(ip)){
        return false;
      }
      if(this.ips.includes(ip)){
        this.$Message.warning('IP已经在列表中');
        return false;
      }
      this.ips.push(ip);
    },
    removeIp: function(idx){
      if(this.ips.length == 1){
        this.$Message.warning('至少保留一个IP');
        return false;
      }
      if(idx >= 0){
        this.ips.splice(idx, 1);
      }
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/sys/setting/QueryIPWhiteList`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'ips', res.data);
        }
      });
    },
    saveSetting: function(){
      if(this.ips.length == 0){
        this.$Message.warning('至少保留一个可访问IP');
        return false;
      }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/sys/setting/SaveIPWhiteList`, {ips: this.ips}).then(res => {
      this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功')
        }
      });
    },
  }
}
</script>
<style scoped>
.ip-list-area{
  border: solid 1px rgba(208, 208, 214, 0.452);
  height: 500px;
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ip-list-item{
  border-bottom: solid 1px rgba(208, 208, 214, 0.452);
  height: 40px;
  flex: none;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.ip-item-header{
  /* border: solid 1px red; */
  width: 50px;
  flex: none;
  text-align: right;
  padding-right: 5px;
}
.ip-item-title{
  /* border: solid 1px red; */
  width: 300px;
  flex: auto;
}
.ip-item-btn{
  /* border: solid 1px red; */
  text-align: center;
  width: 60px;
  flex: none;
}
.area-title{
  font-weight: bolder;
  font-size: 20px;
}
.area-list{
  display: flex;
  flex-direction: column;
}
.area-item{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.sort-title{
  width: 140px;
  text-align: right;
  padding-right: 10px;
  flex: none;
}
.chk-item{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin-bottom: 5px;
}
.alarm-type{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin: 5px;
  padding: 5px;
}
.alarm-type-name{
  padding: 5px 0 1px 0;
  border-bottom: solid 1px rgba(219, 175, 175, 0.568);
  margin-bottom: 10px;
}
.alarm-level-container{
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* align-content: stretch; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-level-item{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 3px;
  width: 300px;
  flex: auto;
  margin: 5px;
  display: flex;
  flex-direction: column;
}
.level-item-header{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 10px;
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.level-item-content{
  border: solid 1px rgba(219, 175, 175, 0.568);
  height: 400px;
  flex: auto;
}
.header-title{
  /* border: solid 1px red; */
  width: 100px;
  flex: auto;
  font-weight: bolder;
  font-size: 20px;
}
.header-btn{
  /* margin: 10px; */
  flex: none;
  /* border: solid 1px red; */
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>