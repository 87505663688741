<template>
  <div class="light-control-container">
    <MenuCommon v-if="args.type == 'common'" class="light-control-menu" @cmd="cmdGot" />
    <ListCommon ref="lightList1" class="light-control-list" :type="args.type" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import MenuCommon from '@/components/control/light/menus/Common'
import ListCommon from '@/components/control/light/list/newCommon'
export default {
  name: 'CommandLightIndex',
  components: {
    MenuCommon,
    ListCommon,
  },
  props: {
    args: {
      type: Object,
      default() {
        return {
          type: 'common',
        }
      }
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs']),
  },
  watch: {
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    cmdGot: function (params) {
      // console.log('cmd got', params)
      this.$refs.lightList1.dealCmd(params);
    },
  }
}
</script>
<style scoped>
.light-control-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
}
.light-control-menu {
  /* border: solid 1px red; */
  height: 50px;
  flex: none;
}
.light-control-list {
  /* border: solid 1px red; */
  height: 300px;
  flex: auto;
}
</style>