<template>
  <div class="container" :style="{'background-image':model==2? `url(${isShowImg()})`:''}">
    <!-- <Option :value="1">地图</Option>
      <Option :value="2">静态图片</Option>
      <Option :value="3">多层图片</Option> -->
    <div>
      <template v-if="model==1">
        <gisPage :key="keyIndex" />
      </template>
      <template v-if="model==2">
        <manyImg :key="keyIndex" />
      </template>
      <template v-if="model==3">
        <gisPage :key="keyIndex" />
      </template>
    </div>

    <div class="container-box">
      <div class="container-left">
        <div class="modular-box" style="height: 100%;">
          <deviceBox />
        </div>
        <div class="modular-box mt30">
          <videoBox />
        </div>
      </div>
      <div class="container-lower">
        <div class="modular-box">
          <energyBox />
        </div>
      </div>
      <div class="container-right">
        <div class="modular-box">
          <alarmBox />
        </div>
        <div class="modular-box mt30" style="height: 100%;">
          <alarmTimeBox />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import deviceBox from '@/components/carat/home/deviceBox'
import videoBox from '@/components/carat/home/videoBox'
import energyBox from '@/components/carat/home/energyBox'
import alarmTimeBox from '@/components/carat/home/alarmTimeBox'
import alarmBox from '@/components/carat/home/alarmBox'
import gisPage from '@/components/carat/home/gis'
import manyImg from '@/components/carat/home/manyImg'
export default {
  name: 'Home',
  components: {
    deviceBox,
    videoBox,
    energyBox,
    alarmTimeBox,
    alarmBox,
    gisPage,
    manyImg,
  },
  data() {
    return {
      chart: null,
      option: null,
      model: 0,
      keyIndex: 0
    }
  },
  watch: {
    model() {
      this.keyIndex++
    }
  },
  computed: {
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'agentChangeValue']),
    ...mapState('common', ['weeks', 'powerBoxAlarmTypes']),
    ...mapState("group", ["selectedDevices", "selectedNode"]),
  },
  mounted: function () {
    setTimeout(() => {
      this.model = this.config.homeCenterStype;
      if (this.model == undefined) {
        setTimeout(() => {
          this.model = this.config.homeCenterStype;
        }, 500);
      }
    }, 500);

  },
  destroyed: function () {
  },
  methods: {
    isShowImg() {
      return `//${this.domains.trans}/station/file/${this.config.homeCenterImage}`
    }
  }
}
</script>
<style scoped>
.tooltip-content {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  /* padding: 15px 10px; */
}
.tooltip-content-header {
  width: 100%;
  height: 28px;
  background: linear-gradient(90deg, #49e1fc, #49a6fc);
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021727;
  line-height: 28px;
  text-align: left;
  text-indent: 15px;
}
.tooltip-content-center {
  /* border: solid 1px red; */
  flex: auto;
  padding: 5px 15px;
}
.tooltip-content-footer {
  /* border: solid 1px red; */
  height: 50px;
  flex: auto;
  padding: 0 15px;
}
.tooltip-name {
  /* border: solid 1px red; */
  height: 30px;
  line-height: 30px;
  flex: none;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.tooltip-area {
  /* border: solid 1px red; */
  height: 20px;
  line-height: 20px;
  flex: none;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.tooltip-energy {
  height: 35px;
  flex: auto;
  display: flex;
  align-items: center;
}
.tooltip-energy-name {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
  width: 90px;
  flex: none;
}
.tooltip-energy-value {
  /* border: solid 1px red; */
  font-size: 16px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #51e7f3;
  text-align: left;
  width: 80px;
  flex: auto;
}
.tooltip-energy-avg {
  /* border: solid 1px red; */
  font-size: 16px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #f2cf41;
  text-align: left;
  width: 80px;
  flex: auto;
}
.container {
  width: 100%;
  height: 100%;
  background-color: #010b13;
  background-size: 100% auto;
  background-repeat: no-repeat;
  display: flex;
  /* border: solid 1px red; */
  overflow: auto;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0 0 30px 0;
  /* margin: 20px 0 40px 0; */
  position: relative;
}
.container::-webkit-scrollbar {
  display: none;
}

.status-count-item {
  /* border: solid 1px red; */
  height: 10px;
  flex: auto;
  display: flex;
  flex-direction: column;
}

.map-tooltip {
  position: absolute;
  height: 184px;
  background: rgba(13, 44, 77, 0.8);
  z-index: 500;
}
.tooltop-border {
  position: absolute;
  width: 7px;
  height: 7px;
  border: 2px solid #49e1fc;
  opacity: 0.8;
}
.container-map {
  /* border: solid 1px red; */
  /* position: absolute;
  left: 20%;
  top: 150px;
  width: 55%;
  height: 700px; */
}
.center-pointer {
  position: absolute;
  padding-top: 2px;
  cursor: pointer;
}
.container-name {
  padding: 0 10px;
  height: 24px;
  background: linear-gradient(135deg, #49e1fc, #49a6fc);
  border-radius: 4px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #011121;
  line-height: 24px;
}
.container-triangle {
  width: 0;
  height: 0;
  border-top: 12px solid #49a6fc;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  margin-top: 4px;
}
.container-box {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 100%;
}
.mt30 {
  margin-top: 30px;
}
.container-left,
.container-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.container-lower {
  width: calc(100% - 980px);
}
</style>
