<template>
  <Modal v-model="showModal" :mask-closable="false" draggable width="1000px">
    <div slot="header">错误信息</div>
    <Form ref="form" :label-width="80">
      <FormItem :label-width="0">
        <div ref="messageContent" class="message-content">
          <div v-for="(item, idx) in tabData" :key="idx">
            {{idx+1}}、{{item.item1}} - {{item.item2}}
          </div>
          <div style="margin-bottom: 50px;"></div>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
      <Button type="info" :loading="loading" @click="initData" style="margin-right: 10px;">查询</Button>
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'ModalTaskError',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      tabData: [],
    }
  },
  watch: {
    msgs(){
      this.$refs.messageContent.scrollTop = this.$refs.messageContent.scrollHeight;
    },
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.initData();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  computed: {
  },
  mounted: function(){
  },
  methods: {
    initData: async function () {
      this.$axios.post(`//${this.domains.trans}/station/job/QueryError`, {id: this.item.data.id}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'tabData', res.data);
        }
      });
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.message-content{
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
}
</style>