<template>
<div ref="table1" class="setting-table">
  <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" @checkbox-change="selectChangeEvent" :data="cols" :height="tabHeight" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',highlight: true}">
    <vxe-column width="50" type="seq"></vxe-column>
    <vxe-column width="50" type="checkbox" field="checked"></vxe-column>
    <vxe-column field="name" title="名称" width="150">
      <template #default="{ row }">
        {{row.name}}
      </template>
    </vxe-column>
    <vxe-column>
      <template slot="header">
        <AuthButton opCode='slce' style="margin-right: 5px" @click="saveSetting">保存</AuthButton>
        <AuthButton opCode='slcv' style="margin-right: 5px" @click="getList">刷新</AuthButton>
      </template>
    </vxe-column>
  </vxe-table>
</div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'MonitorCommand',
  components:{
  },
  props: {
  },
  data () {
    return {
      tabHeight: 600,
      loading: false,
      cols: [],
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['alarmLevels', 'productCodes', 'alarmTypes', 'alarmGroupList']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 100);
    this.getList();
  },
  destroyed: function(){
  },
  methods: {
    setTableHeight: function(){
      this.tabHeight = this.$refs.table1.clientHeight
      // console.log('set tab height', this.tabHeight, this.$refs.table1.clientHeight)
    },
    selectChangeEvent: function(){
          // console.log('=chk e', e);
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/sys/setting/QueryMonitorCommands`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          if(!res.data.show){
            res.data.show = [];
          }
          // console.log('init cols', res.data);
          let cols = [];
          for(let c in res.data.all){
            cols.push({
              code: c,
              name: res.data.all[c],
              checked: res.data.show.indexOf(c) != -1,
            });
          }
          this.$set(this, 'cols', cols);
          // console.log('init cols', cols);
        }
      });
    },
    saveSetting: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let codes = chks.map(p => p.code);
      if(codes.length == 0){
        this.$Message.warning('请选择要显示的指令');
        return;
      }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/sys/setting/SaveMonitorCommands`, {show: codes}).then(res => {
      this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功')
        }
      });
    },
  }
}
</script>
<style scoped>
.setting-table{
  /* border: solid 1px red; */
  height: 100%;
}
.file-input-area{
  width: 0;
  height: 0;
}
.preview-image-area{
  width: 800px;
}
.chk-item{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin-bottom: 5px;
}
.alarm-type{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin: 5px;
  padding: 5px;
}
.alarm-type-name{
  padding: 5px 0 1px 0;
  border-bottom: solid 1px rgba(219, 175, 175, 0.568);
  margin-bottom: 10px;
}
.alarm-level-container{
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* align-content: stretch; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-level-item{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 3px;
  width: 300px;
  flex: auto;
  margin: 5px;
  display: flex;
  flex-direction: column;
}
.level-item-header{
  border: solid 1px rgba(219, 175, 175, 0.568);
  padding: 10px;
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.level-item-content{
  border: solid 1px rgba(219, 175, 175, 0.568);
  height: 400px;
  flex: auto;
}
.header-title{
  /* border: solid 1px red; */
  width: 100px;
  flex: auto;
  font-weight: bolder;
  font-size: 20px;
}
.header-btn{
  /* margin: 10px; */
  flex: none;
  /* border: solid 1px red; */
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>