<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">{{command.name}}</div>
    <Form :model="form" :label-width="120">
      <FormItem label="确认密码" v-if="needCheck">
        <div v-if="needCheck">
          <Input v-model="form.pswd" type="password" style="width: 120px;height:32px;"></Input>
          <span style="color:red;margin-left:20px">***  此操作需要确认用户密码</span>
        </div>
        <div v-else>密码还在有效期中</div>
      </FormItem>
      <FormItem label="子串口号" v-if="['setComArgs', 'getComArgs'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.comname">
          <template v-for="(v, k) in coms8088names">
            <Option :key="k" :value="parseInt(k)">{{v}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem label="通讯方式" v-if="['setComArgs'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.comtype">
          <template v-for="(v, k) in com8088types">
            <Option :key="k" :value="parseInt(k)">{{v}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem label="波特率" v-if="['setComArgs'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.combuad">
          <template v-for="(v, k) in com8088buads">
            <Option :key="k" :value="parseInt(k)">{{v}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem label="数据位" v-if="['setComArgs'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.comdata">
          <template v-for="(v, k) in com8088datas">
            <Option :key="k" :value="parseInt(k)">{{v}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem label="校验位" v-if="['setComArgs'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.comparity">
          <template v-for="(v, k) in com8088paritys">
            <Option :key="k" :value="parseInt(k)">{{v}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem label="停止位" v-if="['setComArgs'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.comstop">
          <template v-for="(v, k) in com8088stops">
            <Option :key="k" :value="parseInt(k)">{{v}}</Option>
          </template>
        </Select>
      </FormItem>
      <!-- <FormItem label="启用周开关灯" v-if="['setWeekControl'].indexOf(command.code) != -1">
        <table>
          <tr v-for="n in 7" :key="n">
            <td>{{weeks1[n]}}</td>
            <td><Checkbox v-model="form.weekCtrls[(n - 1) * 2]">上午</Checkbox></td>
            <td><Checkbox v-model="form.weekCtrls[(n - 1) * 2 + 1]">下午</Checkbox></td>
          </tr>
        </table>
      </FormItem> -->
      <FormItem label="轻微漏电阈值" v-if="['setLeakageRate'].indexOf(command.code) != -1">
        <Input v-model="form.ll" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="严重漏电阈值" v-if="['setLeakageRate'].indexOf(command.code) != -1">
        <Input v-model="form.lh" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="跳闸漏电阈值" v-if="['setLeakageRate'].indexOf(command.code) != -1">
        <Input v-model="form.lx" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="IP或域名" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.host" type="text" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="端口" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.port" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="心跳时间" v-if="['setNetwork'].indexOf(command.code) != -1">
        <Input v-model="form.heart" type="number" style="width: 120px;height:32px;"><span slot="append">秒</span></Input>
      </FormItem>
      <FormItem label="通道" v-if="['setLoraWork'].indexOf(command.code) != -1">
        <Input v-model="form.channel" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <!-- <FormItem label="速率" v-if="['setLoraWork'].indexOf(command.code) != -1">
        <Input v-model="form.speed" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem> -->
      <!-- <FormItem label="电流倍率" v-if="['setCurrentRate'].indexOf(command.code) != -1">
        <Input v-model="form.currentRate" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem> -->
      <FormItem label="查看日期" v-if="['getYearPlan'].indexOf(command.code) != -1">
        <div style="display: flex;align-items:center;">
          <!-- <i-switch v-model="form.single" size="large" style="margin:3px 10px;" true-color="green" false-color="blue">
            <span slot="open">单日</span>
            <span slot="close">整月</span>
          </i-switch> -->
          <DatePicker type="date" v-model="form.date" format="yyyy-MM-dd"></DatePicker>
        </div>
      </FormItem>
      <FormItem label="延时时间" v-if="['handControl'].indexOf(command.code) != -1">
        <div v-if="form.expire" style="font-size: 40px; margin: 20px">
          {{new Date(form.expire).format('yyyy-MM-dd HH:mm:ss')}}
        </div>
        <div style="display: flex;align-items:center;">
          <i-switch v-model="form.open" size="large" style="margin:3px 10px;" true-color="red" false-color="gray">
            <span slot="open">开灯</span>
            <span slot="close">关灯</span>
          </i-switch>
          <DatePicker type="datetime" v-model="form.expire" style="width: 170px" format="yyyy-MM-dd HH:mm:ss"></DatePicker>
        </div>
      </FormItem>
      <FormItem label="每包大小" v-if="['update'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.packLen" type="number" number style="width: 120px; height: 32px">
            <span slot="append">字节</span>
          </Input>
        </div>
      </FormItem>
      <FormItem label="升级文件" v-if="['update'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <div class="logo-upload">
            <input ref="fileInput" type="file" accept=".bin" :disabled="loading"/>
          </div>
        </div>
      </FormItem>
      <FormItem label="电压是否报警" v-if="['setAlarmStatus'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <i-switch v-model="form.voltageAlarm" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
        </div>
      </FormItem>
      <FormItem label="电流是否报警" v-if="['setAlarmStatus'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <i-switch v-model="form.currentAlarm" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
        </div>
      </FormItem>
      <FormItem label="温度是否报警" v-if="['setAlarmStatus'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <i-switch v-model="form.tempAlarm" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
        </div>
      </FormItem>
      <FormItem label="漏电是否报警" v-if="['setAlarmStatus', 'setLeakageAlarmEnable'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <i-switch v-model="form.leakageAlarm" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="red">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
        </div>
      </FormItem>
      <FormItem label="通道1延时" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.keep1" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">秒</span>
          </Input>
          <Slider v-model="form.keep1" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="通道2延时" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.keep2" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">秒</span>
          </Input>
          <Slider v-model="form.keep2" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="启动感应控制" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.sensor">
          <Option :value="1">有效</Option>
          <Option :value="2">无效</Option>
          <Option :value="0">不修改</Option>
        </Select>
      </FormItem>
      <FormItem label="是否发送433信号" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.txen">
          <Option :value="128">有效</Option>
          <Option :value="2">无效</Option>
          <Option :value="0">不修改</Option>
        </Select>
      </FormItem>
      <FormItem label="发送周期" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.period" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">秒</span>
          </Input>
          <Slider v-model="form.period" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="联动编号" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.rfsn" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.rfsn" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="433功率" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.txpower" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.txpower" :min="0" :max="8" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem :label="command.code=='setUnionParam' ? '433频道':'信道'" v-if="['setUnionParam', 'setLoraChannel'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.channel" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.channel" :min="0" :max="100" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="编号范围" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.range" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.range" :min="0" :max="255" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="是否接收信号" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.rxen">
          <Option :value="1">是</Option>
          <Option :value="2">否</Option>
          <Option :value="0">不修改</Option>
        </Select>
      </FormItem>
      <FormItem label="联动方式" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <Select v-model="form.mode">
          <Option :value="1">联动控制</Option>
          <Option :value="2">灯杆互联</Option>
          <Option :value="0">不修改</Option>
        </Select>
      </FormItem>
      <FormItem label="重发次数" v-if="['setUnionParam'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.repeat" type="number" number style="width: 120px;height:32px;">
          <!-- <span slot="prepend">http://</span> -->
          <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.repeat" :min="0" :max="10" style="flex:auto;margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="检查时间" v-if="['setLeakageAlarmEnable'].indexOf(command.code) != -1">
        <Input v-model="form.checkTime" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="上报次数" v-if="['setLeakageAlarmEnable'].indexOf(command.code) != -1">
        <Input v-model="form.uploadCount" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="参数来源" v-if="['setDayPlan1', 'setDayPlan2', 'handGroup'].indexOf(command.code) != -1" class="form-item">
        <i-switch v-model="form.useConfig" size="large" style="margin:3px 3px 0 0" true-color="green" false-color="blue">
          <span slot="open">选择</span>
          <span slot="close">录入</span>
        </i-switch>
      </FormItem>
      <FormItem label="选择分组" v-if="['handGroup'].indexOf(command.code) != -1 && form.useConfig" class="form-item">
        <Select v-model="form.areaId" style="width: 220px;" @on-change="areaChanged">
          <template v-for="(s, idx) in areas">
            <Option :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem label="分组编号" v-if="['handGroup'].indexOf(command.code) != -1 && !form.useConfig" class="form-item">
        <Input v-model="form.groupNo" type="number" number style="width: 120px; height: 32px">
        <!-- <span slot="append">字节</span> -->
        </Input>
      </FormItem>
      <FormItem label="亮度1" v-if="['handSingle', 'handGroup'].indexOf(command.code) != -1">
        <Input v-model="form.outputPower1" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="亮度2" v-if="['handSingle', 'handGroup'].indexOf(command.code) != -1">
        <Input v-model="form.outputPower2" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="色温" v-if="['handSingle', 'handGroup'].indexOf(command.code) != -1">
        <Input v-model="form.outputColor" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="延时时间" v-if="['handSingle', 'openSingle', 'closeSingle', 'handGroup'].indexOf(command.code) != -1">
        <div v-if="form.expire" style="font-size: 40px; margin: 20px">
          {{new Date(form.expire).format('yyyy-MM-dd HH:mm:ss')}}
        </div>
        <div style="display: flex;align-items:center;">
          <!-- <i-switch v-model="form.open" size="large" style="margin:3px 10px;" true-color="red" false-color="gray">
            <span slot="open">开灯</span>
            <span slot="close">关灯</span>
          </i-switch> -->
          <DatePicker type="datetime" v-model="form.expire" style="width: 170px" format="yyyy-MM-dd HH:mm:ss"></DatePicker>
        </div>
      </FormItem>
      <FormItem label="时间表" v-if="['setDayPlan1'].indexOf(command.code) != -1 && form.useConfig" class="form-item">
        <Select v-model="form.timeId">
          <template v-for="item in timeTables">
            <Option v-if="item.type == '计时日表'" :key="item.id" :value="item.id">[{{item.type}}]{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem label="时间表" v-if="['setDayPlan2'].indexOf(command.code) != -1 && form.useConfig" class="form-item">
        <Select v-model="form.timeId">
          <template v-for="item in timeTables">
            <Option v-if="item.type == '准时日表'" :key="item.id" :value="item.id">[{{item.type}}]{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem label="" :label-width="0" v-if="['setDayPlan1'].indexOf(command.code) != -1 && !form.useConfig" class="form-item">
        <table class="set-table">
          <tr>
            <td rowspan="2">时段</td>
            <td rowspan="2" style="width: 150px">时间</td>
            <td colspan="2" v-for="n in 4" :key="n">通道{{n}}</td>
          </tr>
          <tr>
            <template v-for="n in 4">
              <td :key="`bright-${n}`">亮度</td>
              <td :key="`color-${n}`">色温</td>
            </template>
          </tr>
          <template v-for="m in 6">
            <tr :key="m" v-if="form.timeContent1[m-1]">
              <td>时段{{m}}</td>
              <td>
                <Input style="width: 100px" ref="min" type="number" :min="0" :max="100" number v-model="form.timeContent1[m-1].min" placeholder=""></Input>
              </td>
              <template v-for="n in 4">
                <td :key="`bright-${n}`"><Input class="min-width" ref="min" type="number" :min="0" :max="100" number v-model="form.timeContent1[m-1][`ch${n}`]" placeholder=""></Input></td>
                <td :key="`color-${n}`"><Input class="min-width" ref="min" type="number" :min="0" :max="100" number v-model="form.timeContent1[m-1][`c${n}`]" placeholder=""></Input></td>
              </template>
            </tr>
          </template>
        </table>
      </FormItem>
      <FormItem label="" :label-width="0" v-if="['setDayPlan2'].indexOf(command.code) != -1 && !form.useConfig" class="form-item">
        <table class="set-table">
          <tr>
            <td rowspan="2">时段</td>
            <td rowspan="2" style="width: 150px">时间</td>
            <td colspan="2" v-for="n in 4" :key="n">通道{{n}}</td>
          </tr>
          <tr>
            <template v-for="n in 4">
              <td :key="`bright-${n}`">亮度</td>
              <td :key="`color-${n}`">色温</td>
            </template>
          </tr>
          <template v-for="m in 6">
            <tr :key="m" v-if="form.timeContent2[m-1]">
              <td>时段{{m}}</td>
              <td>
                <TimePicker style="width: 100px" v-model="form.timeContent2[m-1].min" format="HH:mm" placeholder="选择时间"></TimePicker>
              </td>
              <template v-for="n in 4">
                <td :key="`bright-${n}`"><Input class="min-width" ref="min" type="number" :min="0" :max="100" number v-model="form.timeContent2[m-1][`ch${n}`]" placeholder=""></Input></td>
                <td :key="`color-${n}`"><Input class="min-width" ref="min" type="number" :min="0" :max="100" number v-model="form.timeContent2[m-1][`c${n}`]" placeholder=""></Input></td>
              </template>
            </tr>
          </template>
        </table>
      </FormItem>
      <FormItem label="日表类型" v-if="['setPlanType'].indexOf(command.code) != -1" class="form-item">
        <RadioGroup v-model="form.planType">
          <Radio :label="0">计时日表</Radio>
          <Radio :label="1">准时日表</Radio>
          <Radio :label="2">光控模式</Radio>
        </RadioGroup>
      </FormItem>
      <!-- <FormItem label="分组编号" v-if="['setGroup'].indexOf(command.code) != -1" class="form-item">
        <Input v-model="form.groupNo" type="number" number style="width: 120px; height: 32px">
        </Input>
      </FormItem> -->
      <FormItem label="光感值" v-if="['setLuxOpenValue'].indexOf(command.code) != -1" class="form-item">
        <Input v-model="form.lux" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="append">字节</span> -->
        </Input>
      </FormItem>
      <FormItem label="IP端口" v-if="['ftp'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.ipport" type="text" style="width: 220px; height: 32px">
          </Input>
        </div>
      </FormItem>
      <FormItem label="文件路径" v-if="['ftp'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.file" type="text" style="width: 220px; height: 32px">
          </Input>
        </div>
      </FormItem>
      <FormItem label="登录账号" v-if="['ftp'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.user" type="text" style="width: 220px; height: 32px">
          </Input>
        </div>
      </FormItem>
      <FormItem label="FTP密码" v-if="['ftp'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.pswd1" type="text" style="width: 220px; height: 32px">
          </Input>
        </div>
      </FormItem>
      <FormItem label="文件大小" v-if="['ftp'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.len" type="number" number style="width: 220px; height: 32px">
            <span slot="append">字节</span>
          </Input>
        </div>
      </FormItem>
      <FormItem label="校验字" v-if="['ftp'].indexOf(command.code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.crc" type="text" style="width: 220px; height: 32px">
          </Input>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" @click="ok">发送</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalArgEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    command: {
      type: Object,
      default(){ return {};}
    },
    areas: {
      type: Array,
      default(){ return [];}
    }
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      events: {},
      timeTables: [],
      form: {
        areaId: 0, //单灯分组ID
        packLen: 512, //OTA升级包大小
        content: null, //OTA文件内容
        pswd: '', //录入密码
        single: true, //显示单日或整月
        date: '', //日期
        expire: '', //过期时间
        open: true, //开关灯
        currentRate: 1000, //设置电流倍率
        useConfig: true, //是否直接使用配置，true则选择使用配置，false直接输入配置内容
        timeId: 0, //时间表
        timeContent1: { //计时日表时间表内容
          "0":{ "min":100,"ch1":10,"c1":20,"un1":30,"ch2":40,"c2":50,"un2":60,"ch3":70,"c3":80,"un3":90,"ch4":0,"c4":0,"un4":0},
          "1":{ "min":200,"ch1":10,"c1":20,"un1":30,"ch2":40,"c2":50,"un2":60,"ch3":70,"c3":80,"un3":90,"ch4":0,"c4":0,"un4":0},
          "2":{ "min":300,"ch1":10,"c1":20,"un1":30,"ch2":40,"c2":50,"un2":60,"ch3":70,"c3":80,"un3":90,"ch4":0,"c4":0,"un4":0},
          "3":{ "min":400,"ch1":10,"c1":20,"un1":30,"ch2":40,"c2":50,"un2":60,"ch3":70,"c3":80,"un3":90,"ch4":0,"c4":0,"un4":0},
          "4":{ "min":500,"ch1":10,"c1":20,"un1":30,"ch2":40,"c2":50,"un2":60,"ch3":70,"c3":80,"un3":90,"ch4":0,"c4":0,"un4":0},
          "5":{ "min":1440,"ch1":10,"c1":20,"un1":30,"ch2":40,"c2":50,"un2":60,"ch3":70,"c3":80,"un3":90,"ch4":0,"c4":0,"un4":0}
        },
        timeContent2: { //准时日表时间表内容
          "0":{ "min":'00:00',"ch1":10,"c1":20,"un1":30,"ch2":40,"c2":50,"un2":60,"ch3":70,"c3":80,"un3":90,"ch4":0,"c4":0,"un4":0},
          "1":{ "min":'00:00',"ch1":10,"c1":20,"un1":30,"ch2":40,"c2":50,"un2":60,"ch3":70,"c3":80,"un3":90,"ch4":0,"c4":0,"un4":0},
          "2":{ "min":'00:00',"ch1":10,"c1":20,"un1":30,"ch2":40,"c2":50,"un2":60,"ch3":70,"c3":80,"un3":90,"ch4":0,"c4":0,"un4":0},
          "3":{ "min":'00:00',"ch1":10,"c1":20,"un1":30,"ch2":40,"c2":50,"un2":60,"ch3":70,"c3":80,"un3":90,"ch4":0,"c4":0,"un4":0},
          "4":{ "min":'00:00',"ch1":10,"c1":20,"un1":30,"ch2":40,"c2":50,"un2":60,"ch3":70,"c3":80,"un3":90,"ch4":0,"c4":0,"un4":0},
          "5":{ "min":'00:00',"ch1":10,"c1":20,"un1":30,"ch2":40,"c2":50,"un2":60,"ch3":70,"c3":80,"un3":90,"ch4":0,"c4":0,"un4":0}
        },
        planType: 0, //日表模式
        groupNo: 0, //分组编号
        outputPower1: 0, //调光亮度1
        outputPower2: 0, //调光亮度2
        outputColor: 0, //调光色温
        host: '', //ip或域名
        port: 1000, //端口
        heart: 30, //心跳时间，单位秒
        ll: 30, //轻微漏电阈值
        lh: 100, //严重漏电阈值
        lx: 2000, //跳闸漏电阈值
        channel: 1, //通道
        speed: 7, //速率，默认7
        weekCtrls: [true, true, true, true, true, true, true, true, true, true, true, true, true, true],
        comname: 1,
        comtype: 1,
        combuad: 6,
        comdata: 8,
        comparity: 0,
        comstop: 1,
        lux: 100, //光感值
        ipport: '211.162.119.217:20010',
        file: 'bin/App8051v515.bin',
        user: 'amdm',
        pswd1: '0755@123.com',
        len: 109492,
        crc: '9CDD',
        voltageAlarm: false,//电压是否报警
        currentAlarm: false,//电流是否报警
        tempAlarm: false,//温度是否报警
        leakageAlarm: false, //漏电是否报警
        checkTime: 15, //检查时间
        uploadCount: 1, //上报次数
        rfsn: 1, //联动模块编号
        // channel: 0, //433应用频道
        txpower: 3, //433发送功率
        txen: 128, //是否发送433信号,对于检测方可以发送，其他的只能RX
        repeat: 1, //重发次数 0~10有效，默认2
        period: 3, //重发周期
        range: 5, //响应范围，这个对发送方无意义，对接收方有用
        rxen: 1, //是否接收433联动信号  1=允许接收
        mode: 2, //联动方式， 1=按Range ,2:全局，Range无效
        keep1: 10, //灯1延时时间（秒）
        keep2: 1, //灯2延时时间（秒）
        sensor: 1, //是否启用感应启用，RX方只管自己
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['needCheckPswd']),
    ...mapState('group', ['groups', 'selectedNode', 'selectedGroups', 'rootTitle']),
    ...mapState('common', ['deviceTypes', 'productCodes', 'weeks1', 'ampm', 'coms8088names', 'com8088types', 'com8088buads', 'com8088datas', 'com8088paritys', 'com8088stops']),
    needCheck: function(){
      return this.needCheckPswd && this.command.pswd;
    },
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.form.content = null;
        this.form.packLen = 512;
        switch(this.command.code){
          case 'setEventEnable':{
            this.getEvents();
            break;
          }
          case 'getYearPlan': {
            this.form.single = this.command.args.single;
            this.form.date = this.command.args.date;
            break;
          }
          case 'handControl': {
            this.form.open = this.command.args.open;
            this.form.expire = this.command.args.expire;
            break;
          }
          case 'setCurrentRate': {
            this.form.currentRate = this.command.args.currentRate;
            break;
          }
          case 'setLeakageRate':{
            this.form.ll = this.command.args.ll;
            this.form.lh = this.command.args.lh;
            this.form.lx = this.command.args.lx;
            break;
          }
          case 'handSingle':
          case 'closeSingle':
          case 'openSingle': {
            // this.form.open = this.command.args.open;
            let d = new Date();
            d.setHours(d.getHours() + 1);
            this.form.expire = d.format('yyyy-MM-dd HH:mm:ss');
            this.form.groupNo = 1;
            break;
          }
          case 'handGroup':{
            let d = new Date();
            d.setHours(d.getHours() + 1);
            this.form.expire = d.format('yyyy-MM-dd HH:mm:ss');
            this.form.groupNo = 1;
            this.form.areaId = 0;
            break;
          }
          case 'setDayPlan1':
          case 'setDayPlan2': {
            this.form.timeId = 0;
            this.getUseablePlan();
            break;
          }
        }
        // console.log('show command', this.command)
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  methods: {
    areaChanged: function(){
      let ass = this.areas.filter(p => p.id == this.form.areaId);
      if(ass.length == 0)return;
      this.form.groupNo = ass[0].code;
      this.form.outputPower1 = ass[0].bright;
      this.form.outputPower2 = ass[0].bright;
      this.form.outputColor = ass[0].color;
    },
    getUseablePlan: function(){
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLightPlan`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'timeTables', res.data);
        }
      });
    },
    getEvents: function(){
      this.$axios.post(`//${this.domains.trans}/station/enum/BJ8051Events`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'events', res.data);
        }
      });
    },
    createCmd: function(){
      let args;
      if(this.command.code == 'openSingle'){
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        args = {bright1: 100, bright2: 100, color1: 100, color2: 0, expire: expire};
      }else if(this.command.code == 'closeSingle'){
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        args = {bright1: 0, bright2: 0, color1: 0, color2: 0, expire: expire};
      }else if(this.command.code == 'handSingle'){
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        args = {bright1: this.form.outputPower1, bright2: this.form.outputPower2, color1: this.form.outputColor, color2: 100 - this.form.outputColor, expire: expire};
      }else if(this.command.code == 'handGroup'){
        let d = new Date(this.form.expire);
        let expire = ((d.getTime() - new Date().getTime()) / 1000 / 60).toFixed(0);
        args = {
          useConfig: this.form.useConfig, 
          areaId: this.form.areaId,
          no: this.form.groupNo, 
          bright1: this.form.outputPower1, 
          bright2: 100-this.form.outputPower2, 
          color1: this.form.outputColor, 
          color2: 100 - this.form.outputColor, 
          expire: expire
        };
      } else if (this.command.code == 'setAlarmStatus') {
        args = { voltage: this.form.voltageAlarm, current: this.form.currentAlarm, temp: this.form.tempAlarm, leakage: this.form.leakageAlarm };
      } else if (this.command.code == 'setLeakageAlarmEnable') {
        args = { enable: this.form.leakageAlarm, check: this.form.checkTime, count: this.form.uploadCount };
      }else if(this.command.code == 'setPlanType'){
        args = {type: this.form.planType};
      }else if(this.command.code == 'setDayPlan1'){
        args = {useConfig: this.form.useConfig, timeId: this.form.timeId, timeContent: this.form.timeContent1};
      }else if(this.command.code == 'setDayPlan2'){
        args = {useConfig: this.form.useConfig, timeId: this.form.timeId, timeContent: this.form.timeContent2};
      }else if(this.command.code == 'handControl'){
        args = {open: this.form.open, enable:true, expire: new Date(this.form.expire).format('yyyy-MM-dd HH:mm:ss'), point: false};
      }else if(this.command.code == 'getYearPlan'){
        let date = new Date(this.form.date);
        args = {single: this.form.single, month: date.getMonth() + 1, day: date.getDate()};
      }else if(this.command.code == 'setCurrentRate'){
        args = {k: this.form.currentRate};
      }else if(this.command.code == 'update'){
        // console.log('args saved', this.form.content)
        args = {version: 3, flag: 0,len: this.form.packLen, content: this.form.content};
      }else if(this.command.code == 'setNetwork'){
        args = {host: this.form.host, port: this.form.port, heart: this.form.heart};
      }else if(this.command.code == 'setLoraWork') {
        args = {channel: this.form.channel, speed: this.form.speed};
      }else if(this.command.code == 'setLeakageRate'){
        args = {ll: this.form.ll, lh: this.form.lh, lx: this.form.lx};
      }else if(this.command.code == 'setComArgs'){
        args = {com: this.form.comname, type: this.form.comtype, buad: this.form.combuad, data: this.form.comdata, parity: this.form.comparity, stop: this.form.comstop};
      }else if(this.command.code == 'getComArgs'){
        args = {com: this.form.comname};
      } else if (this.command.code == 'setUnionParam') {
        args = {
          rfsn: this.form.rfsn,
          channel: this.form.channel,
          txpower: this.form.txpower,
          txen: this.form.txen,
          repeat: this.form.repeat,
          period: this.form.period,
          range: this.form.range,
          rxen: this.form.rxen,
          mode: this.form.mode,
          keep1: this.form.keep1,
          keep2: this.form.keep2,
          sensor: this.form.sensor,
        };
      }else if(this.command.code == 'ftp'){
        args = {ipport: this.form.ipport, file: this.form.file, user: this.form.user, pswd: this.form.pswd1, len: this.form.len, crc: this.form.crc};
      // }else if(this.command.code == 'setWeekControl'){
      //   let list = [];
      //   for(let i = 0; i < 7; i++){
      //     for(let j = 0; j < 2; j++){
      //       let idx = i * 2 + j;
      //       list.push({week: i+1, am: j == 0, enable: this.form.weekCtrls[idx]});
      //     }
      //   }
      //   args = {list}
      }else if(this.command.code == 'setLuxOpenValue'){
        args = {lux: this.form.lux};
      } else{
        args = {};
      }
      this.$emit('saved', {code: this.command.code, name: this.command.name, args});
      this.showModal = false;
      this.form.pswd = '';
    },
    ok: function () {
        // console.log('args saved before 1', this.command.code)
      if (this.command.code == "update") {
        if (this.$refs.fileInput.files.length == 0) {
          this.$Message.warning("请选择升级文件");
          return;
        }
        if(this.form.pswd == ''){
          this.$Message.warning('请输入密码');
          return;
        }
        this.$store.dispatch('auth/checkPswd', {pswd: this.form.pswd}).then(res => {
          if(res.code != 0){
            // this.$Message.error('密码错误');
            return;
          }else{
            var reader = new FileReader(); //实例化文件读取对象
            reader.readAsDataURL(this.$refs.fileInput.files[0]); //将文件读取为 DataURL,也就是base64编码
            let that = this;
            reader.onload = function (ev) {
              //文件读取成功完成时触发
              var dataURL = ev.target.result; //获得文件读取成功后的DataURL,也就是base64编码
              // document.querySelector("img").src = dataURL; //将DataURL码赋值给img标签
              that.form.content = dataURL.replace("data:application/octet-stream;base64,", "");
              // that.$emit('saved', {code: that.code, name: that.name, packLen: that.form.packLen, args: dataURL})
              // that.argsSaved({
              //   code: that.code,
              //   name: that.name,
              //   args: { len: that.form.packLen, content: dataURL },
              // });
            // console.log('args saved before', that.form.content)
              that.createCmd();
              // that.loading = false;
              // that.showModal = false;
              that.$refs.fileInput.value = "";
            };
          }
        });
        return;
      }
      if(this.command.code == 'openSingle' || this.command.code == 'closeSingle'){
        let d = new Date(this.form.expire);
        if(d < new Date()){
          this.$Message.warning('必须选择将来的时间');
          return;
        }
      }
      if(this.command.code == 'setLuxOpenValue'){
        if(this.form.lux < 0 || this.form.lux > 65535){
          this.$Message.warning('光感值必须在0-65535之间');
          return;
        }
      }
      // if(this.command.code == 'setGroup'){
      //   if(this.form.groupNo < 0 || this.form.groupNo > 255){
      //     this.$Message.warning('分组编号必须在0-255之间');
      //     return;
      //   }
      // }
      if(this.command.code == 'setDayPlan1' || this.command.code == 'setDayPlan2'){
        // console.log('get time id', this.form.timeId)
        if(!this.form.timeId)this.form.timeId = 0;
        if(this.form.useConfig && this.form.timeId <= 0){
          this.$Message.warning('必须选择一个时间表');
          return;
        }
      }
      if(this.command.code == 'handControl'){
        let d = new Date(this.form.expire);
        if(d < new Date()){
          this.$Message.warning('必须选择将来的时间');
          return;
        }
      }
      if(this.command.code == 'setCurrentRate'){
        // if(this.form.currentRate < 1 || this.form.currentRate > 65535){
        //   this.$Message.warning('电流倍率必须在 1 - 65535 之间');
        //   return;
        // }
      }
      if(this.command.code == 'setNetwork'){
        if(this.form.port < 0 || this.form.port > 65535){
          this.$Message.warning('端口必须在 1 - 65535 之间');
          return;
        }
      }
      if(this.command.code == 'setLoraWork'){
        if(this.form.channel < 0 || this.form.channel > 100){
          this.$Message.warning('通道必须在 0 - 100 之间');
          return;
        }
      }
      if(this.command.code == 'setLeakageAlarmEnable'){
        if(this.form.checkTime < 15 || this.form.checkTime > 255){
          this.$Message.warning('检查时间必须在 15 - 255 之间');
          return;
        }
        if(this.form.uploadCount < 1 || this.form.uploadCount > 5){
          this.$Message.warning('上报次数必须在 1 - 5 之间');
          return;
        }
      }
      if(this.command.code == 'setLeakageRate'){
        if(parseInt(this.form.ll) < 0){
          this.$Message.warning('轻微漏电阈值必须大于0');
          return;
        }
        if(parseInt(this.form.lh) < parseInt(this.form.ll)){
          this.$Message.warning('轻微漏电阈值必须小于严重漏电阈值');
          return;
        }
        if(parseInt(this.form.lx) < parseInt(this.form.lh)){
          this.$Message.warning('严重漏电阈值必须小于跳闸漏电阈值');
          return;
        }
      }
      if(this.needCheck){
        if(this.form.pswd == ''){
          this.$Message.warning('请输入密码');
          return;
        }
        this.$store.dispatch('auth/checkPswd', {pswd: this.form.pswd}).then(res => {
          if(res.code != 0){
            // this.$Message.error('密码错误');
            return;
          }else{
            this.createCmd();
          }
        });
      }else{
        this.createCmd();
      }
    },
    cancel () {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.set-table tr td{
  border: solid 1px gray;
  text-align: center;
  white-space:nowrap
}
.set-table{
  width: 100%;
}
.time-span{
  display: flex;
}
.min-width{
  width: 70px;
  flex: none;
  margin: 0;
}
.ch-width{
  width: 120px;
  flex: none;
  margin: 0 10px;
  display: flex;
}
.ch-width span{
  width: 25px;
  flex: none;
}
.ch-width div{
  width: 40px;
  flex: auto;
}
.demo-drawer-footer{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form{
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly{
  background: #eee
}
</style>