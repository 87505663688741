<template>
  <Modal v-model="showModal" :mask-closable="false" width="800px">
    <div slot="header">编辑角色信息</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="80">
      <!-- <FormItem prop="customerId" label="所在组织">
        <Input type="text" maxlength="20" v-model="custName" readonly placeholder="请输入名称"></Input>
      </FormItem> -->
      <FormItem prop="name" label="角色名称">
        <Input ref="name" type="text" maxlength="20" v-model="form.name" placeholder="请输入名称">
        </Input>
      </FormItem>
      <FormItem prop="remark" label="角色说明">
        <Input ref="remark" type="text" maxlength="20" v-model="form.remark" placeholder="请输入角色说明">
        </Input>
      </FormItem>
      <FormItem prop="name" :label-width="0" style="margin-bottom:0">
        <div class="fun-container" :style="{height: `300px`}">
          <Checkbox :value="chkAppAll" @on-change="selectAll" class="float-chk">全选</Checkbox>
          <ul v-for="fun in functions" :key="`fun-${fun.id}`" class="fun-list">
            <li>
              <span>{{fun.name}}: <Checkbox :value="chkFunAll(fun)" @on-change="selectRow($event, fun)" style="margin-left: 10px">全选</Checkbox></span>
              <ul class="chk-list">
                <li v-for="op in fun.operations" :key="`fun-${fun.id}-op-${op.id}`">
                  <Checkbox v-model="op.chk">{{ op.cnName }}</Checkbox>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'ModalRoleEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    }
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      index: -1,
      apps: [],
      custName: '',
      form: {
        id: 0,
        // customerId: 0,
        remark: '',
        name: '',
        chks: [],
      },
      rules: {
        name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度不能超过20', trigger: 'blur' },
        remark: { required: false, type: 'string', max: 100, message: '说明长度不能超过100', trigger: 'blur' },
      },
      functions: [],
    }
  },
  computed: {
    chkFunAll: function(){
      return function(fun){
        for(let op of fun.operations){
          if(!op.chk)return false;
        }
        return true;
      }
    },
    chkAppAll: function(){
      for(let fun of this.functions){
        for(let op of fun.operations){
          if(!op.chk)return false;
        }
      }
      return true;
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(this.showModal){
        this.isAdd = this.item.isAdd;
        // this.custName = this.item.cust.name;
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          // this.form.customerId = this.item.cust.id;
          this.form.remark = this.item.data.remark || '';
          this.form.name = this.item.data.name;
          this.form.chks = [];
          this.index = this.item.index;
        }else{
          this.form.id = 0;
          // this.form.customerId = this.item.cust.id;
          this.form.name = '';
          this.form.remark = '';
          this.form.chks = [];
          this.index = -1;
        }
        this.initFunctions();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  methods: {
    initFunctions: function(){
      this.$axios.post(`sys/auth/QueryRoleFuncsOld`, {roleId: this.form.id}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          res.data.funs.map(func => {
            func.operations.map(op => {
              op.chk = res.data.ops.indexOf(op.code) != -1;
            })
          })
          this.$set(this, 'functions', res.data.funs);
        }
      });
    },
    selectAll: function(chk){
      for(let fun of this.functions){
        for(let op of fun.operations){
          op.chk = chk;
        }
      }
    },
    selectRow: function(chk, params){
      for(let c of params.operations){
        c.chk = chk;
      }
    },
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        return;
      }
      let chks = [];
      for(let fun of this.functions){
        for(let op of fun.operations){
          if(op.chk){
            chks.push(op.code);
          }
        }
      }
      this.form.chks = chks;
      if(this.form.chks.length == 0){
        this.$Message.warning('请选择角色权限');
        return;
      }
      this.loading = true;
      this.$axios.post(`sys/auth/SaveOldRole`, this.form).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved');
        }
      })
    },
    cancel () {
      this.showModal = false;
      this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.float-chk{
  position: absolute;
  top: 8px;
  right: 30px;
}
.form{
  height: calc(100%);
  overflow: auto;
}
.readonly{
  background: #eee
}
.fun-container{
  border: solid 1px rgba(216, 199, 199, 0.438);
  border-radius: 6px;
  height: 100%;
  overflow: auto;
}
.fun-list{
  display: flex;
  /* list-style: none; */
  flex-direction: column;
  margin: 8px 15px;
}
.chk-list{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
li{
  margin: 0 5px;
}
</style>